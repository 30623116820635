import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ModalComponent } from '../../../components/organisms/Modal'
import { FlexComponent } from '../../../components/organisms/Layout/Flex'
import { useModalContext } from '../../../../hooks'
import { Button } from '../../../components/atoms/Button'

const ExitModal = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const exitModalID = 'exitModal'
  const exitModal = useModalContext(exitModalID)
  const handleModalClose = () => {
    exitModal.close()
  }

  const onCancel = () => {
    exitModal.close()
  }

  const onContinue = () => {
    navigate('/')
    exitModal.close()
  }
  return (
    <ModalComponent
      modalTitle={t(`product-details-page.modal.title`)}
      isOpen={exitModal.isOpen}
      onClose={handleModalClose}
      height='auto'
      maxWidth='620px'
      mobileHeight='auto'
    >
      <FlexComponent flexDirection={['column', 'column', 'row', 'row']} alignItems='center' gap={2}>
        <Button
          isDisabled={false}
          type='button'
          label={t(`product-details-page.modal.delete`)}
          variant='tertiary'
          width='295px'
          onClick={onContinue}
        />
        <Button label={t(`product-details-page.modal.keep`)} variant='quaternary' width='295px' onClick={onCancel} />
      </FlexComponent>
    </ModalComponent>
  )
}

export default ExitModal
