import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader'
import { Headline } from '../components/atoms/Headline'
import PageSection from '../components/organisms/PageSection/PageSection'
import { ReactComponent as EmailIcon } from '../../icons/Email.svg'
import { FlexComponent } from '../components/organisms/Layout/Flex'
import { Text } from '../components/atoms/Text'
import { Input } from '../components/atoms/Input'
import Form from '../components/molecules/Form/Form'
import { ReactComponent as WarningIcon } from '../../icons/Warning.svg'
import Button from '../components/atoms/Button/Button'
import { HideOn } from '../components/atoms/HideOn'
import { SnackBar } from '../components/molecules/SnackBar'
import { useSnackbarContext } from '../../hooks'
import { ResendOtpSnackbar } from '../Templates/UserRegistration/ResendOtpSnackbar'
import { useAccess } from '../../Context/AccessContext'
import { useUser } from '../../Context/UserContext'
import { colors } from '../../theme/blanco/colors/colors'

interface IOtpForm {
  otp: string
}

const ConfirmRegistrationPage = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { user } = useUser()

  const { setHasAccess } = useAccess()

  const schema = yup
    .object({
      otp: yup.string().required(),
    })
    .required()
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setError,
  } = useForm<IOtpForm>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      otp: '',
    },
  })

  const email = localStorage.getItem('emailAddress')
  const correlationId = localStorage.getItem('correlation')

  const resendOtpSnackbarID = 'resendOtpSnackbar'

  const resendOtpSnackbar = useSnackbarContext(resendOtpSnackbarID)
  // resendOtpSnackbar.open()

  const userConfirm = async (otp: string) => {
    const id = localStorage.getItem('id')
    const userId = localStorage.getItem('userId')

    // TODO: remove this error??
    if (!id || !userId) {
      throw new Error('Missing id or userId')
    }

    const url = new URL(`${process.env.REACT_APP_BLANCO_SHOP_API}/consumer/confirm-email?locale=${user?.localeDiva}`)
    const res = await fetch(url.toString(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id,
        otp,
        userId,
      }),
    })
    const responseData = await res.json()
    if (!res.ok) {
      throw new Error(responseData.message || 'confirm failed')
    }
    return responseData
  }

  const confirmMutation = useMutation({
    mutationFn: (otp: string) => userConfirm(otp),
    onError: (error: any) => {
      console.error('An error has occurred: ', error)

      if (error.response && error.response.data && error.response.data.message) {
        setError('otp', {
          type: 'manual',
          message: error.response.data.message,
        })
      } else {
        // Fallback error message
        setError('otp', {
          type: 'manual',
          message: 'Verification failed. Please try again.',
        })
      }
    },
    onSuccess: () => {
      setHasAccess(true)
      navigate('/confirmation-page', { state: 'registration' })
      localStorage.removeItem('correlation')
    },
  })

  const onClickConfirm = (data: IOtpForm) => {
    confirmMutation.mutate(data.otp)
  }

  const resentOtp = async (data: string) => {
    const url = new URL(
      `${process.env.REACT_APP_BLANCO_SHOP_API}/consumer/send-email-verification?locale=${user?.localeDiva}`,
    )
    const res = await fetch(url.toString(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: data,
      }),
    })
    const responseData = await res.json()
    if (!res.ok) {
      throw new Error(responseData.message)
    }
    return responseData
  }

  const resentOtpMutation = useMutation({
    mutationFn: (userEmail: string) => resentOtp(userEmail),
    onError: (error) => {
      console.error('An error has occurred: ', error)
    },
    onSuccess: (data) => {
      if (data.userId && data.id) {
        localStorage.setItem('id', data.id)
        localStorage.setItem('correlation', data.correlation)
      }
    },
  })

  const sendEmailVerification = (userEmail: string) => {
    resentOtpMutation.mutate(userEmail)
    resendOtpSnackbar.open()
  }

  return (
    <PageSection>
      <Headline hasBorders headline={t('product-registration.confirm-registration-modal.headline')} />
      <ResendOtpSnackbar />
      <FlexComponent flexDirection='column' mt={60} alignItems='center' gap={24}>
        <FlexComponent flexDirection='column' gap={16} alignItems='center'>
          <HideOn mobile>
            <FlexComponent justifyContent='center'>
              <EmailIcon />
            </FlexComponent>
          </HideOn>
          <HideOn desktop>
            <FlexComponent justifyContent='center'>
              <EmailIcon width={48} height={48} />
            </FlexComponent>
          </HideOn>
          <Text variant='title' text={t('product-registration.confirm-registration-modal.title')} />
        </FlexComponent>
        <FlexComponent flexDirection='column' alignItems='center' gap={2} textAlign='center'>
          <HideOn mobile>
            <FlexComponent gap={1} justifyContent='center'>
              <Text text={t('product-registration.confirm-registration-modal.code-sent')} />
              <Text variant='bold' text={`${email}`} />
            </FlexComponent>
          </HideOn>
          <HideOn desktop>
            <FlexComponent flexDirection='column'>
              <Text text={t('product-registration.confirm-registration-modal.code-sent')} />
              <Text variant='bold' text={`${email}`} />
            </FlexComponent>
          </HideOn>
          <Text text={t('product-registration.confirm-registration-modal.enter-code')} />
        </FlexComponent>
        <Form mt={1} onSubmit={handleSubmit(onClickConfirm)}>
          <FlexComponent flexDirection='column' alignItems='center'>
            <FlexComponent gap={2}>
              <FlexComponent flexShrink={0} mt={20}>
                <HideOn mobile>
                  {correlationId ? (
                    <Text variant='bold' fontSize={16} text={`${correlationId} -`} />
                  ) : (
                    <Text fontSize={14} text={t('product-registration.confirm-registration-modal.otp-label')} />
                  )}
                </HideOn>
                <HideOn desktop>
                  {correlationId ? (
                    <Text variant='bold' fontSize={14} text={`${correlationId} -`} />
                  ) : (
                    <Text fontSize={12} text={t('product-registration.confirm-registration-modal.otp-label')} />
                  )}
                </HideOn>
              </FlexComponent>
              <Controller
                name='otp'
                control={control}
                render={({ field }) => (
                  <Input
                    hasError={!!errors.otp}
                    backIcon={!!errors.otp && <WarningIcon />}
                    errorMessage={
                      errors.otp
                        ? errors.otp.message
                        : t('product-registration.confirm-registration-modal.error-message')
                    }
                    isRequired={true}
                    placeholder={t('product-registration.confirm-registration-modal.otp-placeholder')}
                    {...field}
                  />
                )}
              />
            </FlexComponent>
            <HideOn mobile>
              <FlexComponent mb={52}>
                <Text text={t('product-registration.confirm-registration-modal.not-received')} />
                <Button
                  type='button'
                  onClick={() => email && sendEmailVerification(email)}
                  variant='secondary'
                  label={t('product-registration.confirm-registration-modal.resend')}
                />
              </FlexComponent>
            </HideOn>
            <HideOn desktop>
              <FlexComponent flexDirection='column' alignItems='center' mb={52}>
                <Text text={t('product-registration.confirm-registration-modal.not-received')} />
                <Button
                  type='button'
                  onClick={() => email && sendEmailVerification(email)}
                  variant='secondary'
                  label={t('product-registration.confirm-registration-modal.resend')}
                />
              </FlexComponent>
            </HideOn>
            <SnackBar
              text1={t('product-registration.confirm-registration-modal.snackbar-success1')}
              text2={t('product-registration.confirm-registration-modal.snackbar-success2')}
            />
            <FlexComponent flexDirection={['column', 'column', 'row', 'row']} gap={16} alignItems='center'>
              <Button
                isDisabled={!isValid || confirmMutation.isLoading}
                type='submit'
                label={t('product-registration.confirm-registration-modal.submit')}
                variant='primary'
                width='100%'
                desktopWidth='auto'
              />
              {confirmMutation.isLoading && (
                <ClipLoader size={24} color={colors.primary.elementaryBlue} loading={confirmMutation.isLoading} />
              )}
            </FlexComponent>
          </FlexComponent>
        </Form>
      </FlexComponent>
    </PageSection>
  )
}
export default ConfirmRegistrationPage
