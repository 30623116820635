import { CSSObject } from '@emotion/react'
import { toRem } from '../../../../helpers/functions'

export const style = ({ theme }: any): CSSObject => {
  const { breakpoint, shadows, typography, spacings, color } = theme
  return {
    backgroundColor: color.primary.elementaryWhite,
    padding: `${spacings.s12} ${spacings.s20}`,
    borderRadius: '3px',
    position: 'relative',
    width: '100%',
    '& .datepicker': {
      '&-label': {
        fontSize: typography.fontSizeMobile.interactionsBoldSmall,
        lineheight: typography.lineHeightMobile.interactionsBoldSmall,
        fontWeight: typography.fontWeight.bold,
        color: color.greyscale.grey,
        fontFamily: typography.fontFamily.helveticaBold,
        '& span': {
          fontWeight: typography.fontWeight.light,
          fontFamily: typography.fontFamily.helveticaLight,
        },
        [breakpoint.up('md')]: {
          fontSize: typography.fontSizeMobile.interactionsHeavy,
          lineHeight: typography.lineHeightMobile.interactionsBoldSmall,
          '& span': {
            fontSize: typography.fontSizeMobile.interactionsHeavy,
            lineHeight: typography.lineHeightMobile.interactionsBoldSmall,
          },
        },
      },
    },
    '& .datepicker-wrapper': {
      height: '100%',
    },
    '& .react-datepicker-wrapper': {
      '& input': {
        border: 0,
        margin: 0,
        padding: 0,
        fontSize: toRem(16),
        lineHeight: toRem(24),
        fontWeight: typography.fontWeight.light,
        fontFamily: typography.fontFamily.helveticaLight,
        color: color.primary.elementaryBlue,
        '&::placeholder': {
          color: color.greyscale.grey,
        },
        '&:focus': {
          outline: 'none',
        },
      },
    },
    //datepicker lib styles
    '& .react-datepicker-popper': {
      width: '100%',
    },
    '& .react-datepicker': {
      width: '100%',
      border: 0,
      boxShadow: shadows.secondary,
    },
    '& .react-datepicker__month-container': {
      width: '100%',
      padding: `${spacings.s16} ${spacings.s24}`,
    },
    '& .react-datepicker__triangle': {
      display: 'none',
    },
    '& .react-datepicker__header': {
      background: color.primary.elementaryWhite,
      border: 0,
    },
    '& .react-datepicker__day-names': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: spacings.s12,
    },
    '& .react-datepicker__day-name': {
      margin: 0,
      width: toRem(36),
      height: toRem(36),
      fontSize: typography.fontSizeMobile.interactionsBoldSmallWide,
      lineHeight: typography.lineHeightMobile.interactionsBoldSmall,
      color: color.greyscale.silver,
      fontWeight: typography.fontWeight.light,
      fontFamily: typography.fontFamily.helveticaLight,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .react-datepicker__week': {
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& .react-datepicker__month': {
      margin: 0,
    },
    '& .react-datepicker__day': {
      width: toRem(36),
      height: toRem(36),
      margin: 0,
      fontSize: typography.fontSizeMobile.interactionsBoldSmallWide,
      lineHeight: typography.lineHeightMobile.interactionsBoldSmall,
      color: color.greyscale.shipGrey,
      fontWeight: typography.fontWeight.light,
      fontFamily: typography.fontFamily.helveticaLight,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .react-datepicker__day--outside-month': {
      color: color.greyscale.silver,
    },
    '& .react-datepicker__day--future': {
      color: color.greyscale.lightGrey, // Example text color for future dates
    },
    '& .react-datepicker__current-month': {
      color: color.greyscale.shipGrey,
      fontWeight: typography.fontWeight.bold,
      fontSize: typography.fontSizeMobile.copyBold,
      lineHeight: typography.lineHeightMobile.copyBold,
      fontFamily: typography.fontFamily.helveticaBold,
      marginBottom: spacings.s12,
    },
    '& .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected':
      {
        backgroundColor: color.primary.elementaryBlue,
        color: color.primary.elementaryWhite,
      },
    '& .react-datepicker__navigation': {
      top: toRem(20),
    },
    '& .react-datepicker__navigation-icon--previous': {
      width: toRem(24),
      height: toRem(24),
      left: 0,
      top: 0,
      '& svg': {
        position: 'absolute',
        top: 0,
        left: 0,
      },
    },
    '& .react-datepicker__navigation-icon--next': {
      width: toRem(24),
      height: toRem(24),
      right: 0,
      top: 0,
      '& svg': {
        position: 'absolute',
        top: 0,
        left: 0,
      },
    },
    '& .react-datepicker__navigation--previous': {
      left: toRem(24),
    },
    '& .react-datepicker__navigation--next': {
      right: toRem(24),
    },
    '&  .react-datepicker__navigation-icon--next, .react-datepicker__navigation-icon--previous': {
      '&::before': {
        display: 'none',
      },
    },
  }
}

export default style
