import { CSSObject } from 'theme-ui'
import { toRem } from '../../../../helpers/functions'

const style = ({ theme }: any): CSSObject => {
  const { color } = theme
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: toRem(16),
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: '50%',
    top: 0,
    transform: 'translateX(-50%)',
    '& span': {
      width: toRem(16),
      height: toRem(16),
      borderRadius: '50%',
      backgroundColor: color.greyscale.iron,
      animation: 'pulsate 1.5s infinite',
      '&:nth-of-type(2)': {
        animationDelay: '0.25s',
      },
      '&:nth-of-type(3)': {
        animationDelay: '0.5s',
      },
    },
    '@keyframes pulsate': {
      '0%': {
        transform: 'scale(1)',
        opacity: 0.7,
      },
      '50%': {
        transform: 'scale(1.2)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(1)',
        opacity: 0.7,
      },
    },
  }
}

export default style
