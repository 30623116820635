import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import { StepperHeader } from '../components/molecules/StepperHeader'
import PageSection from '../components/organisms/PageSection/PageSection'
import { GridOptions } from '../Templates/ProductDetails/GridOptions'
import { randomNumberGenerator } from '../../helpers/functions/randomNumberGenerator'
import { useUser } from '../../Context/UserContext'

function useQueryParams() {
  return new URLSearchParams(useLocation().search)
}

const getData = async (queryPath: string, locale?: string) => {
  const url = new URL(
    `${process.env.REACT_APP_BLANCO_PRODUCT_REGISTRATION_API}/api/attributes/${locale}/waste${queryPath}`,
  )
  const res = await fetch(url.toString(), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
  const data = await res.json()
  if (!res.ok) {
    throw new Error(data.message)
  }
  return data
}

const TapStepsPage = () => {
  const { t } = useTranslation()
  const { user } = useUser()

  const [hoveredCard, setHoveredCard] = useState<number | null>(null)

  const [installationMethodId, setInstallationMethodId] = useState(0)
  const [numberOfBucketsId, setNumberOfBucketsId] = useState(0)
  const [horsePower, setHorsePower] = useState('')
  const [subType, setSubType] = useState('')

  const queryParams = useQueryParams()
  const navigate = useNavigate()

  const queries = queryParams.get('type')
    ? queryParams.get('type') === 'waste bin'
      ? ['type', 'installationmethod', 'numberofbuckets']
      : ['type', 'horsepower']
    : ['type']

  const currentQuery = queryParams.get('query') || queries[0]
  const currentQueryIndex = queries.indexOf(currentQuery)

  // Determine the path after '/sink'
  const pathAfterProduct = location.search
  const fetchRoute = pathAfterProduct || `?query=${currentQuery}`

  const pickedQueryResults = queries
    .slice(0, currentQueryIndex)
    .map((query) => queryParams.get(query))
    .filter((result): result is string => result !== null) //typescript proof

  const breadcrumbs = ['waste'].concat(pickedQueryResults)

  const { data } = useQuery(['fetchRoute', fetchRoute, user?.localeBlanco], () =>
    getData(fetchRoute, user?.localeBlanco),
  )

  const handleMaterialSelect = (material: any, id: any) => {
    if (currentQuery === 'installationmethod') setInstallationMethodId(id)
    if (currentQuery === 'numberofbuckets') setNumberOfBucketsId(id)
    if (currentQuery === 'horsepower') setHorsePower(material)
    if (currentQuery === 'type') setSubType(material)
    if ((currentQueryIndex + 1 === queries.length && currentQueryIndex !== 0) || material === 'Waste management') {
      const newObject = {
        id: `Waste ${randomNumberGenerator()}`,
        second_id: `${randomNumberGenerator()}`,
        image: [{ Dokumentenkennung: data.headerImagePath }],
        description: breadcrumbs.join(' / '),
        details: {
          type: 'Waste',
          subType: subType || null,
          wasteDetails: {
            installationMethodId: installationMethodId || 0,
            numberOfBucketsId: numberOfBucketsId || 0,
            horsePower: horsePower || '',
          },
        },
      }

      // Step 1: Retrieve the 'selectedItems' array from local storage
      const selectedItemsString = localStorage.getItem('selectedItems')
      const selectedItems = selectedItemsString ? JSON.parse(selectedItemsString) : []

      // Step 2: Add the new object to the array
      selectedItems.push(newObject)

      // Step 3: Save the updated array back to local storage
      localStorage.setItem('selectedItems', JSON.stringify(selectedItems))
      navigate('/product-registration')

      return
    }
    const baseRoute = `/product-details/waste${fetchRoute}`
    const array =
      currentQuery === 'type' &&
      (material === 'waste bin' ? ['type', 'installationmethod', 'numberofbuckets'] : ['type', 'horsepower'])
    const newRoute = baseRoute.replace(
      `query=${currentQuery}`,
      `${currentQuery}=${material}&query=${(array || queries)[currentQueryIndex + 1]}`,
    )

    navigate(newRoute)
    setHoveredCard(null)
  }

  const onBack = () => {
    if (currentQueryIndex === 0) return navigate('/product-details')
    const base = `/product-details/waste${fetchRoute}`.split(`&query=${currentQuery}`)[0]
    const backRoute = base.split(`${queries[currentQueryIndex - 1]}`)[0] + `query=${queries[currentQueryIndex - 1]}`
    return navigate(backRoute)
  }

  const getHeadline = (query: string) => {
    if (query === 'type') return t('product-details-page.waste.headline.type')
    if (query === 'installationmethod') return t('product-details-page.waste.headline.installationmethod')
    if (query === 'numberofbuckets') return t('product-details-page.waste.headline.numberofbuckets')
    if (query === 'horsepower') return t('product-details-page.waste.headline.horsepower')
  }

  //for card hover style:
  const onMouseEnter = (index: number) => {
    setHoveredCard(index)
  }

  const onMouseLeave = () => {
    setHoveredCard(null)
  }

  return (
    <>
      <StepperHeader
        breadcrumbs={breadcrumbs}
        stepNumber={currentQueryIndex + 1}
        maxStepNumber={queries.length}
        onBack={() => onBack()}
        exitUrl='/'
        marginBottom={80}
      />
      <PageSection
        sectionMaxWidth={990}
        headlineMaxWidth={483}
        headline={getHeadline(currentQuery)}
        titleMarginBottom={80}
        ml={24}
        mr={24}
        isHeadlineLeft
        isSectionLeft
        isCentered
      >
        <GridOptions
          data={data}
          currentQuery={currentQuery}
          onCardClick={handleMaterialSelect}
          hoveredCard={hoveredCard}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
      </PageSection>
    </>
  )
}

export default TapStepsPage
