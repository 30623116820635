import { toRem } from '../../../helpers/functions'
import ITypography from '../../../types/theme/typography/typography.interface'

export const typography: ITypography = {
  fontSizeMobile: {
    h1Heavy: toRem(36),
    h2Heavy: toRem(24),
    h3Bold: toRem(24),
    h2Roman: toRem(24),
    copyBold: toRem(18),
    copyRoman: toRem(18),
    copyLight: toRem(18),
    interactionsBold: toRem(14),
    interactionsMedium: toRem(14),
    interactionsBoldSmall: toRem(12),
    interactionsBoldSmallWide: toRem(12),
  },
  fontSize: {
    heading1: toRem(84),
    heading2: toRem(60),
    h3Bold: toRem(36),
    h3Roman: toRem(36),
    h4Light: toRem(30),
    h5Heavy: toRem(24),
    h5Bold: toRem(24),
    h5Light: toRem(24),
    copyBold: toRem(18),
    copyRoman: toRem(18),
    copyLight: toRem(18),
    interactionsHeavy: toRem(14),
    interactionsMedium: toRem(14),
    interactionsBoldSmall: toRem(12),
    interactionsBoldSmallWide: toRem(12),
    descriptionsRoman: toRem(14),
  },
  lineHeightMobile: {
    h1Heavy: toRem(36),
    h2Heavy: toRem(30),
    h3Bold: toRem(30),
    h2Roman: toRem(30),
    copyBold: toRem(32),
    copyRoman: toRem(32),
    copyLight: toRem(32),
    interactionsBold: toRem(15),
    interactionsMedium: toRem(15),
    interactionsBoldSmall: toRem(16),
    interactionsBoldSmallWide: toRem(28),
  },
  lineHeight: {
    heading1: toRem(96),
    heading2: toRem(72),
    h3Bold: toRem(42),
    h3Roman: toRem(42),
    h4Light: toRem(42),
    h5Heavy: toRem(36),
    h5Bold: toRem(36),
    h5Light: toRem(36),
    copyBold: toRem(32),
    copyRoman: toRem(32),
    copyLight: toRem(32),
    interactionsHeavy: toRem(28),
    interactionsMedium: toRem(28),
    interactionsBoldSmall: toRem(16),
    interactionsBoldSmallWide: toRem(16),
    descriptionsRoman: toRem(24),
  },
  letterSpacing: {
    primary: 1,
    secondary: 2,
    tertiary: 1.43,
  },
  fontFamily: {
    helveticaBold: 'helveticaBold',
    helveticaBlack: 'helveticaBlack',
    helveticaHeavy: 'helveticaHeavy',
    helveticaLight: 'helveticaLight',
    helveticaMedium: 'helveticaMedium',
    helveticaRoman: 'helveticaRoman',
    helveticaThin: 'helveticaThin',
    helveticaUltraThin: 'helveticaUltraThin',
  },

  fontWeight: {
    bold: 700,
    black: 900,
    heavy: 800,
    light: 300,
    medium: 500,
    roman: 400,
    thin: 100,
    ultraThin: 200,
  },
}
