import { CSSObject } from '@emotion/react'
import { toRem } from '../../../../helpers/functions'

const style = ({ theme, icon }: any): CSSObject => {
  const { color, spacings, breakpoint, typography } = theme
  return {
    position: 'relative',
    width: '100%',
    borderRadius: spacings.s4,
    cursor: 'pointer',
    height: toRem(160), // check: is it ok to hardcode height
    background: `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 34.60%, rgba(0, 0, 0, 0.80) 100%), url(${icon})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [breakpoint.up('md')]: {
      height: toRem(185),
    },

    '.textWrapper': {
      position: 'absolute',
      bottom: toRem(18),
      left: toRem(18),
      color: color.greyscale.white,
      fontSize: typography.fontSize.copyBold,
      lineHeight: typography.lineHeight.copyBold,
      fontWeight: typography.fontWeight.bold,
      fontFamily: typography.fontFamily.helveticaBold,
    },
  }
}

export default style
