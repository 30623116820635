import { CSSObject } from 'theme-ui'
import { toRem } from '../../../../helpers/functions'
import { typography } from '../../../../theme/blanco/typography/typography'

export const style = ({ theme }: any): CSSObject => {
  const { color, breakpoint } = theme
  return {
    display: 'flex',
    justifyContent: 'space-between',
    gap: toRem(12),
    padding: '24px',
    flexDirection: 'column',
    [breakpoint.up('md')]: {
      flexDirection: 'row',
      padding: '24px 0',
      gap: toRem(48),
    },
    '& .emptyState': {
      '&-wrapper': {
        flexDirection: 'column-reverse',
        alignItems: 'center',
        [breakpoint.up('md')]: {
          flexDirection: 'column',
        },
      },
      '&-title': {
        color: color.greyscale.shipGrey,
        fontSize: typography.fontSize.copyBold,
        lineHeight: typography.lineHeight.copyBold,
        fontWeight: typography.fontWeight.bold,
        fontFamily: typography.fontFamily.helveticaBold,
        marginTop: toRem(16),
        [breakpoint.up('md')]: {
          marginTop: 0,
        },
      },
      '&-image': {
        maxWidth: toRem(168),
        marginTop: toRem(0),
        [breakpoint.up('md')]: {
          marginTop: toRem(40),
          maxWidth: toRem(312),
          alignSelf: 'flex-start',
        },
      },
      '&-divider': {
        minWidth: '4px',
        background: color.primary.elementaryBlue,
        minHeight: toRem(32),
        height: '100%',
      },
      '&-subtitle': {
        color: color.greyscale.shipGrey,
        fontSize: toRem(16),
        lineHeight: toRem(26),
        fontWeight: typography.fontWeight.light,
        fontFamily: typography.fontFamily.helveticaLight,
        [breakpoint.up('md')]: {
          fontSize: typography.fontSize.copyLight,
          lineHeight: typography.lineHeight.copyLight,
        },
      },
    },
  }
}
export default style
