import { CSSObject } from '@emotion/react'
import { toRem } from '../../../../helpers/functions'

export const style = ({
  theme,
  width,
  maxWidthMobile,
  maxWidthDesktop,
  height,
  backgroundColor,
  variant,
  disabled,
  labelColor,
  desktopWidth,
}: any): CSSObject => {
  const { typography, color, spacings, breakpoint } = theme
  return {
    width: width,
    height: height,
    backgroundColor: backgroundColor,
    border: 0,
    boxShadow: 'none',
    cursor: 'pointer',
    color: labelColor ? labelColor : color.primary.elementaryWhite,
    maxWidth: maxWidthMobile ? maxWidthMobile : '100%',
    ...(variant === 'primary' && {
      padding: `${spacings.s24} ${spacings.s24}`,
      textTransform: 'uppercase',
      backgroundColor: color.primary.elementaryBlue,
      fontSize: typography.fontSizeMobile.interactionsBoldSmall,
      lineHeight: typography.lineHeightMobile.interactionsBoldSmall,
      fontWeight: 700,
      letterSpacing: '2px',
      borderRadius: '3px',
      fontFamily: typography.fontFamily.helveticaMedium,
      [breakpoint.up('md')]: {
        padding: `${spacings.s24} ${spacings.s32}`,
      },
    }),
    ...(variant === 'secondary' && {
      height: 'auto',

      backgroundColor: 'transparent',

      fontSize: toRem(16),
      lineHeight: typography.lineHeightMobile.interactionsBoldSmallWide,
      color: color.primary.elementaryBlue,
      textDecoration: 'underline',
      fontWeight: typography.fontWeight.light,
      fontFamily: typography.fontFamily.helveticaLight,
      [breakpoint.up('md')]: {
        fontSize: typography.fontSize.copyLight,
      },
    }),
    ...(variant === 'text' && {
      fontSize: typography.fontSizeMobile.interactionsBoldSmallWide,
      fontWeight: typography.fontWeight.bold,
      lineHeight: typography.lineHeightMobile.interactionsBoldSmall,
      fontFamily: typography.fontFamily.helveticaBold,
      letterSpacing: '1px',
      textTransform: 'uppercase',
      color: color.primary.elementaryBlue,

      backgroundColor: 'transparent',
    }),
    ...(disabled && {
      opacity: 0.5,
      cursor: 'not-allowed',
    }),
    ...(variant === 'tertiary' && {
      fontSize: typography.fontSizeMobile.interactionsBoldSmallWide,
      fontWeight: typography.fontWeight.bold,
      lineHeight: typography.lineHeightMobile.interactionsBoldSmall,
      fontFamily: typography.fontFamily.helveticaBold,
      letterSpacing: '1px',
      textTransform: 'uppercase',
      color: color.primary.elementaryBlue,
      border: `1px solid ${color.primary.elementaryBlue}`,
      backgroundColor: 'transparent',
      height: toRem(48),
      flex: 'auto',
    }),
    ...(variant === 'quaternary' && {
      fontSize: typography.fontSizeMobile.interactionsBoldSmallWide,
      fontWeight: typography.fontWeight.bold,
      lineHeight: typography.lineHeightMobile.interactionsBoldSmall,
      fontFamily: typography.fontFamily.helveticaBold,
      letterSpacing: '1px',
      textTransform: 'uppercase',
      color: color.primary.elementaryWhite,
      border: `1px solid ${color.primary.elementaryBlue}`,
      backgroundColor: color.primary.elementaryBlue,
      height: toRem(48),
      flex: 'auto',
    }),
    ...(variant === 'leftCenteredPrimary' && {
      fontSize: typography.fontSizeMobile.interactionsBoldSmallWide,
      fontWeight: typography.fontWeight.bold,
      lineHeight: typography.lineHeightMobile.interactionsBoldSmall,
      fontFamily: typography.fontFamily.helveticaBold,
      letterSpacing: '1px',
      textTransform: 'uppercase',
      color: color.primary.elementaryBlue,
      border: `1px solid ${color.primary.elementaryBlue}`,
      backgroundColor: 'transparent',
      height: toRem(62),
      flex: 'auto',
      borderRadius: toRem(3),
    }),
    ...(variant === 'leftCenteredSecondary' && {
      fontSize: typography.fontSizeMobile.interactionsBoldSmallWide,
      fontWeight: typography.fontWeight.bold,
      lineHeight: typography.lineHeightMobile.interactionsBoldSmall,
      fontFamily: typography.fontFamily.helveticaBold,
      letterSpacing: '1px',
      textTransform: 'uppercase',
      color: color.greyscale.grey,
      border: `1px solid ${color.greyscale.iron}`,
      backgroundColor: 'transparent',
      height: toRem(62),
      flex: 'auto',
      borderRadius: toRem(3),
    }),
    ...(variant === 'leftCenteredDefault' && {
      fontSize: typography.fontSizeMobile.interactionsBoldSmallWide,
      fontWeight: typography.fontWeight.bold,
      lineHeight: typography.lineHeightMobile.interactionsBoldSmall,
      fontFamily: typography.fontFamily.helveticaBold,
      letterSpacing: '1px',
      textTransform: 'uppercase',
      color: color.primary.elementaryBlue,
      // border: `1px solid ${color.primary.elementaryBlue}`,
      backgroundColor: 'transparent',
      height: toRem(62),
      flex: 'auto',
      borderRadius: toRem(3),
    }),
    [theme.breakpoint.up('md')]: {
      maxWidth: maxWidthDesktop ? maxWidthDesktop : '100%',
      width: desktopWidth ? desktopWidth : 'auto',
      ...((variant === 'leftCenteredPrimary' ||
        variant === 'leftCenteredSecondary' ||
        variant === 'leftCenteredDefault') && {
        width: '100%',
      }),
    },
  }
}

export default style
