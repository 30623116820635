/**
 * Creates media queries for theme breakpoints
 * @returns {{breakpointKeys: string[], values: {xs: number, sm: number, md: number, lg: number, xl: number, xxl: number}, up: (function(*): string), down: down, between: between, only: (function(*=): (*|string)), width: (function(*): *)}}
 */
const createMediaQueries = (breakpoints: any) => {
  // Starting default values, can be configured different in settings
  const {
    values = {
      xs: 0,
      sm: 641,
      md: 769,
      lg: 1025,
      xl: 1281,
      xxl: 1536,
    },
    unit = 'px',
    step = 5,
  } = breakpoints

  const breakpointKeys = Object.keys(values)

  // For example, up('md') will match from medium (960px) up to infinite
  const up = (key: any) => {
    const value = typeof values[key] === 'number' ? values[key] : key
    return `@media (min-width:${value}${unit})`
  }

  // For example, down('md') will match from 0 to medium + 1, being large (1280px)
  const down = (key: any) => {
    const endIndex = breakpointKeys.indexOf(key) + 1
    const upperBound = values[breakpointKeys[endIndex]]

    if (endIndex === breakpointKeys.length) {
      // Apply xLarge and down to all sizes
      return up('xs')
    }

    const value = typeof upperBound === 'number' && endIndex > 0 ? upperBound : key
    return `@media (max-width:${value - step / 100}${unit})`
  }

  // For example, between('sm', 'md') will match from small (600px) to medium + 1 (1280px)
  const between = (start: any, end: any) => {
    const endIndex = breakpointKeys.indexOf(end) + 1

    if (endIndex === breakpointKeys.length) {
      return up(start)
    }

    /* eslint-disable prettier/prettier */
    return `@media (min-width:${values[start]}${unit}) and (max-width:${
      values[breakpointKeys[endIndex]] - step / 100
    }${unit})`;
  };

  // For example, only('md') will match from medium (900px) to medium + 1 (1280px)
  const only = (key: any) => between(key, key);

  const width = (key: any) => values[key];

  return {
    breakpointKeys,
    values,
    up,
    down,
    between,
    only,
    width,
  };
};

export default createMediaQueries;
