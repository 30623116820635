import { useContext, useMemo } from 'react'
import { SnackbarContext } from '../Context/SnackBarContext/SnackbarProvider'

export const useSnackbarContext = (snackbarUid: string) => {
  const { activeSnackbars, openSnackbar, closeSnackbar } = useContext(SnackbarContext)

  const isOpen = useMemo(() => {
    return activeSnackbars?.has(snackbarUid)
  }, [activeSnackbars, snackbarUid])

  const handleOpen = () => {
    openSnackbar(snackbarUid)
  }

  const handleClose = () => {
    closeSnackbar(snackbarUid)
  }

  return { isOpen, open: handleOpen, close: handleClose }
}

export default useSnackbarContext
