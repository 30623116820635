import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PageSection from '../components/organisms/PageSection/PageSection'
import { FlexComponent } from '../components/organisms/Layout/Flex'
import { ReactComponent as ArrowRightIcon } from '../../icons/ArrowRight.svg'
import { Button } from '../components/atoms/Button'
import { ReactComponent as CheckIcon } from '../../icons/check.svg'
import { Text } from '../components/atoms/Text'

const ConfirmationPage = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const flow = location.state
  const isRegistration = flow === 'registration'

  return (
    <>
      <PageSection headlineMaxWidth={650} headline={t('confirmation-page.headline')} />

      <PageSection sectionMaxWidth={990}>
        <FlexComponent gap={26} flexDirection={'column'} mb={80} mt={[24, 24, 60, 60]}>
          <FlexComponent justifyContent='center' mb={[24, 24, 60, 60]}>
            <Text text={t('confirmation-page.subtext')} />
          </FlexComponent>
          <FlexComponent flexDirection={['column', 'column', 'row', 'row']} gap={40}>
            <FlexComponent flexDirection='row' width={['100%', '100%', '50%', '50%']} gap={12}>
              <FlexComponent flexShrink={0}>
                <CheckIcon />
              </FlexComponent>
              <Text
                text={
                  isRegistration
                    ? t('confirmation-page.checkmarks.first-registration')
                    : t('confirmation-page.checkmarks.first-login')
                }
              />
            </FlexComponent>
            <FlexComponent flexDirection='row' width={['100%', '100%', '50%', '50%']} gap={12}>
              <FlexComponent flexShrink={0}>
                <CheckIcon />
              </FlexComponent>
              <Text
                text={
                  isRegistration
                    ? t('confirmation-page.checkmarks.second-registration')
                    : t('confirmation-page.checkmarks.second-login')
                }
              />
            </FlexComponent>
          </FlexComponent>

          <FlexComponent alignItems='center' gap={2} mb={[100, 100, 200, 200]}>
            <ArrowRightIcon />
            <Button variant='text' label={t('confirmation-page.register-more-products')} url='/product-registration' />
          </FlexComponent>
        </FlexComponent>
      </PageSection>
    </>
  )
}

export default ConfirmationPage
