import styled from '@emotion/styled'

import { FlexComponent } from '../../organisms/Layout/Flex'
import { style, styleFormWrapper } from './Form.style'
interface IForm {
  onSubmit: (data: any) => void
  children: React.ReactNode
  title?: string
  description?: string
  mt?: number
}

const StyledFormWrapper = styled.div<Partial<IForm>>((props) => ({ ...styleFormWrapper(props) }))
const StyledForm = styled.form<Partial<IForm>>(() => ({ ...style() }))

const Form = ({ onSubmit, children, title, description, mt }: IForm) => {
  return (
    <StyledFormWrapper mt={mt}>
      {(title || description) && (
        <FlexComponent flexDirection='column' mb={[24, 24, 24, 28]}>
          <h3 className='form-title'>{title}</h3>
          <span className='form-description'>{description}</span>
        </FlexComponent>
      )}
      <StyledForm onSubmit={onSubmit}>{children}</StyledForm>
    </StyledFormWrapper>
  )
}

export default Form
