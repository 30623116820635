import {
  ControlProps,
  components,
  DropdownIndicatorProps,
  SingleValueProps,
  OptionProps as ReactSelectOptionProps,
} from 'react-select'
import styled from '@emotion/styled'
import { ActionMeta, MultiValue } from 'react-select'
import AsyncSelect from 'react-select/async'
import { FlexComponent } from '../../../components/organisms/Layout/Flex'
import { Text } from '../Text'
import { ReactComponent as WarningIcon } from '../../../../icons/Warning.svg'
import { styleSelectWrap, styleLabel } from './Select.style'

interface IOption {
  value: string
  label: string
  img?: React.ReactNode
}

interface ISelect {
  loadOptionsFunction?: (inputValue: string) => Promise<IOption[]>
  options?: IOption[]
  value?: IOption
  placeholder?: string
  label?: React.ReactNode
  defaultValue?: IOption
  isRequired?: boolean
  isDisabled?: boolean
  isSearchable?: boolean
  noOptionsMessage?: string
  isError?: boolean
  helperText?: string
  isReadOnly?: boolean
  icon?: React.ReactElement
  isFloating?: boolean
  onChange?: (option: MultiValue<IOption>, actionMeta: ActionMeta<IOption>) => void
  onBlur?: () => void
}

const SELECT_BASE_CLASS_NAME = 'ui-select'

const CustomOption = (props: ReactSelectOptionProps<IOption, true>) => {
  return (
    <components.Option {...props}>
      <FlexComponent justifyContent='space-between'>
        <FlexComponent gap={12}>
          {props.data.img}
          {props.label}
        </FlexComponent>
      </FlexComponent>
    </components.Option>
  )
}

const SingleValue = ({ ...props }: SingleValueProps<IOption>) => {
  return (
    <components.SingleValue {...props}>
      <FlexComponent gap={16}>
        {props.data.img}
        {props.data.label}
      </FlexComponent>
    </components.SingleValue>
  )
}

const StyledLabel = styled.label<ISelect>((props) => ({ ...styleLabel(props) }))

const StyledSelectWrap = styled.div<ISelect>((props) => ({ ...styleSelectWrap(props) }))

const Select = ({
  options,
  value,
  placeholder,
  label,
  defaultValue,
  isRequired,
  isDisabled = false,
  isSearchable = true,
  isError,
  onBlur,
  helperText,
  isReadOnly,
  onChange,
  loadOptionsFunction,
}: ISelect) => {
  const DropdownIndicator = (props: DropdownIndicatorProps<IOption, true>) => {
    return isError ? <components.DropdownIndicator {...props}>{<WarningIcon />}</components.DropdownIndicator> : null
  }

  const Control = ({ children, ...props }: ControlProps<IOption>) => {
    return (
      <>
        <StyledLabel isError={isError} isFloating={props.isFocused || props.hasValue || isError}>
          {label}
          {isRequired && '*'}
        </StyledLabel>
        <components.Control {...props}>{children}</components.Control>
      </>
    )
  }
  return (
    <div>
      <StyledSelectWrap options={options} isReadOnly={isReadOnly} isDisabled={isDisabled} isError={isError}>
        <AsyncSelect
          className={SELECT_BASE_CLASS_NAME}
          classNamePrefix={SELECT_BASE_CLASS_NAME}
          options={options}
          loadOptions={loadOptionsFunction}
          noOptionsMessage={() => null}
          value={value}
          defaultValue={defaultValue}
          isDisabled={isDisabled || isReadOnly}
          placeholder={label ? ' ' : placeholder || ' '}
          isSearchable={isSearchable}
          components={{
            Control,
            IndicatorSeparator: null,
            DropdownIndicator,
            Option: CustomOption,
            SingleValue,
          }}
          onChange={onChange}
          onBlur={onBlur}
        />
      </StyledSelectWrap>
      {helperText && isError && (
        <FlexComponent mt={'2px'}>
          <Text variant='error' text={helperText} />
        </FlexComponent>
      )}
    </div>
  )
}

export default Select
