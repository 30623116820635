import styled from '@emotion/styled'
import * as Accordion from '@radix-ui/react-accordion'
import { ReactComponent as ChevronDown } from '../../../../icons/ChevronDown.svg'
import { Divider } from '../../atoms/Divider'
import { accordionItem } from './Accordion.style'

const StyledAccordionItem = styled(Accordion.Item)<any>((props) => ({ ...accordionItem(props) }))

interface IAccordionItem {
  headerTitle: string
  content: any
  value: string
}

const AccordionItemWithContent = ({ headerTitle, content, value }: IAccordionItem) => {
  const isDesktop = window.innerWidth > 768
  return (
    <StyledAccordionItem value={value}>
      <Accordion.Trigger className='accordionItem-trigger'>
        <Accordion.Header className='accordionItem-headerTitleWIthContent'>
          <span>{headerTitle}</span>
          <ChevronDown className='accordionItem-chevron' />
        </Accordion.Header>
        {isDesktop && <Divider />}
      </Accordion.Trigger>
      <Accordion.Content className='accordionItem-contentWrapperWithContent'>{content}</Accordion.Content>
    </StyledAccordionItem>
  )
}

export default AccordionItemWithContent
