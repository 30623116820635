import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAccess } from '../../Context/AccessContext'

interface ProtectedRouteProps {
  children: React.ReactNode
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { hasAccess } = useAccess()

  if (!hasAccess) {
    return <Navigate to='/' replace />
  }

  return <>{children}</>
}

export default ProtectedRoute
