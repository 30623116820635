import styled from '@emotion/styled'
import { Content, DialogContentProps } from '@radix-ui/react-dialog'

import { contentStyles } from './Modal.style'
import ModalHeader from './ModalHeader'

const StyledModalContent = styled(Content)<Partial<Props>>((props) => ({ ...contentStyles(props) }))

interface Props extends DialogContentProps {
  children: React.ReactNode
  onClose?: () => void
  modalTitle: string
  maxWidth?: string
  height?: string
  mobileHeight?: string
  doesCloseExist?: boolean
  preventPointerDownOutside?: boolean
}

const ModalContent = ({
  children,
  mobileHeight,
  height,
  doesCloseExist = true,
  onClose,
  modalTitle,
  maxWidth,
  preventPointerDownOutside = false,
}: Props) => (
  <StyledModalContent
    maxWidth={maxWidth}
    height={height}
    mobileHeight={mobileHeight}
    {...(preventPointerDownOutside && { onPointerDownOutside: (e) => e.preventDefault() })}
  >
    <ModalHeader doesCloseExist={doesCloseExist} onClose={onClose} modalTitle={modalTitle} />
    {children}
  </StyledModalContent>
)

export default ModalContent
