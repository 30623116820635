import styled from '@emotion/styled'
import { ReactComponent as CheckedIcon } from '../../../../icons/CheckboxChecked.svg'
import { ReactComponent as UncheckedIcon } from '../../../../icons/CheckboxEmpty.svg'
import style from './Checkbox.style'

interface ICheckbox {
  isChecked: boolean
  onChange?: () => void
  isError?: boolean
}

const StyledCheckbox = styled.label<Partial<ICheckbox>>(style)

const Checkbox = ({ isChecked, onChange, isError }: ICheckbox) => {
  return (
    <StyledCheckbox className='checkbox-label' isError={isError} isChecked={isChecked}>
      <input type='checkbox' checked={isChecked} onChange={onChange} />
      <span className='checkbox-custom'>{isChecked ? <CheckedIcon /> : <UncheckedIcon />}</span>
    </StyledCheckbox>
  )
}

export default Checkbox
