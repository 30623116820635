import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { FlexComponent } from '../Layout/Flex'
import { Button } from '../../atoms/Button'
import { ReactComponent as ArrowRightIcon } from '../../../../icons/ArrowRight.svg'
import { makePublicPathUrl } from '../../../../helpers/functions/routing'
import style from './EmptyState.style'

const StyledEmptyState = styled.div((props) => ({ ...style(props) }))

const EmptyState = () => {
  const { t } = useTranslation()
  return (
    <StyledEmptyState>
      <FlexComponent className='emptyState-wrapper' flexDirection='column'>
        <span className='emptyState-title'>{t('product-registration.search-dropdown.empty-state.title')}</span>
        <img className='emptyState-image' src={makePublicPathUrl('/images/empty-state.png')} />
      </FlexComponent>
      <FlexComponent flexDirection='column' maxWidth={410}>
        <FlexComponent gap={16} alignItems='center'>
          <span className='emptyState-divider' />
          <span className='emptyState-subtitle'>
            {t('product-registration.search-dropdown.empty-state.subtitle-first')}
          </span>
        </FlexComponent>
        <FlexComponent gap={16} mt={40} alignItems='center'>
          <span className='emptyState-divider' />
          <span className='emptyState-subtitle'>
            {t('product-registration.search-dropdown.empty-state.subtitle-second')}
          </span>
        </FlexComponent>
        <FlexComponent mt={16} alignItems='center'>
          <ArrowRightIcon />
          <Button
            variant='text'
            label={t('product-registration.search-dropdown.empty-state.button-first')}
            url='/product-details'
          />
        </FlexComponent>
      </FlexComponent>
    </StyledEmptyState>
  )
}

export default EmptyState
