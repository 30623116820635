import IRoute from '../../types/route/route.interface'
import Home from '../Pages/Home'
import ProductRegistration from '../Pages/ProductRegistration'
import ConfirmationPage from '../Pages/ConfirmationPage'
import ConfirmRegistrationPage from '../Pages/ConfirmRegistrationPage'
import ProductDetailsDefault from '../Pages/ProductDetailsDefault'
import SinkMaterialPage from '../Pages/SinkMaterialPage'
import TapStepsPage from '../Pages/TapStepsPage'
import WasteStepsPage from '../Pages/WasteStepsPage'
import ProtectedRoute from '../../helpers/utils/ProtectedRoute'

const routes: IRoute[] = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: 'product-registration',
    element: <ProductRegistration />,
  },
  {
    path: 'confirmation-page',
    element: (
      <ProtectedRoute>
        <ConfirmationPage />
      </ProtectedRoute>
    ),
  },
  {
    path: 'confirm-registration',
    element: <ConfirmRegistrationPage />,
  },
  {
    path: 'product-details',
    element: <ProductDetailsDefault />,
  },
  {
    path: 'product-details/sink',
    element: <SinkMaterialPage />,
  },
  {
    path: 'product-details/tap',
    element: <TapStepsPage />,
  },
  {
    path: 'product-details/waste',
    element: <WasteStepsPage />,
  },
]

export default routes
