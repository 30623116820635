import { CSSObject } from '@emotion/react'
import { toRem } from '../../../../helpers/functions'

export const style = ({ theme }: any): CSSObject => {
  const { color, typography, breakpoint } = theme
  return {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    '& .tabs-cta': {
      border: 0,
      boxShadow: 'none',
      backgroundColor: 'transparent',
      textTransform: 'uppercase',
      color: color.primary.elementaryBlue,
      fontSize: typography.fontSize.interactionsBoldSmallWide,
      lineHeight: typography.lineHeight.interactionsBoldSmallWide,
      marginTop: toRem(40),
      width: '50%',
      [breakpoint.up('md')]: {
        marginTop: toRem(60),
      },
      "&[data-state='inactive']": {
        color: color.greyscale.shipGrey,
        borderBottom: `2px solid ${color.greyscale.iron}`,
        paddingBottom: toRem(14),
      },
      "&[data-state='active']": {
        color: color.primary.elementaryBlue,
        borderBottom: `2px solid ${color.primary.elementaryBlue}`,
        paddingBottom: toRem(14),
      },
    },
  }
}

export const tabcontentStyles = ({ theme }: any): CSSObject => {
  const { breakpoint } = theme
  return {
    display: 'grid',
    gridTemplateColumns: '1fr',
    alignItems: 'start',
    [breakpoint.up('md')]: {
      gap: toRem(110),
      // gridTemplateColumns: 'minmax(auto, 482px) 1fr',
      gridTemplateColumns: '1fr 1fr',
    },
  }
}
