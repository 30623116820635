import { CSSObject } from '@emotion/react'
import { toRem } from '../../../../helpers/functions'

const style = ({ theme, hasBorders, isDisabled }: any): CSSObject => {
  const { typography, color, breakpoint, spacings } = theme

  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: `1px solid ${color.greyscale.lightGrey}`,
    borderBottom: `1px solid ${color.greyscale.lightGrey}`,

    ...(isDisabled && {
      opacity: 0.5,
    }),
    maxWidth: toRem(1160),
    width: '100%',
    margin: '0 auto',
    padding: `${spacings.s16} ${spacings.s24}`,
    [breakpoint.up('md')]: {
      padding: hasBorders ? `${spacings.s24}` : 0,
    },
    '& .headline': {
      fontSize: toRem(20),
      lineHeight: typography.lineHeightMobile.copyBold,
      fontFamily: typography.fontFamily.helveticaBold,
      color: hasBorders ? color.primary.elementaryBlue : color.greyscale.shipGrey,
      fontWeight: typography.fontWeight.bold,
      maxWidth: toRem(1038),
      textAlign: 'left',
      width: '100%',
      padding: 0,
      [breakpoint.up('md')]: {
        fontSize: typography.fontSize.h5Bold,
        lineHeight: typography.lineHeightMobile.h5Bold,
      },
      [breakpoint.up('lg')]: {
        padding: hasBorders ? `0 ${spacings.s24}` : 0,
      },
    },
    ...(!hasBorders && {
      border: 0,
      padding: 0,
    }),
  }
}

export default style
