import { useTranslation } from 'react-i18next'
import { ReactComponent as CheckIcon } from '../../../../icons/check.svg'
import List from '../../../components/atoms/List/List'
import { FlexComponent } from '../../../components/organisms/Layout/Flex'
import Newsletter from '../../../components/atoms/Newsletter/Newsletter'
import { HideOn } from '../../../components/atoms/HideOn'
import { ReactComponent as DocumentIcon } from '../../../../icons/Document.svg'
import { ReactComponent as OfferIcon } from '../../../../icons/Offer.svg'
import { Button } from '../../../components/atoms/Button'

const Content = () => {
  const { t } = useTranslation()

  const list = [
    {
      icon: <CheckIcon />,
      text: t('home.list.first'),
    },
    {
      icon: <CheckIcon />,
      text: t('home.list.second'),
    },
    {
      icon: <CheckIcon />,
      text: t('home.list.third'),
    },
    {
      icon: <CheckIcon />,
      text: t('home.list.fourth'),
    },
  ]

  const newsletterIncentives = [
    {
      icon: <OfferIcon opacity={'0.5'} />,
      text: t('home.newsletter.first'),
    },
    {
      icon: <DocumentIcon opacity={'0.5'} />,
      text: t('home.newsletter.second'),
    },
  ]
  return (
    <FlexComponent
      maxWidth={1038}
      width='100%'
      alignItems='flex-start'
      gap={[0, 0, 10, 110]}
      mt={[40, 40, 80, 80]}
      pl={[0, 0, 24, 24]}
      pr={[0, 0, 24, 24]}
      ml='auto'
      mr='auto'
      flexDirection={['column', 'column', 'row', 'row']}
    >
      <HideOn mobile>
        <FlexComponent maxWidth={['100%', '100%', 483, 483]}>
          <List list={list} title={t('home.list.headline')} />
        </FlexComponent>
        <FlexComponent alignContent='flex-end' mt={100}>
          <Button url='/product-registration' variant='primary' label={t('home.button.label')} />
        </FlexComponent>
      </HideOn>
      <FlexComponent width='100%' maxWidth={['100%', '100%', 400, 400]}>
        <Newsletter
          newsletterIncentives={newsletterIncentives}
          title={t('home.newsletter.headline')}
          description={t('home.newsletter.description')}
        />
      </FlexComponent>
    </FlexComponent>
  )
}

export default Content
