import React, { createContext, useContext, useState, ReactNode } from 'react'

interface AccessContextType {
  hasAccess: boolean
  setHasAccess: (hasAccess: boolean) => void
}

const AccessContext = createContext<AccessContextType | null>(null)

interface AccessProviderProps {
  children: ReactNode
}

export const AccessProvider: React.FC<AccessProviderProps> = ({ children }) => {
  const [hasAccess, setHasAccess] = useState(false)

  return <AccessContext.Provider value={{ hasAccess, setHasAccess }}>{children}</AccessContext.Provider>
}

export const useAccess = (): AccessContextType => {
  const context = useContext(AccessContext)
  if (context === null) {
    throw new Error('useAccess must be used within an AccessProvider')
  }
  return context
}
