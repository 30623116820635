import { CSSObject } from '@emotion/react'
import { toRem } from '../../../../helpers/functions'

export const style = ({ theme, maxWidth, label, value, hasError, type }: any): CSSObject => {
  const { typography, color, breakpoint, spacings } = theme

  const placeholderStyles = {
    fontSize: toRem(16),
    fontWeight: typography.fontWeight.light,
    color: color.greyscale.grey,
    borderRadius: 3,
    display: 'flex',
    alignItems: 'center',
    marginTop: '6px', //to align label-placeholder vertically
  }

  const floatingLabelStyle = {
    fontSize: toRem(12),
    fontFamily: typography.fontFamily.helveticaBold,
    lineHeight: toRem(16),
    top: spacings.s12,
    transition: '0.35s ease all',
    ontWeight: typography.fontWeight.bold,
    marginBottom: 'auto',
    color: hasError ? color.applicationStates.error : color.greyscale.grey,
    marginTop: 0,
  }

  return {
    backgroundColor: color.greyscale.alabaster,
    fontSize: toRem(16),
    lineHeight: toRem(28),
    fontWeight: typography.fontWeight.light,
    fontFamily: typography.fontFamily.helveticaLight,
    color: color.primary.elementaryBlue,
    borderRadius: 3,
    display: 'flex',
    alignItems: 'center',
    gap: spacings.s12,
    width: '100%',
    transition: 'all 0.35s ease-in-out',
    maxWidth: '100%',
    padding: `0 ${spacings.s20}`,
    position: 'relative',
    [breakpoint.up('md')]: {
      maxWidth: maxWidth,
    },

    '& label': {
      ...placeholderStyles,
      position: 'absolute',
      pointerEvents: 'none',
      ...(value || hasError ? floatingLabelStyle : {}),
    },

    '& svg': {
      minWidth: toRem(24),
    },

    '& input': {
      letterSpacing: type === 'password' ? spacings.s8 : 'normal',
      fontSize: spacings.s16,
      fontWeight: typography.fontWeight.light,
      fontFamily: typography.fontFamily.helveticaLight,
      color: color.primary.elementaryBlue,
      height: '100%',
      width: '100%',
      border: 0,
      boxShadow: 'none',
      backgroundColor: color.greyscale.alabaster,
      transition: 'width 0.35s ease-in-out',
      borderRadius: 3,
      padding: label ? `36px 0 ${spacings.s8} 0` : `${spacings.s24} 0 ${spacings.s20} `, // If ok, add s36 to spacings

      '&:focus': {
        outline: 'none',
        '~ .floatingLabel': {
          ...floatingLabelStyle,
        },
      },
      '&::placeholder': {
        ...placeholderStyles,
      },
    },
    '& .input-backIcon': {
      cursor: 'pointer',
    },
  }
}
