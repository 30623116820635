import React from 'react'
import styled from '@emotion/styled'
import { ReactComponent as RadioOnIcon } from '../../../../icons/Radio-On.svg'
import { ReactComponent as RadioOffIcon } from '../../../../icons/Radio-Off.svg'
import { Text } from '../../../components/atoms/Text'
import { style } from './RadioButton.style'

const StyledRadioButton = styled.label<Partial<IRadioButton>>((props) => ({ ...style(props) }))

interface IRadioButton {
  isChecked: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  label?: string
  value: string
  isError?: boolean
  onClick?: () => void
}

const RadioButton = ({ isChecked, onChange, value, label, isError, onClick }: IRadioButton) => {
  return (
    <StyledRadioButton isError={isError} onClick={onClick}>
      <input type='radio' value={value} checked={isChecked} onChange={onChange} />
      <span className='radio-button-custom'>{isChecked ? <RadioOnIcon /> : <RadioOffIcon />}</span>
      {label && <Text text={label} />}
    </StyledRadioButton>
  )
}

export default RadioButton
