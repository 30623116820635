import { useTranslation } from 'react-i18next'
import { useProductsList } from '../../../../Context/ProductListContext'
import { useModalContext } from '../../../../hooks'
import { Button } from '../../../components/atoms/Button'
import { FlexComponent } from '../../../components/organisms/Layout/Flex'
import { ModalComponent } from '../../../components/organisms/Modal'

interface IConfirmationDeleteProductModal {
  // productId: string
  secondId?: string
}

const ConfirmationDeleteProductModal = ({
  // productId,
  secondId,
}: IConfirmationDeleteProductModal) => {
  const { t } = useTranslation()
  const confirmationDeleteProductModalID = 'confirmationDeleteProductModal'

  const confirmationDeleteProductModal = useModalContext(confirmationDeleteProductModalID)
  const handleModalClose = () => confirmationDeleteProductModal.close()
  const { productsList, setProductsList } = useProductsList()

  const deleteItem = () => {
    const updatedProductsList = productsList.filter((product) => product.second_id !== secondId)

    setProductsList(updatedProductsList)

    localStorage.setItem('selectedItems', JSON.stringify(updatedProductsList))

    handleModalClose()
  }

  return (
    <ModalComponent
      isOpen={confirmationDeleteProductModal.isOpen}
      modalTitle={t('product-registration.confirmation-modal.title')}
      maxWidth='604px'
      height='auto'
      mobileHeight='350px'
      doesCloseExist={false}
      onClose={handleModalClose}
    >
      <FlexComponent
        flexDirection={['column', 'column', 'row', 'row']}
        justifyContent='space-between'
        width='100%'
        gap={10}
      >
        <Button
          variant='tertiary'
          label={t('product-registration.confirmation-modal.button.delete')}
          onClick={deleteItem}
        />
        <Button
          variant='quaternary'
          label={t('product-registration.confirmation-modal.button.cancel')}
          onClick={handleModalClose}
        />
      </FlexComponent>
    </ModalComponent>
  )
}

export default ConfirmationDeleteProductModal
