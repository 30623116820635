import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import style from './ImageTextOverlay.style'

interface IImageTextOverlay {
  icon: string
  text: string
  href?: string
}

const StyledImageTextOverlay = styled.div<Partial<IImageTextOverlay>>((props) => ({
  ...style(props),
  cursor: 'pointer', // Indicate the element is clickable
}))

const ImageTextOverlay = ({ icon, text, href }: IImageTextOverlay) => {
  const navigate = useNavigate()

  const handleClick = () => {
    if (href) {
      navigate(href)
    }
  }

  return (
    <StyledImageTextOverlay icon={icon} onClick={handleClick}>
      <div className='textWrapper'>{text}</div>
    </StyledImageTextOverlay>
  )
}

export default ImageTextOverlay
