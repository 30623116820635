import { useState, useEffect, useRef } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useQuery } from 'react-query'
import { debounce } from 'lodash'
import { FlexComponent } from '../../../components/organisms/Layout/Flex'
import { BigDropdown } from '../../../components/organisms/BigDropdown'
import { Button } from '../../../components/atoms/Button'
import { Input } from '../../../components/atoms/Input'
import { ReactComponent as SearchIcon } from '../../../../icons/Search.svg'
import { ReactComponent as CloseIcon } from '../../../../icons/Close.svg'
import { useModalContext } from '../../../../hooks'
import { ProductInfoModal } from '../ProductInfoModal'
import { accordionData } from '../../../../static/accordionData'
import { useUser } from '../../../../Context/UserContext'

interface Props {
  t: any
}

const SearchProduct = ({ t }: Props) => {
  const { user } = useUser()
  const { control, watch, setValue } = useForm()
  const inputValue = watch('productSearch', '')
  const inputHasMinimumLength = inputValue.length >= 3
  const inputRef = useRef<HTMLInputElement | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isInputActive, setIsInputActive] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [isFetching, setIsFetching] = useState(false)

  const fetchProducts = async ({ queryKey }: { queryKey: string[] }) => {
    const [, productNumber] = queryKey
    const response = await fetch(
      // `https://func-b-ww-product-registration-api-d-01.azurewebsites.net/api/getProducts?locale=en_UK&productgroup=sink&productnumberislike=${productNumber}`,
      `${process.env.REACT_APP_BLANCO_PRODUCT_REGISTRATION_API}/api/getProducts?locale=${
        user?.localeBlanco || 'en_GB'
      }&text=${productNumber}&productgroup=all`,
    )
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    // return response.json()
    const responseData = await response.json() // Resolve the promise and store the data
    // console.log('response: ', responseData) // Log the data
    return responseData
  }
  const serialNumberInstructionsModalID = 'serialNumberInstructionsModal'

  const serialNumberInstructionsModal = useModalContext(serialNumberInstructionsModalID)
  const handleModalOpen = () => serialNumberInstructionsModal.open()

  const { data, isLoading } = useQuery(['products', searchTerm], fetchProducts, {
    enabled: inputHasMinimumLength && searchTerm === inputValue,
    staleTime: 1000 * 60 * 5,
  })
  const debouncedSetSearchTerm = debounce((value: string) => {
    setSearchTerm(value)
  }, 500)

  useEffect(() => {
    if (inputHasMinimumLength) {
      setIsFetching(true)
      debouncedSetSearchTerm(inputValue)
    }
  }, [inputValue])

  useEffect(() => {
    setIsFetching(false)
  }, [data, searchTerm === inputValue])

  const handleActiveInput = () => {
    if (window.innerWidth <= 768) {
      if (inputRef.current) {
        const yOffset = -30
        const y = inputRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset
        window.scrollTo({ top: y, behavior: 'smooth' })
      }
    }
    setIsInputActive((prevState) => !prevState)
  }

  const clearInput = () => {
    setValue('productSearch', '')
  }

  const clearInputAndCloseDropdown = () => {
    clearInput()
    setIsInputActive(false)
  }

  return (
    <>
      <FlexComponent
        alignItems='flex-start'
        width='100%'
        flexDirection='column'
        gap={[10, 10, 24, 24]}
        className='relative'
      >
        <Controller
          name='productSearch'
          control={control}
          defaultValue=''
          render={({ field }) => (
            <Input
              {...field}
              ref={(e: any) => {
                field.ref(e)
                inputRef.current = e
              }}
              maxWidth={inputHasMinimumLength ? '100%' : '480px'}
              onClick={handleActiveInput}
              placeholder={t('product-registration.section.step.first.input.placeholder')}
              icon={<SearchIcon />}
              backIcon={inputHasMinimumLength ? <CloseIcon /> : null}
              onBackIconClick={clearInput}
            />
          )}
        />
        <BigDropdown
          closeDropdown={clearInputAndCloseDropdown}
          isLoading={isFetching || isLoading}
          t={t}
          isActive={inputHasMinimumLength}
          items={data}
        />
        <Button
          onClick={handleModalOpen}
          variant='secondary'
          label={t('product-registration.section.step.first.input.underLabel')}
        />
      </FlexComponent>
      <ProductInfoModal accordionData={accordionData} t={t} />
    </>
  )
}

export default SearchProduct
