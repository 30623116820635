import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ReactComponent as BlancoLogo } from '../../../../icons/logo.svg'
import Dropdown from '../../molecules/Dropdown/Dropdown'
import { IUserIp } from '../../../../types/API/userIp'
import { CountryModal } from '../../../Templates/Home/CountryModal'
import { useModalContext } from '../../../../hooks'
import { style } from './Header.style'

const StyledHeader = styled.header((props) => ({ ...style(props) }))

const Header = () => {
  const { i18n, t } = useTranslation()

  const [userCountry, setUserCountry] = useState<IUserIp>({
    ip: '',
    network: '',
    version: '',
    city: '',
    region: '',
    region_code: '',
    country: '',
    country_name: '',
    country_code: '',
    country_code_iso3: '',
    country_capital: '',
    country_tld: '',
    continent_code: '',
    in_eu: false,
    postal: '',
    latitude: 222,
    longitude: 222,
    timezone: '',
    utc_offset: '',
    country_calling_code: '',
    currency: '',
    currency_name: '',
    languages: '',
    country_area: 222,
    country_population: 222,
    asn: '',
    org: '',
  })
  const [langList, setLangList] = useState<Array<{ langCode: string; langDisplay: string }>>([])
  const [isLangSwitchDisabled, setIsLangSwitchDisabled] = useState<boolean>(false)
  const location = useLocation()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ipApiKey = process.env.REACT_APP_IPAPI_KEY?.trim()
        const additionalPart = ipApiKey ? `?key=${ipApiKey}` : ''

        const response = await fetch('https://ipapi.co/json/' + additionalPart)
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const jsonData = await response.json()
        setUserCountry(jsonData)
        setLoading(false) // Set loading to false after fetching data
      } catch (error) {
        console.error('Failed to fetch user country:', error)
        setLoading(false) // Also set loading to false in case of error
      }
    }

    fetchData()
  }, [])

  const handleLanguageChange = (langCode: string) => {
    i18n.changeLanguage(langCode)
    localStorage.setItem('language', langCode)
  }

  useEffect(() => {
    let languages: string[] = []
    switch (userCountry.country_code) {
      case 'CH':
        languages = ['de', 'fr']
        break
      case 'DE':
        languages = []
        setIsLangSwitchDisabled(true)
        break
      case 'AT':
        languages = []
        setIsLangSwitchDisabled(true)
        break
      case 'GB':
        languages = []
        setIsLangSwitchDisabled(true)
        break
      case 'UK':
        languages = []
        setIsLangSwitchDisabled(true)
        break
      default:
        languages = ['de', 'en', 'fr']
    }

    setLangList(
      languages.map((lang) => ({
        langCode: lang,
        langDisplay: t(`home.language-switcher.${lang}`),
      })),
    )

    const savedLang = localStorage.getItem('language')
    const defaultLang =
      (savedLang && languages.includes(savedLang) && savedLang) ||
      (['DE', 'AT', 'CH'].includes(userCountry.country_code) ? 'de' : 'en')
    i18n.changeLanguage(defaultLang)
  }, [userCountry.country_code])

  const countryModalID = 'countryModal'
  const countryModal = useModalContext(countryModalID)

  const userDomain = window.location.hostname.split('.').pop()?.toLowerCase()
  const userLocation = (() => {
    if (['uk', 'gb'].includes(userCountry.country_code.toLowerCase())) {
      return 'uk'
    }

    return userCountry.country_code.toLowerCase()
  })()

  const [scenario, setScenario] = useState<'A' | 'B' | 'C' | 'D' | undefined>(undefined)

  useEffect(() => {
    const handleUseCases = () => {
      const validCountriesAndDomains = ['de', 'ch', 'at', 'uk']

      if (validCountriesAndDomains.includes(userLocation) && validCountriesAndDomains.includes(userDomain || '')) {
        setScenario(userDomain === userLocation ? 'A' : 'B')
      } else if (userLocation && !validCountriesAndDomains.includes(userLocation)) {
        setScenario('C')
      } else {
        setScenario('D')
      }
    }

    handleUseCases()
    countryModal.open()
  }, [userLocation, userDomain])

  return (
    <StyledHeader>
      {scenario !== 'A' && (
        <CountryModal
          scenario={scenario}
          countryName={userCountry.country_name}
          countryCode={userLocation}
          userDomain={userDomain}
        />
      )}
      <Link to={'/'}>
        <BlancoLogo />
      </Link>
      {location.pathname === '/' &&
        !loading && ( // Render dropdown only when loading is false
          <Dropdown
            align='end'
            sideOffset={-38}
            isDisabled={isLangSwitchDisabled}
            onSelect={handleLanguageChange}
            items={langList}
            defaultActiveItem={i18n.language}
          />
        )}
    </StyledHeader>
  )
}

export default Header
