import styled from '@emotion/styled'
import * as Tabs from '@radix-ui/react-tabs'
import { style } from './Tabs.style'

interface ITabTitles {
  text: string
}
interface ITabProps {
  tabTitles: ITabTitles[]
  children: React.ReactNode
  defaultValue?: string
}

const StyledTabs = styled(Tabs.Root)<Partial<ITabProps>>((props) => ({ ...style(props) }))

const TabsComponent = ({ tabTitles, children, defaultValue }: ITabProps) => {
  return (
    <StyledTabs defaultValue={defaultValue}>
      <Tabs.List className='tabs-list' aria-label='Manage your account'>
        {tabTitles.map((tabTitle, index) => (
          <Tabs.Trigger className='tabs-cta' value={`tabTrigger-${index}`} key={`tabTrigger-${index}`}>
            {tabTitle.text}
          </Tabs.Trigger>
        ))}
      </Tabs.List>
      {children}
    </StyledTabs>
  )
}

export default TabsComponent
