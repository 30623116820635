import React, { createContext, useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

interface User {
  newsletterEmail?: string
  gdprConsent?: string
  location?: { country: string; city: string }
  domain?: string
  language?: string
  localeDiva?: string
  localeBlanco?: string
}

type SetUser = React.Dispatch<React.SetStateAction<User | null>>

interface IUserContext {
  user: User | null
  setUser: SetUser
}

const errorMessage = 'useUser must be used within a UserProvider'

const UserContext = createContext<IUserContext | undefined>(undefined)

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const { i18n } = useTranslation()
  const [user, setUser] = useState<User | null>(null)

  useEffect(() => {
    const getLocales = (domain?: string, language?: string) => {
      // const validLanguages = ['de', 'fr', 'en']
      // const validDomains = ['de', 'ch', 'at', 'uk']

      if (domain === 'uk') return ['en-GB', 'en_GB']
      if (domain === 'at') return ['de-AT', 'de_AT']
      if (domain === 'de') return ['de-DE', 'de_DE']
      if (domain === 'ch' && language === 'fr') return ['fr-CH', 'fr_CH']
      if (domain === 'ch' && language === 'de') return ['de-CH', 'de_CH']
      if (language === 'de') return ['de-DE', 'de_DE']
      if (language === 'fr') return ['fr-CH', 'fr_CH']
      return ['en-GB', 'en_GB']
    }

    const initializeUserContext = async () => {
      const domain = window.location.hostname.split('.').pop()
      const language = i18n.language
      const localeDiva = getLocales(domain, language)[0]
      const localeBlanco = getLocales(domain, language)[1]

      setUser((prevUser) => ({
        ...prevUser,
        domain,
        language,
        localeDiva,
        localeBlanco,
      }))
    }

    initializeUserContext()

    // Update language when it changes in i18n
    const handleLanguageChange = (lang: string) => {
      setUser((prevUser) => ({
        ...prevUser,
        language: lang,
      }))
    }

    i18n.on('languageChanged', handleLanguageChange)

    return () => {
      i18n.off('languageChanged', handleLanguageChange)
    }
  }, [])

  return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>
}

export const useUser = (): IUserContext => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error(errorMessage)
  }
  return context
}
