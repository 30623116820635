import styled from '@emotion/styled'

import style from './Incentive.style'

const StyledIncetive = styled.div<Partial<IncentiveProps>>((props) => ({ ...style(props) }))

interface IncentiveProps {
  icon: React.ReactNode
  text: string
  textColor?: string
  backgroundColor?: string
}
const Incentive = ({ icon, text, textColor, backgroundColor }: IncentiveProps) => (
  <StyledIncetive textColor={textColor} backgroundColor={backgroundColor}>
    <div className='incentive-icon'>{icon}</div>
    <div className='incentive-text'>{text}</div>
  </StyledIncetive>
)

export default Incentive
