import { CSSObject } from 'theme-ui'
import { toRem } from '../../../../helpers/functions'
import { typography } from '../../../../theme/blanco/typography/typography'

export const style = ({ theme }: any): CSSObject => {
  const { spacings, color, breakpoint } = theme
  return {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: spacings.s24,
    borderBottom: `1px solid ${color.greyscale.iron}`,
    gap: spacings.s24,
    cursor: 'pointer',
    [breakpoint.up('md')]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
    },
    '& .dropdownItem': {
      '&-image': {
        maxWidth: toRem(150),
        width: '100%',
        height: 'auto',
        [breakpoint.down('md')]: {
          flexShrink: 0,
          height: toRem(100),
        },
        [breakpoint.up('md')]: {
          maxWidth: toRem(176),
          height: toRem(100),
        },
        '& img': {
          objectFit: 'contain',
        },
      },
      '&-wraper': {},
      '&-productNumber': {
        fontSize: typography.fontSizeMobile.interactionsBold,
        lineHeight: typography.lineHeightMobile.interactionsBold,
        fontWeight: typography.fontWeight.bold,
        fontFamily: typography.fontFamily.helveticaBold,
        color: color.greyscale.shipGrey,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 1,

        [breakpoint.up('md')]: {
          fontSize: typography.fontSize.copyBold,
          lineHeight: typography.lineHeight.copyBold,
        },
      },
      '&-productName': {
        fontSize: toRem(16),
        lineHeight: typography.lineHeightMobile.interactionsBoldSmallWide,
        fontWeight: typography.fontWeight.light,
        fontfamily: typography.fontFamily.helveticaLight,
        color: color.greyscale.shipGrey,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,

        [breakpoint.up('md')]: {
          fontSize: typography.fontSize.copyBold,
          lineHeight: typography.lineHeight.copyBold,
        },
      },
    },
  }
}

export default style
