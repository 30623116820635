import React, { CSSProperties } from 'react'
import { ResponsiveStyleValue, Flex as ThemeUIFlex, FlexProps as ThemeUIFlexProps } from 'theme-ui'

export interface FlexProps
  extends Pick<
    ThemeUIFlexProps,
    | 'as'
    | 'variant'
    | 'sx'
    | 'children'
    | 'p'
    | 'px'
    | 'py'
    | 'pt'
    | 'pr'
    | 'pb'
    | 'pl'
    | 'm'
    | 'mx'
    | 'my'
    | 'mt'
    | 'mr'
    | 'mb'
    | 'ml'
    | 'backgroundColor'
    | 'opacity'
    | 'className'
    | 'role'
    | 'onClick'
    | 'key'
  > {
  disabled?: boolean
  ref?: React.Ref<any>
  width?: ResponsiveStyleValue<string | number>
  maxWidth?: ResponsiveStyleValue<string | number>
  height?: ResponsiveStyleValue<string | number>
  flexDirection?: ResponsiveStyleValue<CSSProperties['flexDirection']>
  flexWrap?: ResponsiveStyleValue<CSSProperties['flexWrap']>
  flexGrow?: ResponsiveStyleValue<CSSProperties['flexGrow']>
  flexShrink?: ResponsiveStyleValue<CSSProperties['flexShrink']>
  flexBasis?: ResponsiveStyleValue<CSSProperties['flexBasis']>
  justifyContent?: ResponsiveStyleValue<CSSProperties['justifyContent']>
  alignItems?: ResponsiveStyleValue<CSSProperties['alignItems']>
  alignContent?: ResponsiveStyleValue<CSSProperties['alignContent']>
  gap?: ResponsiveStyleValue<string | number>
  rowGap?: ResponsiveStyleValue<string | number>
  columnGap?: ResponsiveStyleValue<string | number>
  textAlign?: ResponsiveStyleValue<CSSProperties['textAlign']>
  overflowX?: ResponsiveStyleValue<CSSProperties['overflowX']>
}

const Flex: React.FC<FlexProps> = React.forwardRef<any, FlexProps>((props, ref) => {
  const {
    disabled,
    children,
    width,
    maxWidth,
    height,
    flexDirection,
    flexWrap,
    flexGrow,
    flexShrink,
    flexBasis,
    justifyContent,
    alignItems,
    alignContent,
    gap,
    rowGap,
    columnGap,
    textAlign,
    overflowX,
    sx,
    ...rest
  } = props

  if (disabled) {
    return null
  }

  return (
    <ThemeUIFlex
      sx={{
        width,
        maxWidth,
        height,
        flexDirection,
        flexWrap,
        flexGrow,
        flexShrink,
        flexBasis,
        justifyContent,
        alignItems,
        alignContent,
        gap: gap,
        rowGap: rowGap,
        columnGap: columnGap,
        textAlign,
        overflowX,
        ...sx,
      }}
      {...rest}
      ref={ref}
    >
      {children}
    </ThemeUIFlex>
  )
})

Flex.displayName = 'Flex'

export default Flex
