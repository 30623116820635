import { useTranslation } from 'react-i18next'
import { ImageTextOverlay } from '../../../components/molecules/ImageTextOverlay'
import { FlexComponent } from '../../../components/organisms/Layout/Flex'
import { makePublicPathUrl } from '../../../../helpers/functions/routing'

const CategoryFooter = () => {
  const { t } = useTranslation()

  const list = [
    {
      icon: makePublicPathUrl('/images/sink-dark.jpg'),
      text: t('product-details-page.footer.sink'),
      href: '/product-details/sink',
    },
    {
      icon: makePublicPathUrl('/images/tap-category.jpg'),
      text: t('product-details-page.footer.tap'),
      href: '/product-details/tap',
    },
    {
      icon: makePublicPathUrl('/images/waste-category.jpg'),
      text: t('product-details-page.footer.waste'),
      href: '/product-details/waste',
    },
  ]

  const recommendationItems = list.map((listItem, index) => (
    <ImageTextOverlay key={index} icon={listItem.icon} text={t(listItem.text)} href={listItem.href} />
  ))

  return (
    <FlexComponent flexDirection={['column', 'column', 'row', 'row']} width='100%' gap={[16, 16, 24, 24]} mt={24}>
      {recommendationItems}
    </FlexComponent>
  )
}

export default CategoryFooter
