import { CSSObject } from '@emotion/react'

export const style = ({ theme, hasMargin }: any): CSSObject => {
  const { color, spacings } = theme
  return {
    height: '1px',
    width: '100%',
    background: color.greyscale.iron,
    margin: hasMargin ? `${spacings.s24} 0` : 0,
  }
}

export default style
