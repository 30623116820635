import styled from '@emotion/styled'
import { Text } from '../../atoms/Text/index'
import { FlexComponent } from '../../organisms/Layout/Flex'
import { ReactComponent as EmptyImage } from '../../../../icons/emptySquare.svg'
import { style } from './MaterialCard.style'

interface IMaterialCard {
  imageUrl?: string
  name: string
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  isLowerOpacity?: boolean
  isColor?: boolean
}

const StyledMaterialCard = styled.div<Partial<IMaterialCard>>((props) => ({ ...style(props) }))

const MaterialCard = ({ imageUrl, name, onClick, isLowerOpacity, onMouseEnter, onMouseLeave }: IMaterialCard) => {
  const isColor = imageUrl?.startsWith('#')
  return (
    <StyledMaterialCard
      onClick={onClick}
      isLowerOpacity={isLowerOpacity}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      isColor={isColor}
      color={imageUrl}
    >
      <FlexComponent flexDirection='row' gap={32} alignItems='center'>
        <div className='image-container'>{!isColor && (imageUrl ? <img src={imageUrl} /> : <EmptyImage />)}</div>

        <Text text={name} variant='bold' />
      </FlexComponent>
    </StyledMaterialCard>
  )
}

export default MaterialCard
