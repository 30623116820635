import styled from '@emotion/styled'
import { ReactComponent as CloseIcon } from '../../../../icons/Close.svg'
import { ReactComponent as CheckCircle } from '../../../../icons/CheckCircle.svg'
import { FlexComponent } from '../../organisms/Layout/Flex'
import DatepickerComponent from '../../molecules/Datepicker/Datepicker'
import { HideOn } from '../HideOn'
import { ReactComponent as EmptyImage } from '../../../../icons/emptyImgLong.svg'

import { style } from './ProductCard.style'

interface IProductCard {
  serialNumber: string
  productFamily: string
  imageUrl: string
  alt: string
  onProductDelete?: () => void
  startDate?: any
  onChange?: any
  t: any
  hasDatepicker?: boolean
}
//TODO: change this any to proper types and change t type across the app
const StyledProductCard = styled.div((props) => ({ ...style(props) }))

const ProductCard = ({
  serialNumber,
  productFamily,
  alt,
  t,
  onProductDelete,
  startDate,
  onChange,
  hasDatepicker = true,
  imageUrl,
}: IProductCard) => {
  return (
    <>
      <HideOn desktop>
        <StyledProductCard>
          <FlexComponent flexDirection={hasDatepicker ? 'column' : 'column-reverse'} gap={hasDatepicker ? 0 : 24}>
            <FlexComponent flexDirection='column' width='100%'>
              <FlexComponent alignItems='center' justifyContent='space-between'>
                <span className='product-card_header_serialNumber'>{serialNumber}</span>
                <div onClick={onProductDelete ? onProductDelete : undefined}>
                  {onProductDelete ? <CloseIcon /> : <CheckCircle />}
                </div>
              </FlexComponent>
              <FlexComponent>
                <span className='product-card_productFamily'>{productFamily}</span>
              </FlexComponent>
            </FlexComponent>
            <FlexComponent mt={24} justifyContent='center' width='100%' className='product-card_image'>
              {imageUrl ? <img src={imageUrl} alt={alt} /> : <EmptyImage />}
            </FlexComponent>
          </FlexComponent>
          <FlexComponent mt={24} justifyContent='center' width='100%'>
            <DatepickerComponent
              datepickerOptional={t('product-registration.product-card.datepicker-optional')}
              datepickerLabel={t('product-registration.product-card.datepicker-label')}
              placeholderText={t('product-registration.product-card.datepicker-placeholder')}
              selected={startDate}
              startDate={startDate}
              onChange={onChange}
              isDisabled={!hasDatepicker}
            />
          </FlexComponent>
        </StyledProductCard>
      </HideOn>
      <HideOn mobile>
        <StyledProductCard>
          <FlexComponent justifyContent='space-between'>
            <FlexComponent gap={32}>
              <FlexComponent className='product-card_image'>
                {imageUrl ? <img src={imageUrl} alt={alt} /> : <EmptyImage />}
              </FlexComponent>
              <FlexComponent flexDirection='column' alignItems='flex-start' justifyContent='center'>
                <span className='product-card_header_serialNumber'>{serialNumber}</span>
                <span className='product-card_productFamily'>{productFamily}</span>
              </FlexComponent>
            </FlexComponent>

            <FlexComponent gap={32} flexShrink={0}>
              <FlexComponent
                className='product-card_datepicker-wrapper'
                width='100%'
                maxWidth={['100%', '100%', 312, 312]}
              >
                <DatepickerComponent
                  datepickerOptional={t('product-registration.product-card.datepicker-optional')}
                  datepickerLabel={t('product-registration.product-card.datepicker-label')}
                  placeholderText={t('product-registration.product-card.datepicker-placeholder')}
                  selected={startDate}
                  startDate={startDate}
                  onChange={onChange}
                  isDisabled={!hasDatepicker}
                />
              </FlexComponent>
              <FlexComponent>
                <div onClick={onProductDelete}>{onProductDelete ? <CloseIcon /> : <CheckCircle />}</div>
              </FlexComponent>
            </FlexComponent>
          </FlexComponent>
        </StyledProductCard>
      </HideOn>
    </>
  )
}

export default ProductCard
