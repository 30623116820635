import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Text } from '../../../components/atoms/Text' // Adjust the import if necessary
import { getCountry } from '../../../../helpers/functions/getLanguageByCountry'
import style from './Footer.style'

const StyledFooter = styled.footer((props) => ({ ...style(props) }))

const Footer = () => {
  const { t } = useTranslation()
  const currentYear = new Date().getFullYear()
  const country = getCountry()

  const handlePrivacySettingsClick = () => {
    const event = new Event('privacySettingsClick')
    window.dispatchEvent(event)
  }

  return (
    <StyledFooter>
      <div className='footer-container'>
        <div className='footer-container__copyright'>© {currentYear} Blanco</div>
        <div className='footer-container__links'>
          <Text isHtml={true} variant='footer' text={t(`home.footer.impressum.${country}`)} />
          <Text isHtml={true} variant='footer' text={t(`home.footer.data-protection.${country}`)} />
          <Text isHtml={true} variant='footer' text={t(`home.footer.compliance.${country}`)} />
          <Text
            isHtml={true}
            isCursorPointer
            variant='footer'
            text={t(`home.footer.privacy-settings`)}
            onClick={handlePrivacySettingsClick}
          />
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer
