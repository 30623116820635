import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import styled from '@emotion/styled'
import { ReactComponent as LanguageSwitcherIcon } from '../../../../icons/languageSwitcher.svg'
import { style } from './Dropdown.style'
import DropdownPortal from './DropdownPortal'

interface IDropdown {
  items: Array<{ langCode: string; langDisplay: string }>
  onSelect: (lang: string) => void
  isDisabled?: boolean
  alignOffset?: number
  align?: 'start' | 'end'
  side?: 'bottom' | 'top' | 'left' | 'right'
  sideOffset?: number
  defaultActiveItem: string
}

const StyledDropdown = styled.div((props) => ({ ...style(props) }))

const Dropdown = ({
  items,
  side = 'bottom',
  onSelect,
  isDisabled,
  alignOffset = 0,
  align = 'start',
  sideOffset = 0,
  defaultActiveItem,
}: IDropdown) => {
  if (isDisabled) return null

  return (
    <StyledDropdown>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <button className='dropdown-icon' aria-label='Switch language'>
            <LanguageSwitcherIcon />
          </button>
        </DropdownMenu.Trigger>
        <DropdownPortal
          defaultActiveItem={defaultActiveItem}
          sideOffset={sideOffset}
          side={side}
          align={align}
          alignOffset={alignOffset}
          onSelect={onSelect}
          items={items}
        />
      </DropdownMenu.Root>
    </StyledDropdown>
  )
}

export default Dropdown
