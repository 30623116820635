import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { FlexComponent } from '../../organisms/Layout/Flex'
import { style } from './Button.style'

const StyledButton = styled.button<Partial<IButton>>((props) => ({ ...style(props) }))

interface IButton {
  width?: string
  height?: string
  label: string
  backgroundColor?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  url?: string
  variant?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'quaternary'
    | 'text'
    | 'leftCenteredPrimary'
    | 'leftCenteredSecondary'
    | 'leftCenteredDefault'
  isDisabled?: boolean
  maxWidthDesktop?: string
  maxWidthMobile?: string
  desktopWidth?: string
  className?: string
  type?: 'button' | 'submit' | 'reset'
  icon?: React.ReactNode
}

const Button = ({
  url,
  maxWidthDesktop,
  maxWidthMobile,
  isDisabled,
  onClick,
  width,
  height,
  label,
  backgroundColor,
  variant,
  desktopWidth,
  className,
  type,
  icon,
}: IButton) => {
  const buttonContent = (
    <StyledButton
      className={className}
      type={type}
      disabled={isDisabled}
      onClick={onClick}
      width={width}
      height={height}
      backgroundColor={backgroundColor}
      variant={variant}
      maxWidthMobile={maxWidthMobile}
      maxWidthDesktop={maxWidthDesktop}
      desktopWidth={desktopWidth}
      icon={icon}
    >
      {icon ? (
        <FlexComponent gap={12} alignItems='center' pl={30}>
          {icon}
          {label}
        </FlexComponent>
      ) : (
        <FlexComponent justifyContent='center'>{label}</FlexComponent>
      )}
    </StyledButton>
  )

  return url ? (
    <Link style={{ width: '100%' }} to={url}>
      {buttonContent}
    </Link>
  ) : (
    buttonContent
  )
}

export default Button
