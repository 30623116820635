import { Global, useTheme } from '@emotion/react'
import { css } from '@emotion/react'
import BlancoTheme from '../types/theme/theme.interface'
import { makePublicPathUrl } from '../helpers/functions/routing'
import style from './Styles.style'

const GlobalStyles = () => {
  const theme = useTheme() as BlancoTheme
  const fontFace = css`
    @font-face {
      font-family: 'helveticaBold';
      src: url(${makePublicPathUrl('/fonts/HelveticaNeueLTPro-Bd.otf')}) format('opentype');
    }
    @font-face {
      font-family: 'helveticaBlack';
      src: url(${makePublicPathUrl('/fonts/HelveticaNeueLTPro-Blk.otf')}) format('opentype');
    }
    @font-face {
      font-family: 'helveticaHeavy';
      src: url(${makePublicPathUrl('/fonts/HelveticaNeueLTPro-Hv.otf')}) format('opentype');
    }
    @font-face {
      font-family: 'helveticaLight';
      src: url(${makePublicPathUrl('/fonts/HelveticaNeueLTPro-Lt.otf')}) format('opentype');
    }
    @font-face {
      font-family: 'helveticaMedium';
      src: url(${makePublicPathUrl('/fonts/HelveticaNeueLTPro-Md.otf')}) format('opentype');
    }
    @font-face {
      font-family: 'helveticaRoman';
      src: url(${makePublicPathUrl('/fonts/HelveticaNeueLTPro-Roman.otf')}) format('opentype');
    }
    @font-face {
      font-family: 'helveticaThin';
      src: url(${makePublicPathUrl('/fonts/HelveticaNeueLTPro-Th.otf')}) format('opentype');
    }
    @font-face {
      font-family: 'helveticaUltraThin';
      src: url(${makePublicPathUrl('/fonts/HelveticaNeueLTPro-UltLt.otf')}) format('opentype');
    }
  `
  return (
    <>
      <Global styles={style(theme)} />
      <Global
        styles={css`
          ${fontFace}
        `}
      />
    </>
  )
}

export default GlobalStyles
