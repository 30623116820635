import styled from '@emotion/styled'
import * as Accordion from '@radix-ui/react-accordion'

import { style } from './Accordion.style'

const StyledAccordion = styled(Accordion.Root)(() => ({ ...style() }))

interface IAccordion {
  type?: 'single' | 'multiple'
  orientation?: 'horizontal' | 'vertical'
  children: React.ReactNode
}
const AccordionComponent = ({ type = 'single', orientation = 'vertical', children }: IAccordion) => (
  <StyledAccordion orientation={orientation} type={type}>
    {children}
  </StyledAccordion>
)

export default AccordionComponent
