import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'
import { StepperHeader } from '../components/molecules/StepperHeader'
import PageSection from '../components/organisms/PageSection/PageSection'
import { GridOptions } from '../Templates/ProductDetails/GridOptions'
import { ProductDetailsCard } from '../components/molecules/ProductDetailsCard'
import { Chip } from '../components/atoms/Chip'
import { Text } from '../components/atoms/Text'
import { FlexComponent } from '../components/organisms/Layout/Flex'
import { SelectModal } from '../Templates/ProductDetails/SelectModal'
import { Button } from '../components/atoms/Button'
import { randomNumberGenerator } from '../../helpers/functions/randomNumberGenerator'
import { useUser } from '../../Context/UserContext'

function useQueryParams() {
  return new URLSearchParams(useLocation().search)
}

const getData = async (queryPath: string, locale?: string) => {
  const url = new URL(
    `${process.env.REACT_APP_BLANCO_PRODUCT_REGISTRATION_API}/api/attributes/${locale}/sink${queryPath}`,
  )
  const res = await fetch(url.toString(), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
  const data = await res.json()
  if (!res.ok) {
    throw new Error(data.message)
  }
  return data
}

const SinkMaterialPage = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { user } = useUser()

  const [materialId, setMaterialId] = useState(0)
  const [colorId, setColorId] = useState(0)

  const [hoveredCard, setHoveredCard] = useState<number | null>(null)
  const queryParams = useQueryParams()
  const navigate = useNavigate()

  const queries = ['material', 'color']
  const currentQuery = queryParams.get('query') || (!location.search && queries[0]) //check if query=something is in link - current attribute
  const currentQueryIndex = currentQuery ? queries.indexOf(currentQuery) : null //index of current attribute

  // Determine the path after '/sink'
  const pathAfterProduct = location.search

  const fetchRoute = pathAfterProduct || `?query=${currentQuery}` //in case it's just /sink add query

  const pickedQueryResults = queries //values for breadcrumbs
    .slice(0, currentQueryIndex || queries.length) //from all possible queries get the ones that are picked
    .map((query) => queryParams.get(query)) //get values for each picked attribute
    .filter((result): result is string => result !== null) //typescript proof

  const breadcrumbs = ['sink'].concat(pickedQueryResults)

  const { data } = useQuery(
    ['fetchRoute', fetchRoute, user?.localeBlanco],
    () => getData(fetchRoute, user?.localeBlanco),
    {
      enabled: !!currentQuery, // Only fetch if currentQuery is truthy
    },
  )
  const handleMaterialSelect = (material: any, id: any) => {
    if (currentQueryIndex === null) return

    if (currentQuery === 'material') setMaterialId(id)
    if (currentQuery === 'color') setColorId(id)

    const baseRoute = `/product-details/sink${fetchRoute}` //current route
    const newRoute =
      currentQueryIndex + 1 < queries.length
        ? baseRoute.replace(
            `query=${currentQuery}`,
            `${currentQuery}=${material}&query=${queries[currentQueryIndex + 1]}`,
          )
        : baseRoute.replace(`query=${currentQuery}`, `${currentQuery}=${material}`)
    navigate(newRoute)
    setHoveredCard(null)
  }

  const getHeadline = (query: string) => {
    if (query === 'material') return t('product-details-page.sink.headline.material')
    if (query === 'color') return t('product-details-page.sink.headline.color')
  }

  //for card hover style:
  const onMouseEnter = (index: number) => {
    setHoveredCard(index)
  }

  const onMouseLeave = () => {
    setHoveredCard(null)
  }

  // optional attributes----------------------------------------------------------------------------------------------------------------------------------------------------------------

  const optionalAttributes = ['installationtype', 'type', 'numberofbowls', 'cabinetsize']
  const [currentAttribute, setCurrentAttribute] = useState(optionalAttributes[0])
  const [groupIcon, setGroupIcon] = useState('')

  const [selectedCardInfo, setSelectedCardInfo] = useState(
    optionalAttributes.reduce((acc: any, attribute) => {
      acc[attribute] = null // Initialize each attribute's selection as null
      return acc
    }, {}),
  )

  const getFilterData = async (attribute: string) => {
    //TO DO: add to helpers
    const combineNonNullElements = (obj: any) =>
      Object.entries(obj)
        .filter(
          ([key, value]: any) =>
            value !== null && typeof value === 'object' && value[key] !== undefined && key !== attribute,
        )
        .map(([key, value]: any) => {
          return `${key}=${value[key]}`
        })
        .join('&')

    const additionalFilters = combineNonNullElements(selectedCardInfo)
      ? `&${combineNonNullElements(selectedCardInfo)}`
      : ''

    const url = new URL(
      `${process.env.REACT_APP_BLANCO_PRODUCT_REGISTRATION_API}/api/attributes/${user?.localeBlanco}/sink${fetchRoute}${additionalFilters}&query=${attribute}`,
    )
    const response = await fetch(url.toString(), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const data = await response.json()
    if (!response.ok) {
      throw new Error(data.message || 'An error occurred')
    }
    // Extract headerImagePath
    !groupIcon && setGroupIcon(data.headerImagePath)

    return data
  }

  const { data: myData, refetch } = useQuery(
    ['filterData', currentAttribute, user?.localeBlanco],
    () => getFilterData(currentAttribute),
    {
      enabled: false, // This disables the query from automatically running
    },
  )

  useEffect(() => {
    // Only call refetch if currentQuery is explicitly false
    if (currentQuery === false) {
      refetch()
    }
  }, [selectedCardInfo, currentAttribute, refetch, currentQuery, user?.localeBlanco]) // Include currentQuery in the dependencies array

  const handleCardClick = (cardInfo: any) => {
    setSelectedCardInfo((prev: any) => ({
      ...prev,
      [currentAttribute]: prev[currentAttribute] === cardInfo ? null : cardInfo,
    }))

    optionalAttributes.forEach((attribute) => {
      attribute !== currentAttribute && queryClient.removeQueries(['filterData', attribute], { exact: true })
    })
  }

  const onClickMain = () => {
    setSelectedCardInfo(
      optionalAttributes.reduce((acc: any, attribute) => {
        acc[attribute] = null // Initialize each attribute's selection as null
        return acc
      }, {}),
    )
  }

  const onBack = () => {
    setSelectedCardInfo(
      optionalAttributes.reduce((acc: any, attribute) => {
        acc[attribute] = null // Initialize each attribute's selection as null
        return acc
      }, {}),
    )
    setCurrentAttribute(optionalAttributes[0])
    if (currentQueryIndex === 0) return navigate('/product-details')
    const base = `/product-details/sink${fetchRoute}`.split(`&query=${currentQuery}`)[0]
    const backRoute =
      base.split(`${queries[(currentQueryIndex || queries.length) - 1]}`)[0] +
      `query=${queries[(currentQueryIndex || queries.length) - 1]}`

    navigate(backRoute)
    optionalAttributes.forEach((attribute) => {
      queryClient.removeQueries(['filterData', attribute], { exact: true })
    })
  }

  const isSelectedCardInfoEmpty = () => !Object.values(selectedCardInfo).some((value) => value !== null)

  // const selectModalID = 'selectModal'

  // const selectModal = useModalContext(selectModalID)
  // const handleModalOpen = () => {
  //   selectModal.open()
  // }

  const handleButton = () => {
    const newObject = {
      id: `Sink ${randomNumberGenerator()}`,
      second_id: `${randomNumberGenerator()}`,
      image: [{ Dokumentenkennung: groupIcon }],
      description: breadcrumbs.join(' / '),
      details: {
        type: 'Sink',
        subType: selectedCardInfo?.type?.type || null,
        sinkDetails: {
          materialId: materialId,
          colorId: colorId,
          installationType: selectedCardInfo?.installationtype?.installationtype || '',
          cabinetSize: selectedCardInfo?.cabinetsize?.cabinetsize || '',
          numberOfBowls: selectedCardInfo?.numberofbowls?.numberofbowls || '',
        },
      },
    }

    // Step 1: Retrieve the 'selectedItems' array from local storage
    const selectedItemsString = localStorage.getItem('selectedItems')
    const selectedItems = selectedItemsString ? JSON.parse(selectedItemsString) : []

    // Step 2: Add the new object to the array
    selectedItems.push(newObject)

    // Step 3: Save the updated array back to local storage
    localStorage.setItem('selectedItems', JSON.stringify(selectedItems))
    navigate('/product-registration')
  }

  return (
    <>
      <StepperHeader
        breadcrumbs={breadcrumbs}
        stepNumber={(currentQueryIndex === null ? queries.length : currentQueryIndex) + 1}
        maxStepNumber={queries.length + 1}
        onBack={() => onBack()}
        exitUrl='/'
        marginBottom={80}
      />
      <SelectModal />
      {!currentQuery && (
        <PageSection sectionMaxWidth={990} titleMarginBottom={80} ml={24} mr={24} isSectionLeft isCentered>
          <ProductDetailsCard
            title={t(`product-details-page.sink.product-group-title`)}
            selectedAttributes={breadcrumbs}
            type='main'
            onClick={() => onClickMain()}
            isChecked={isSelectedCardInfoEmpty()}
            img={groupIcon}
          />
          <FlexComponent mt={80} mb={24} maxWidth={483}>
            <Text text={t(`product-details-page.sink.text`)} variant='bold' />
          </FlexComponent>
          <FlexComponent overflowX='scroll' gap={14} mb={60}>
            {optionalAttributes.map((option, index) => (
              <Chip
                key={index}
                label={t(`product-details-page.sink.optional-attribute.${option}`)}
                isSelected={option === currentAttribute}
                onClick={() => {
                  setCurrentAttribute(option)
                }}
              />
            ))}
          </FlexComponent>

          <GridOptions
            type='optionalAttributes'
            currentQuery={currentAttribute}
            data={myData}
            onClick={handleCardClick}
            selectedCardInfo={selectedCardInfo}
            onReset={onClickMain}
          />
          <FlexComponent flexDirection='row-reverse' mt={60}>
            <Button
              onClick={handleButton}
              type='button'
              label={t(`product-details-page.sink.button-label`)}
              variant='primary'
              width='100%'
              desktopWidth='auto'
            />
          </FlexComponent>
        </PageSection>
      )}

      {/* <ProductDetailsCard
        type='main'
        img='https://cdn.blanco.com/assets/derivateOPK/blanco_410x280/hlr-system/png/416/102416.png'
      /> */}
      {currentQuery && (
        <PageSection
          sectionMaxWidth={990}
          headlineMaxWidth={483}
          headline={getHeadline(currentQuery)}
          titleMarginBottom={80}
          ml={24}
          mr={24}
          isHeadlineLeft
          isSectionLeft
          isCentered
        >
          <GridOptions
            data={data}
            currentQuery={currentQuery}
            onCardClick={handleMaterialSelect}
            hoveredCard={hoveredCard}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
        </PageSection>
      )}
    </>
  )
}

export default SinkMaterialPage
