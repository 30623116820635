import { CSSObject } from '@emotion/react'

const style = ({ theme }: any): CSSObject => {
  const { spacings } = theme

  return {
    display: 'flex',
    flexDirection: 'column',
    '.option': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      '&:not(:first-of-type):not(:last-of-type)': {
        paddingTop: spacings.s16,
        paddingBottom: spacings.s16,
      },
      '&:first-of-type': {
        paddingBottom: spacings.s16,
      },
      '&:last-of-type': {
        paddingTop: spacings.s16,
      },
    },
  }
}

export default style
