import styled from '@emotion/styled'
import { ReactNode } from 'react'

import { containerStyles } from './Modal.style'

const StyledModalContainer = styled.div(() => ({ ...containerStyles() }))

interface Props {
  children: ReactNode
}
const ModalContainer = ({ children }: Props) => {
  return <StyledModalContainer>{children}</StyledModalContainer>
}

export default ModalContainer
