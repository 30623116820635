import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ThemeProvider } from '@emotion/react'
import { I18nextProvider } from 'react-i18next'
import { QueryClient, QueryClientProvider } from 'react-query'
import routes from './app/routes/routes'
import { themes } from './theme'
import GlobalStyles from './global/GlobalStyles'
import i18n from './i18n'
import { Header } from './app/components/organisms/Header'
import { Footer } from './app/components/organisms/Footer'
import { ModalProvider } from './Context/ModalContext'
import { SnackbarProvider } from './Context/SnackBarContext'
import { ProductsListProvider } from './Context/ProductListContext'
import { UserProvider } from './Context/UserContext'
import { basePath } from './helpers/functions/routing'

const { blanco } = themes
const queryClient = new QueryClient()

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <ProductsListProvider>
          <ModalProvider>
            <SnackbarProvider>
              <ThemeProvider theme={blanco}>
                <GlobalStyles />
                <I18nextProvider i18n={i18n}>
                  <BrowserRouter basename={basePath}>
                    <Header />
                    <Routes>
                      {routes.map((route, index) => (
                        <Route key={index} path={route.path} element={route.element} />
                      ))}
                    </Routes>
                    <Footer />
                  </BrowserRouter>
                </I18nextProvider>
              </ThemeProvider>
            </SnackbarProvider>
          </ModalProvider>
        </ProductsListProvider>
      </UserProvider>
    </QueryClientProvider>
  )
}

export default App
