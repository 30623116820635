import styled from '@emotion/styled'
import * as Dialog from '@radix-ui/react-dialog'

import { Text } from '../../atoms/Text/index'
import { ReactComponent as WarningIcon } from '../../../../icons/Warning.svg'
import { FlexComponent } from '../../organisms/Layout/Flex'
import { ReactComponent as CloseIcon } from '../../../../icons/Close.svg'
import { HideOn } from '../../atoms/HideOn'
import { style } from './SnackBar.style'

interface ISnackBar {
  text1: string
  text2?: string
  onOpen?: () => void
  onClose?: () => void
  isOpen?: boolean
  maxWidth?: number
}

const StyledSnackBar = styled(Dialog.Root)<Partial<ISnackBar>>((props) => ({ ...style(props) }))
const StyledWrapper = styled.div<Partial<ISnackBar>>((props) => ({ ...style(props) }))

const SnackBar = ({ text1, text2, onOpen, onClose, isOpen, maxWidth }: ISnackBar) => {
  return (
    <StyledSnackBar open={isOpen} onOpenChange={isOpen ? onClose : onOpen}>
      <Dialog.Portal>
        <StyledWrapper maxWidth={maxWidth}>
          {true && (
            <div onClick={onClose} className='close'>
              {<CloseIcon />}
            </div>
          )}
          <FlexComponent flexDirection='row' gap={16} alignItems='center'>
            <WarningIcon />
            <HideOn mobile>
              <FlexComponent flexDirection='column'>
                <Text text={text1} fontSize={14} />
                {text2 && <Text text={text2} fontSize={14} />}
              </FlexComponent>
            </HideOn>
            <HideOn desktop>
              <FlexComponent flexDirection='column'>
                <Text text={text1} fontSize={12} />
                {text2 && <Text text={text2} fontSize={12} />}
              </FlexComponent>
            </HideOn>
          </FlexComponent>
        </StyledWrapper>
      </Dialog.Portal>
    </StyledSnackBar>
  )
}

export default SnackBar
