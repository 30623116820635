import styled from '@emotion/styled'
import React, { FC } from 'react'

import style, { ITypographyStyle } from './Typography.style'

export interface ITypography extends ITypographyStyle {
  className?: string
  children: React.ReactNode
  component?: React.ElementType
}

const StyledTypography = styled.div<ITypography>((props) => ({ ...style(props) }))

const Typography: FC<ITypography> = ({
  className,
  children,
  component,
  textColor,
  textAlign,
  noWrap,
  lineHeight,
  breakWord,
  numberOfLines,
  opacity,
  fontWeight,
  fontSize,
}) => {
  return (
    <StyledTypography
      as={component}
      className={className}
      textColor={textColor}
      textAlign={textAlign}
      noWrap={noWrap}
      lineHeight={lineHeight}
      breakWord={breakWord}
      numberOfLines={numberOfLines}
      opacity={opacity}
      fontWeight={fontWeight}
      fontSize={fontSize}
    >
      {children}
    </StyledTypography>
  )
}

export default Typography
