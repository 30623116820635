export const colors = {
  primary: {
    elementaryBlue: '#333399',
    elementaryWhite: '#FFFFFF',
  },
  secondary: {
    expandedYellow: '#FADC41',
    expandedGreen: '#82D173',
    expandedGreyBlue: '#7D98A1',
  },
  applicationStates: {
    error: '#D51317',
  },
  greyscale: {
    shipGrey: '#404042',
    silver: '#AFAFAF',
    nobel: '#B6B6B6',
    iron: '#DBDCDE',
    alabaster: '#F8F8F8',
    darkGrey: '#575757',
    grey: '#777777',
    lightGrey: '#F2F2F2',
    white: '#FFFFFF',
  },
}
