import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from 'react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalComponent } from '../../../components/organisms/Modal'
import { FlexComponent } from '../../../components/organisms/Layout/Flex'
import { Text } from '../../../components/atoms/Text'
import { ReactComponent as WarningIcon } from '../../../../icons/Warning.svg'
import { ReactComponent as SuccessLarge } from '../../../../icons/SuccessLarge.svg'
import { useModalContext } from '../../../../hooks'
import Form from '../../../components/molecules/Form/Form'
import { Input } from '../../../components/atoms/Input'
import { Button } from '../../../components/atoms/Button'
import { useUser } from '../../../../Context/UserContext'

interface ILoginUserForm {
  email: string
}

const ProductInfoModal = () => {
  const { t } = useTranslation()
  const [isSubmit, setIsSubmit] = useState(false)
  const { user } = useUser()

  const resetPasswordModalID = 'resetPasswordModal'
  const resetPasswordModal = useModalContext(resetPasswordModalID)
  const handleModalClose = () => {
    resetPasswordModal.close()
    setIsSubmit(false)
  }

  const schema = yup
    .object({
      email: yup.string().email(t('product-registration.reset-password-modal.input.error-message.email')).required(),
    })
    .required()
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ILoginUserForm>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  })

  const changePassword = async (email: string, locale: string = 'en-GB') => {
    const url = new URL(`${process.env.REACT_APP_BLANCO_SHOP_API}/consumer/initiate-reset-password`)

    url.searchParams.append('email', email)
    url.searchParams.append('locale', locale)

    const res = await fetch(url.toString(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (!res.ok) {
      const errorText = await res.text()
      throw new Error(errorText || 'An error occurred during the request')
    }
    return { message: 'Password reset initiated successfully.' }
  }

  const resetPasswordMutation = useMutation({
    mutationFn: (email: string) => changePassword(email, user?.localeDiva), //TO DO: add locale info (string) here
    onError: (error) => {
      console.error('An error has occurred: ', error)
    },
    onSuccess: () => {},
  })

  const onSubmit = (data: ILoginUserForm) => {
    data && setIsSubmit(true)
    // console.log('Submitted login data', data)
    resetPasswordMutation.mutate(data.email)
  }

  const onConfirm = () => {
    resetPasswordModal.close()
    setIsSubmit(false)
  }
  return (
    <ModalComponent
      modalTitle={t('product-registration.reset-password-modal.title')}
      isOpen={resetPasswordModal.isOpen}
      onClose={handleModalClose}
      height='auto'
      maxWidth='620px'
      mobileHeight='auto'
    >
      {!isSubmit && (
        <Form
          description={t('product-registration.reset-password-modal.description')}
          onSubmit={handleSubmit(onSubmit)}
        >
          <FlexComponent flexDirection='column' gap={40}>
            <Controller
              name='email'
              control={control}
              render={({ field }) => (
                <Input
                  hasError={!!errors.email}
                  backIcon={!!errors.email && <WarningIcon />}
                  errorMessage={t('product-registration.reset-password-modal.input.error-message.email')} //TODO: add translation
                  isRequired={true}
                  label={t('product-registration.reset-password-modal.input.label.email')}
                  placeholder={t('product-registration.reset-password-modal.input.placeholder.email')}
                  {...field}
                />
              )}
            />
            <FlexComponent justifyContent={'center'}>
              <Button
                isDisabled={!isValid}
                type='submit'
                label={t('product-registration.reset-password-modal.button.submit')}
                variant='primary'
                width='100%'
                desktopWidth='auto'
              />
            </FlexComponent>
          </FlexComponent>
        </Form>
      )}

      {isSubmit && (
        <FlexComponent flexDirection='column' gap={40}>
          <FlexComponent flexDirection='column' gap={24} alignItems={'center'}>
            <SuccessLarge />
            <Text variant='primary' text={t('product-registration.reset-password-modal.success-message')} />
          </FlexComponent>
          <FlexComponent justifyContent={'center'}>
            <Button
              isDisabled={!isValid}
              type='button'
              label={t('product-registration.reset-password-modal.button.close')}
              variant='primary'
              width='100%'
              desktopWidth='auto'
              onClick={onConfirm}
            />
          </FlexComponent>
        </FlexComponent>
      )}
    </ModalComponent>
  )
}

export default ProductInfoModal
