import styled from '@emotion/styled'

import { style } from './Divider.style'

interface IDivider {
  hasMargin?: boolean
}

const StyledDivider = styled.div<IDivider>((props) => ({ ...style(props) }))

const Divider = ({ hasMargin = true }: IDivider) => <StyledDivider hasMargin={hasMargin} />

export default Divider
