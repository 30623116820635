import { CSSObject } from '@emotion/react'

export const style = ({ theme, isCardOnMobile }: any): CSSObject => {
  const { color, spacings, breakpoint, typography } = theme
  return {
    backgroundColor: isCardOnMobile ? color.greyscale.alabaster : color.greyscale.white,
    padding: isCardOnMobile ? spacings.s24 : 0,
    width: '100%',
    [breakpoint.up('md')]: {
      padding: spacings.s24,
      backgroundColor: color.greyscale.alabaster,
    },
    '& .newsletter': {
      '&-title': {
        color: color.greyscale.shipGrey,
        fontSize: typography.fontSize.copyBold,
        lineHeight: typography.lineHeight.copyBold,
        fontFamily: typography.fontFamily.helveticaBold,
        fontWeight: 700,
      },
      '&-description': {
        color: color.greyscale.shipGrey,
        fontSize: typography.fontSize.copyBold,
        lineHeight: typography.lineHeight.copyBold,
        fontFamily: typography.fontFamily.helveticaLight,
        fontWeight: 300,
        marginTop: spacings.s8,
        [theme.breakpoint.up('md')]: {
          marginTop: spacings.s16,
        },
      },
      '&-incentive': {
        backgroundColor: isCardOnMobile ? color.greyscale.white : color.greyscale.alabaster,
        display: 'flex',
        alignItems: 'center',
        gap: spacings.s24,
        color: color.greyscale.shipGrey,
        fontSize: typography.fontSizeMobile.interactionsBoldSmallWide,
        lineHeight: typography.lineHeightMobile.interactionsBoldSmallWide,
        fontFamily: typography.fontFamily.helveticaBold,
        fontWeight: 700,
        padding: spacings.s16,
        '& svg': {
          minWidth: 40,
        },
        [breakpoint.up('md')]: {
          padding: spacings.s24,
          fontSize: typography.fontSize.copyBold,
          lineHeight: typography.lineHeight.copyBold,
          backgroundColor: color.primary.elementaryWhite,
        },
      },
    },
  }
}
