import { CSSObject } from '@emotion/react'
import { toRem } from '../../../../helpers/functions'

const style = ({ theme }: any): CSSObject => {
  const { color, spacings, breakpoint, typography } = theme
  return {
    backgroundColor: color.greyscale.lightGrey,
    padding: `${spacings.s12} ${spacings.s24}`,
    marginTop: spacings.s64,

    [breakpoint.up('md')]: {
      padding: `${spacings.s24} ${spacings.s64}`,
      marginTop: `calc(${toRem(108)})`,
    },

    '& .footer-container': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'column-reverse',
      gap: toRem(4),
      [breakpoint.up('md')]: {
        flexDirection: 'row',
      },

      '&__copyright': {
        display: 'flex',
        textTransform: 'uppercase',
        fontSize: typography.fontSize.interactionsHeavy,
        fontWeight: 400,
        lineHeight: toRem(24),
        color: color.greyscale.shipGrey,
      },

      '&__links': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',

        width: '100%',

        fontSize: toRem(12),
        fontWeight: 700,
        lineHeight: toRem(28),
        letterSpacing: toRem(2),
        textTransform: 'uppercase',
        fontFamily: typography.fontFamily.helveticaHeavy,

        '& a': {
          textDecoration: 'none',
          color: color.greyscale.shipGrey,
        },

        [breakpoint.up('md')]: {
          justifyContent: 'flex-end',
          flexDirection: 'row',
          gap: toRem(32),
          width: 'auto',
        },
      },
    },
  }
}

export default style
