import styled from '@emotion/styled'
import { RadioButton } from '../../atoms/RadioButton'
import { FlexComponent } from '../../organisms/Layout/Flex'
import { ReactComponent as EmptyImage } from '../../../../icons/emptySquare.svg'
import { style } from './ProductDetailsCard.style'

interface IProductDetailsCard {
  type?: 'main' | 'single'
  img?: string
  title?: string
  selectedAttributes?: string[]
  isChecked?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onClick?: () => void
  isEmptyState?: boolean
}

const StyledProductDetailsCard = styled.div<Partial<IProductDetailsCard>>((props) => ({
  ...style(props),
}))

const ProductDetailsCard = ({
  img,
  title,
  selectedAttributes,
  isChecked = false,
  onClick,
  type = 'single',
  isEmptyState = false,
}: IProductDetailsCard) => {
  const handleClick = () => {
    if (onClick) onClick() // Call onClick action
  }
  return (
    <StyledProductDetailsCard type={type} onClick={onClick} isEmptyState={isEmptyState}>
      {!isEmptyState ? (
        <FlexComponent
          className='subsection'
          width='100%'
          gap={[20, 20, 24, 24]}
          pt={type === 'main' ? [48, 48, 40, 40] : [24, 24, 40, 40]}
          pr={[24, 24, 40, 40]}
          pb={[24, 24, 40, 40]}
          pl={[60, 60, 80, 80]}
          alignItems='center'
        >
          <div className='card-image'>{img ? <img src={img} /> : <EmptyImage />}</div>
          <FlexComponent flexDirection='column' width='100%'>
            <div className='card-title'>{title}</div>
            {selectedAttributes && <div className='card-breadcrumbs'>{selectedAttributes.join(' / ')}</div>}
          </FlexComponent>
          {onClick && (
            <div className='card-radiobutton'>
              <RadioButton isChecked={isChecked} onChange={handleClick} onClick={() => onClick()} value={title || ''} />
            </div>
          )}
        </FlexComponent>
      ) : (
        <FlexComponent
          className='subsection'
          width='100%'
          gap={[20, 20, 24, 24]}
          pt={type === 'main' ? [48, 48, 40, 40] : [24, 24, 40, 40]}
          pr={[24, 24, 40, 40]}
          pb={[24, 24, 40, 40]}
          pl={[60, 60, 80, 80]}
          alignItems='center'
        >
          <div className='card-image'></div>
        </FlexComponent>
      )}
    </StyledProductDetailsCard>
  )
}

export default ProductDetailsCard
