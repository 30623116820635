import styled from '@emotion/styled'
import { FlexComponent } from '../../organisms/Layout/Flex'
import { style } from './Newsletter.style'

interface INewsletterIncentiveItem {
  icon: React.ReactNode
  text: string
}

interface INewsletterIncentive {
  newsletterIncentives: INewsletterIncentiveItem[]
  description: string
  title: string
  isCardOnMobile?: boolean
}

const StyledNewsletter = styled.div<Partial<INewsletterIncentive>>((props) => ({ ...style(props) }))

const Newsletter = ({ title, description, newsletterIncentives, isCardOnMobile = false }: INewsletterIncentive) => {
  return (
    <StyledNewsletter isCardOnMobile={isCardOnMobile}>
      <div className='newsletter-title'>{title}</div>
      <div className='newsletter-description'>{description}</div>
      <FlexComponent mt={[40, 40, 40, 40]} flexDirection='column' gap={[10, 10, 24, 24]}>
        {newsletterIncentives.map((incentive, index) => (
          <div key={index} className='newsletter-incentive'>
            {incentive.icon}
            {incentive.text}
          </div>
        ))}
      </FlexComponent>
    </StyledNewsletter>
  )
}

export default Newsletter
