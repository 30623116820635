import { useTranslation } from 'react-i18next'
import { useSnackbarContext } from '../../../../hooks'
import { SnackBar } from '../../../components/molecules/SnackBar'

const OptionalAttributesSnackbar = () => {
  const { t } = useTranslation()
  const optionalAttributesSnackbarID = 'optionalAttributesSnackbar'
  const optionalAttributesSnackbar = useSnackbarContext(optionalAttributesSnackbarID)
  const handleSnackbarClose = () => {
    optionalAttributesSnackbar.close()
  }

  return (
    <SnackBar
      maxWidth={390}
      onClose={handleSnackbarClose}
      isOpen={optionalAttributesSnackbar.isOpen}
      text1={t(`product-details-page.snackbar-text`)}
    />
  )
}

export default OptionalAttributesSnackbar
