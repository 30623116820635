import { CSSObject } from '@emotion/react'
import { toRem } from '../../../../helpers/functions'

export const style = ({ theme, stepNumber, maxStepNumber, marginBottom }: any): CSSObject => {
  const { color, breakpoint, spacings } = theme

  const progressFillWidth = stepNumber === maxStepNumber ? '100%' : `${stepNumber * ((1 / maxStepNumber) * 100)}%`

  return {
    svg: {
      flexShrink: 0,
      cursor: 'pointer',
    },
    'svg path': {
      fill: color.primary.elementaryBlue,
    },
    ...(marginBottom && {
      marginBottom: toRem(marginBottom / 2),
    }),
    [breakpoint.up('md')]: {
      ...(marginBottom && {
        marginBottom: toRem(marginBottom),
      }),
    },
    '.progress': {
      '&-wrapper': {
        height: spacings.s4,
      },
      '&-fill': {
        width: progressFillWidth,
        backgroundColor: color.primary.elementaryBlue,
        height: '100%',
      },
    },
  }
}
