import { CSSObject } from '@emotion/react'
import { toRem } from '../../../../helpers/functions'

export const style = (): CSSObject => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: toRem(16),
  }
}

export const styleFormWrapper = ({ theme, mt }: any): CSSObject => {
  const { typography, color, breakpoint } = theme
  return {
    display: 'flex',
    flexDirection: 'column',
    marginTop: mt ? toRem(mt) : toRem(40),
    [breakpoint.up('md')]: {
      marginTop: mt ? toRem(mt) : toRem(60),
    },
    '& .form': {
      '&-title': {
        fontSize: typography.fontSize.copyBold,
        fontWeight: typography.fontWeight.bold,
        lineHeight: typography.lineHeight.copyBold,
        color: color.greyscale.shipGrey,
        fontFamily: typography.fontFamily.helveticaBold,
      },
      '&-description': {
        fontSize: toRem(16),
        fontWeight: typography.lineHeightMobile.light,
        lineHeight: toRem(26),
        color: color.greyscale.shipGrey,
        fontFamily: typography.fontFamily.helveticaLight,
        [breakpoint.up('md')]: {
          fontSize: typography.fontSize.copyBold,
          lineHeight: typography.lineHeight.copyBold,
        },
      },
      '&-product_registration_cta': {
        height: toRem(60),
      },
    },
  }
}
