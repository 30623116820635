import { useTranslation } from 'react-i18next'
import { useSnackbarContext } from '../../../../hooks'
import { SnackBar } from '../../../components/molecules/SnackBar'

const ResendOtpSnackbar = () => {
  const { t } = useTranslation()

  const resendOtpSnackbarID = 'resendOtpSnackbar'
  const resendOtpSnackbar = useSnackbarContext(resendOtpSnackbarID)
  const handleSnackbarClose = () => {
    resendOtpSnackbar.close()
  }

  return (
    <SnackBar
      onClose={handleSnackbarClose}
      isOpen={resendOtpSnackbar.isOpen}
      text1={t('product-registration.confirm-registration-modal.snackbar-success1')}
      text2={t('product-registration.confirm-registration-modal.snackbar-success2')}
    />
  )
}

export default ResendOtpSnackbar
