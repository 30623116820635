import styled from '@emotion/styled'
import style from './Loader.style'

const StyledLoader = styled.div((props) => ({ ...style(props) }))

const Loader = () => (
  <StyledLoader>
    <span />
    <span />
    <span />
  </StyledLoader>
)

export default Loader
