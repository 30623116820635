import { CSSObject } from '@emotion/react'
import { toRem, toRems } from '../../../../helpers/functions'

export const style = ({ theme }: any): CSSObject => {
  const { breakpoint, spacings } = theme
  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    width: '100%',
    minHeight: toRem(24),

    padding: toRems([24, 24, 0, 24]),
    marginBottom: spacings.s40,

    [breakpoint.up('md')]: {
      minHeight: toRem(96),

      padding: toRems([0, 60, 0, 60]),
      marginBottom: spacings.s48,
    },
  }
}
