import { CSSObject } from '@emotion/react'

const style = ({ theme }: any): CSSObject => {
  const { breakpoint, spacings } = theme

  return {
    '.grid-wrapper': {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gap: spacings.s12,
      [breakpoint.up('md')]: {
        gridTemplateColumns: '1fr 1fr',
        gap: spacings.s24,
      },
    },
  }
}

export default style
