import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FlexComponent } from '../Layout/Flex'
import { Text } from '../../atoms/Text'
import { Checkbox } from '../../atoms/Checkbox'
import { Incentive } from '../../molecules/Incentive'
import { ReactComponent as DocumentIcon } from '../../../../icons/Document.svg'
import { ReactComponent as OfferIcon } from '../../../../icons/Offer.svg'
import { Typography } from '../../atoms/Typography'
import { toRem } from '../../../../helpers/functions'
import { getCountry } from '../../../../helpers/functions/getLanguageByCountry'

const EmailSection = ({ onNewsletterConsentChange }: any) => {
  const { t } = useTranslation()
  const [isNewsletterConsentChecked, setIsNewsletterConsentChecked] = useState(false)

  const country = getCountry()

  const toggleNewsletterConsentCheckbox = () => {
    const newValue = !isNewsletterConsentChecked
    localStorage.setItem('newsletterConsent', `${newValue}`)
    setIsNewsletterConsentChecked(newValue)
    onNewsletterConsentChange(newValue)
  }

  const newsletterIncentives = [
    {
      icon: <OfferIcon opacity={isNewsletterConsentChecked ? '1' : '0.5'} />,
      text: t('home.newsletter.first'),
    },
    {
      icon: <DocumentIcon opacity={isNewsletterConsentChecked ? '1' : '0.5'} />,
      text: t('home.newsletter.second'),
    },
  ]

  return (
    <FlexComponent
      justifyContent='flex-start'
      alignItems='flex-start'
      width='100%'
      flexDirection='column'
      gap={40}
      backgroundColor='#F8F8F8'
      p={24}
      mt={[24, 24, 24, 60]}
    >
      <FlexComponent flexDirection={'column'} gap={[10, 10, 10, 10]}>
        <FlexComponent gap={16} alignItems='center'>
          <Typography fontSize={toRem(18)} fontWeight={700} textColor='#339'>
            {t('product-registration.section.step.second.registration-section.checkbox.newsletter-top')}
          </Typography>
        </FlexComponent>
        <Text text={t('product-registration.section.step.second.registration-section.checkbox.newsletter-subtext')} />
      </FlexComponent>
      <FlexComponent flexDirection='column' gap={24} width={'100%'}>
        <Incentive
          textColor='#404042'
          backgroundColor='#FFF'
          icon={newsletterIncentives[0].icon}
          text={newsletterIncentives[0].text}
        />
        <Incentive
          textColor='#404042'
          backgroundColor='#FFF'
          icon={newsletterIncentives[1].icon}
          text={newsletterIncentives[1].text}
        />
      </FlexComponent>
      <FlexComponent gap={16}>
        <Checkbox
          isError={false}
          isChecked={isNewsletterConsentChecked}
          onChange={() => toggleNewsletterConsentCheckbox()}
        />
        <Text
          isHtml
          variant='newsletter'
          text={t(
            `product-registration.section.step.second.registration-section.checkbox.newsletter-checkbox.${country}`,
          )}
        />
      </FlexComponent>
    </FlexComponent>
  )
}

export default EmailSection
