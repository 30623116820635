import { CSSObject } from '@emotion/react'

export const style = ({ theme, isLowerOpacity, isColor, color }: any): CSSObject => {
  const { color: themeColor, spacings } = theme
  return {
    padding: spacings.s16,
    backgroundColor: themeColor.greyscale.lightGrey,
    width: '100%',
    borderRadius: spacings.s4,
    opacity: isLowerOpacity ? '0.4' : 1,
    '.image-container': {
      width: '108px',
      height: '108px',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,
      backgroundColor: isColor ? color : 'transparent', // Apply background color if isColor is true
      img: {
        width: 'auto',
        height: '100%',
        minHeight: '100%',
        objectFit: 'contain',
      },
    },
  }
}
