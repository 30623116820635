import { CSSObject } from '@emotion/react'

export const style = ({ theme, isError }: any): CSSObject => {
  const { color, spacings } = theme

  return {
    input: {
      position: 'absolute',
      opacity: 0,
      display: 'none',
    },
    '.checkbox-custom': {
      cursor: 'pointer',
      alignItems: 'center',
      display: 'flex',
      width: `${spacings.s24}`,
      height: `${spacings.s24}`,
      backgroundColor: 'transparent',
      position: 'relative',

      ...(isError && {
        'svg path': {
          fill: color.applicationStates.error,
        },
      }),
    },
  }
}

export default style
