import { CSSObject } from '@emotion/react'

export interface ITypographyStyle {
  textColor?: string
  textAlign?: 'left' | 'center' | 'right' | 'justify'
  noWrap?: boolean
  lineHeight?: number
  breakWord?: boolean
  numberOfLines?: number
  opacity?: number
  fontWeight?: 400 | 500 | 700 | 800 | 900
  fontSize?: string | number
}

export const style = ({
  fontSize,
  textColor,
  noWrap,
  lineHeight,
  breakWord,
  numberOfLines,
  opacity,
  fontWeight,
  textAlign,
}: ITypographyStyle): CSSObject => {
  return {
    color: textColor,
    textAlign: textAlign,

    ...(fontSize && {
      fontSize: fontSize,
    }),

    ...(lineHeight && {
      lineHeight: lineHeight,
    }),

    ...(fontWeight && {
      fontWeight: fontWeight,
    }),

    ...(noWrap && {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),

    ...(breakWord && {
      wordBreak: 'break-word',
    }),

    ...(numberOfLines && {
      WebkitLineClamp: numberOfLines.toString(),
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      boxOrient: 'vertical',
      WebkitBoxOrient: 'vertical',
      overflowWrap: 'anywhere',
    }),

    ...(opacity && {
      opacity: opacity,
    }),
  }
}

export default style
