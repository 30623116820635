import { useTranslation } from 'react-i18next'
import { Incentive } from '../../../components/molecules/Incentive'
import { FlexComponent } from '../../../components/organisms/Layout/Flex'
import { ReactComponent as AccessIcon } from '../../../../icons/Access.svg'
import { ReactComponent as GiftIcon } from '../../../../icons/Gift.svg'
import { makePublicPathUrl } from '../../../../helpers/functions/routing'

const Hero = () => {
  const { t } = useTranslation()
  return (
    <FlexComponent mt={[24, 24, 24, 60]} flexDirection='column' alignItems='center' width='100%'>
      <FlexComponent>
        <img src={makePublicPathUrl('/images/product-registration-hero.jpg')} alt='blanco-contained-hero' />
      </FlexComponent>
      <FlexComponent
        maxWidth={1038}
        width='100%'
        alignItems='center'
        gap={[10, 10, 10, 24]}
        mt={[-30]}
        pl={[24, 24, 24, 24]}
        pr={[24, 24, 24, 24]}
        flexDirection={['column', 'column', 'row', 'row']}
      >
        <Incentive icon={<GiftIcon />} text={t('home.incentive.gift')} />
        <Incentive icon={<AccessIcon />} text={t('home.incentive.access')} />
      </FlexComponent>
    </FlexComponent>
  )
}

export default Hero
