import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PageSection from '../components/organisms/PageSection/PageSection'
import { Hero } from '../Templates/Home/Hero'
import { Content } from '../Templates/Home/Content'
import { HideOn } from '../components/atoms/HideOn'
import { FlexComponent } from '../components/organisms/Layout/Flex'
import { Button } from '../components/atoms/Button'
import { useModalContext } from '../../hooks'

const Home = () => {
  const { t } = useTranslation()

  const countryModalID = 'countryModal'

  const countryModal = useModalContext(countryModalID)
  // const handleModalOpen = () => {
  //   countryModal.open()
  // }

  useEffect(() => {
    countryModal.open()
  }, [])

  return (
    <>
      <PageSection headlineMaxWidth={650} headline={t('home.headline')} />
      <PageSection sectionMaxWidth={1160}>
        <Hero />
      </PageSection>
      <PageSection sectionMaxWidth={1160}>
        <Content />
        <HideOn desktop>
          <FlexComponent width='100%' alignContent='flex-end' mt={40}>
            <Button url='/product-registration' width='100%' variant='primary' label={t('home.button.label')} />
          </FlexComponent>
        </HideOn>
      </PageSection>
    </>
  )
}

export default Home
