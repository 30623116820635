import styled from '@emotion/styled'
import * as Accordion from '@radix-ui/react-accordion'
import { ReactComponent as ChevronDown } from '../../../../icons/ChevronDown.svg'
import { FlexComponent } from '../../organisms/Layout/Flex'
import { accordionItem } from './Accordion.style'

const StyledAccordionItem = styled(Accordion.Item)<any>((props) => ({ ...accordionItem(props) }))

interface IAccordionItem {
  headerTitle: string
  content?: React.ReactNode
  content2?: React.ReactNode
  value?: string
  subTitle?: string
  subTitle2?: string
  url?: string
  url2?: string
  alt?: string
  alt2?: string
  flexDirection?: any
  children?: React.ReactNode
  isContent?: boolean
}
const AccordionItem = ({
  headerTitle,
  flexDirection = 'column',
  content,
  content2,
  value,
  subTitle,
  subTitle2,
  url,
  url2,
  alt,
  alt2,
  children,
  isContent,
}: IAccordionItem) => (
  <StyledAccordionItem value={value}>
    <Accordion.Trigger className='accordionItem-trigger'>
      <Accordion.Header className='accordionItem-headerTitle'>
        <span>{headerTitle}</span>
        <ChevronDown className='accordionItem-chevron' />
      </Accordion.Header>
    </Accordion.Trigger>
    <Accordion.Content className='accordionItem-contentWrapper'>
      {!isContent && (
        <FlexComponent flexDirection={['column', 'column', flexDirection]} gap={40}>
          <FlexComponent className='accordionItem-content' flexDirection='column'>
            {subTitle && <div className='accordionItem-content_subtitle'>{subTitle}</div>}
            <div className='accordionItem-content_description'>{content}</div>
            <img src={url} alt={alt} />
          </FlexComponent>
          {subTitle2 && (
            <FlexComponent className='accordionItem-content'>
              <div className='accordionItem-content_subtitle'>{subTitle2}</div>
              <div className='accordionItem-content_description'>{content2}</div>
              <img src={url2} alt={alt2} />
            </FlexComponent>
          )}
        </FlexComponent>
      )}
      {isContent && children}
    </Accordion.Content>
  </StyledAccordionItem>
)

export default AccordionItem
