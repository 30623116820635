import { useContext, useMemo } from 'react'
import { ModalContext } from '../Context/ModalContext/ModalProvider'

export const useModalContext = (modalUid: string) => {
  const { activeModals, openModal, closeModal } = useContext(ModalContext)

  const isOpen = useMemo(() => {
    return activeModals?.has(modalUid)
  }, [activeModals, modalUid])

  const handleOpen = () => {
    openModal(modalUid)
  }

  const handleClose = () => {
    closeModal(modalUid)
  }

  return { isOpen, open: handleOpen, close: handleClose }
}

export default useModalContext
