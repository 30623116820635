import { CSSObject } from '@emotion/react'
import { toRem } from '../../../../helpers/functions'

export const style = (): CSSObject => {
  return {
    width: '100%',
  }
}

export const overlayStyles = (): CSSObject => {
  return {
    width: '100%',
    height: '100vh',
    backgroundColor: 'rgba(13, 19, 47, 0.3)',
    top: 0,
    left: 0,
    zIndex: 9999,
  }
}

export const containerStyles = (): CSSObject => {
  return {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    zIndex: 9999,
    overflow: 'auto',
  }
}

export const contentStyles = ({ theme, maxWidth, height, mobileHeight }: any): CSSObject => {
  const { breakpoint, spacings } = theme
  return {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'calc(100% - 32px)',
    height: mobileHeight ? mobileHeight : 'calc(100vh - 32px)',
    backgroundColor: 'white',
    maxWidth: maxWidth ? maxWidth : toRem(822),
    paddingBottom: spacings.s56, // 24 + 32 = 56, 24 is regular padding, 32 is 100vh -32px
    paddingLeft: spacings.s24,
    paddingRight: spacings.s24,
    overflowY: 'auto',
    overflowX: 'hidden',
    [breakpoint.up('md')]: {
      width: '100%',
      height: height ? height : toRem(640),
    },
  }
}

export const headerStyles = ({ theme }: any): CSSObject => {
  const { color, typography, breakpoint, spacings } = theme
  return {
    position: 'sticky',
    top: 0,
    left: 0,
    width: '100%',
    height: 'auto',
    color: color.primary.elementaryBlue,
    fontSize: typography.fontSizeMobile.copyBold,
    lineHeight: typography.lineHeightMobile.interactionsBoldSmallWide,
    fontWeight: typography.fontWeight.black,
    fontFamily: typography.fontFamily.helveticaBlack,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: spacings.s24,
    background: color.greyscale.white,
    textTransform: 'uppercase',
    marginBottom: spacings.s40,
    paddingTop: spacings.s24,
    paddingBottom: spacings.s24,
    zIndex: 99,
    [breakpoint.up('md')]: {
      paddingTop: spacings.s24,
      marginBottom: spacings.s24,
      fontSize: typography.fontSize.copyBold,
      lineHeight: typography.lineHeight.descriptionsRoman,
    },
  }
}
