import { CSSObject } from 'theme-ui'
import { toRem } from '../../../../helpers/functions'

const style = ({ theme, width, desktop, mobile }: any): CSSObject => {
  const { breakpoint } = theme
  return {
    width: width ? toRem(width) : '100%',
    ...(desktop && {
      [breakpoint.up('md')]: {
        display: 'none',
      },
    }),
    ...(mobile && {
      display: 'none',
      [breakpoint.up('md')]: {
        display: 'inline-block',
      },
    }),
  }
}

export default style
