import { CSSObject } from '@emotion/react'
import { toRem } from '../../../../helpers/functions'

export const style = ({ theme, maxWidth }: any): CSSObject => {
  const { color, spacings, shadows } = theme
  return {
    padding: `${spacings.s20} ${spacings.s40} ${spacings.s20} ${spacings.s12}`,
    background: color.primary.elementaryWhite,
    boxShadow: shadows.secondary,
    position: 'fixed',
    maxWidth: toRem(maxWidth),
    [`@media (max-width: 412px)`]: {
      bottom: spacings.s8,
      left: '5%',
      right: '5%',
    },
    [`@media (min-width: 413px)`]: {
      bottom: spacings.s20,
      right: spacings.s20,
    },
    zIndex: 9999,
    overflow: 'auto',

    '.close': {
      position: 'absolute',
      top: '6px',
      right: '6px',
    },
    'svg path': {
      fill: color.primary.elementaryBlue,
    },
  }
}

export const containerStyles = (): CSSObject => {
  return {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    zIndex: 9999,
    overflow: 'auto',
  }
}
