import React from 'react'
import styled from '@emotion/styled'
import { style } from './Chip.style'

const StyledButton = styled.button<Partial<IChip>>((props) => ({ ...style(props) }))

interface IChip {
  width?: string
  height?: string
  label: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  url?: string
  isSelected?: boolean
  isDisabled?: boolean
  className?: string
}

const Chip = ({ isDisabled, onClick, width, height, label, isSelected, className }: IChip) => {
  return (
    <StyledButton
      className={className}
      isDisabled={isDisabled}
      onClick={onClick}
      width={width}
      height={height}
      isSelected={isSelected}
    >
      {label}
    </StyledButton>
  )
}

export default Chip
