import { CSSObject } from '@emotion/react'
import { toRem } from '../../../../helpers/functions'

const style = ({ theme, backgroundColor, textColor }: any): CSSObject => {
  const { color, spacings, breakpoint, typography } = theme
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: toRem(16),
    backgroundColor: color.primary.elementaryBlue,
    ...(backgroundColor && { backgroundColor: `${backgroundColor} !important` }),
    padding: `${spacings.s16}`,
    width: '100%',
    height: toRem(80),
    [breakpoint.up('md')]: {
      justifyContent: 'space-between',
      gap: toRem(24),
      padding: `${spacings.s24}`,
      height: toRem(160),
    },
    [breakpoint.up('lg')]: {
      height: toRem(120),
    },
    '& .incentive': {
      '&-text': {
        color: color.primary.elementaryWhite,
        ...(textColor && { color: textColor }),
        fontSize: typography.fontSizeMobile.interactionsBoldSmall,
        lineHeight: typography.lineHeightMobile.interactionsBoldSmall,
        fontWeight: typography.fontWeight.bold,
        fontFamily: typography.fontFamily.helveticaBold,
        [breakpoint.up('md')]: {
          fontSize: typography.fontSize.copyBold,
          lineHeight: typography.lineHeight.copyBold,
        },
      },
    },
  }
}

export default style
