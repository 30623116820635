import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import styled from '@emotion/styled'
import { useState } from 'react'
import { Typography } from '../../atoms/Typography'
import { ReactComponent as CheckIcon } from '../../../../icons/check.svg'
import { dropdownPortal } from './Dropdown.style'

interface IDropdownPortal {
  items: Array<{ langCode: string; langDisplay: string }>
  onSelect: (langCode: string) => void
  alignOffset: number
  align: 'start' | 'end'
  side: 'bottom' | 'top' | 'left' | 'right'
  sideOffset: number
  defaultActiveItem: string
}

const StyledDropdownPortal = styled.div((props) => ({ ...dropdownPortal(props) }))

const DropdownPortal = ({
  items,
  onSelect,
  alignOffset,
  defaultActiveItem,
  align,
  side,
  sideOffset,
}: IDropdownPortal) => {
  const [activeItem, setActiveItem] = useState<string | null>(defaultActiveItem)
  const handleSelect = (item: { langCode: string; langDisplay: string }) => {
    setActiveItem(item.langCode)
    onSelect(item.langCode)
  }
  return (
    <StyledDropdownPortal>
      <DropdownMenu.Content
        sideOffset={sideOffset}
        side={side}
        align={align}
        alignOffset={alignOffset}
        avoidCollisions
        className='dropdown-content'
      >
        <DropdownMenu.Item>
          {items.map((item, index) => (
            <DropdownMenu.Item onSelect={() => handleSelect(item)} key={item.langCode}>
              <div className='dropdown-itemWrapper'>
                <Typography
                  className={`dropdown-item ${item.langCode === activeItem ? 'active' : ''}`}
                  component='span'
                >
                  {item.langDisplay}
                </Typography>
                {item.langCode === activeItem && <CheckIcon />}
              </div>
              {index !== items.length - 1 && <div className='dropdown-divider' />}
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </StyledDropdownPortal>
  )
}

export default DropdownPortal
