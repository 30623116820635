import styled from '@emotion/styled'
import { FlexComponent } from '../../organisms/Layout/Flex'
import { makePublicPathUrl } from '../../../../helpers/functions/routing'
import style from './DropdownItem.style'

const StyledDropdownItem = styled.div((props) => ({ ...style(props) }))

interface IDropdownItem {
  src: string | undefined
  alt: string | undefined
  productNumber: string
  productName: string
  onClick?: () => void
}
const DropdownItem = ({ onClick, src, alt, productNumber, productName }: IDropdownItem) => (
  <StyledDropdownItem onClick={onClick}>
    <FlexComponent className='dropdownItem-image' justifyContent='center'>
      <img src={src ? src : makePublicPathUrl('/images/product-placeholder.png')} alt={alt} />
    </FlexComponent>
    <FlexComponent className='dropdownItem-wrapper' flexDirection='column'>
      <span className='dropdownItem-productNumber'>{productNumber}</span>
      <span className='dropdownItem-productName'>{productName}</span>
    </FlexComponent>
  </StyledDropdownItem>
)

export default DropdownItem
