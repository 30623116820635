import styled from '@emotion/styled'
import { style } from './List.style'

interface IListItem {
  icon: React.ReactNode
  text: string
}
interface IList {
  list: IListItem[]
  title?: string
  isRow?: boolean
}

const StyledList = styled.div<Partial<IList>>((props) => ({ ...style(props) }))

const List = ({ list, title, isRow = false }: IList) => (
  <StyledList isRow={isRow}>
    <h2 className='list-title'>{title}</h2>
    <ul className='list'>
      {list.map((item, index) => (
        <li className='list-item' key={index}>
          {item.icon} {item.text}
        </li>
      ))}
    </ul>
  </StyledList>
)

export default List
