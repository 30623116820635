import { CSSObject } from '@emotion/react'
import { toRem, toRems } from '../../../../helpers/functions'

export const style = ({ theme }: any): CSSObject => {
  const { breakpoint, typography, color } = theme
  return {
    backgroundColor: color.greyscale.lightGrey,
    padding: toRems([24, 20]),
    [breakpoint.up('md')]: {
      padding: toRems([40, 40]),
    },

    '& .product-card': {
      '&_image': {
        display: 'flex', // Ensures flexbox properties are applied
        alignItems: 'center', // Centers the image vertically
        justifyContent: 'center', // Centers the image horizontally
        width: '100%',
        height: toRem(200),
        flexShrink: 0,
        [breakpoint.up('md')]: {
          width: toRem(195),
          height: toRem(100),
          flexShrink: 0,
        },
        '& img': {
          maxWidth: '100%',
          maxHeight: '100%',
          objectFit: 'contain',
        },
        '& svg': {
          maxWidth: '100%',
          maxHeight: '100%',
          objectFit: 'contain',
        },
      },
      '&_header': {
        '&_serialNumber': {
          fontSize: typography.fontSizeMobile.copyBold,
          fontWeight: typography.fontWeight.bold,
          fontFamily: typography.fontFamily.helveticaBold,
          lineHeight: typography.lineHeightMobile.copyBold,
          color: color.primary.elementaryBlue,
          width: '100%',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        },
      },
      '&_productFamily': {
        fontSize: toRem(16),
        fontWeight: typography.fontWeight.light,
        fontFamily: typography.fontFamily.helveticaLight,
        lineHeight: typography.lineHeightMobile.interactionsBoldSmallWide,
        color: color.primary.elementaryBlue,
        width: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
      '&_datepicker-wrapper': {
        [breakpoint.up('lg')]: {
          minWidth: toRem(250),
        },
        [breakpoint.up('lg')]: {
          minWidth: toRem(312),
        },
      },
    },
  }
}
