import createMediaQueries from '../../helpers/functions/createMediaQueries'
import { themeBreakpoints } from './breakpoints'
import { themeColors } from './colors'
import { themeSpacings } from './spacings'
import { themeTypography } from './typography'
import { themeShadows } from './shadows'

const theme = {
  color: {
    ...themeColors,
  },
  typography: {
    ...themeTypography,
  },
  spacings: {
    ...themeSpacings,
  },
  breakpoint: {
    ...createMediaQueries(themeBreakpoints),
  },
  breakpoints: ['640px', '768px', '1024px', '1280px', '1535px'],
  shadows: {
    ...themeShadows,
  },
}

export default {
  ...theme,
}
