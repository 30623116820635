import React, { createContext, ReactNode, useState } from 'react'

interface SnackbarContextValue {
  activeSnackbars: Set<string>
  openSnackbar: (id: string) => void
  closeSnackbar: (id: string) => void
}

export const SnackbarContext = createContext<SnackbarContextValue>({
  activeSnackbars: new Set<string>(),
  openSnackbar: () => {},
  closeSnackbar: () => {},
})

interface Props {
  children: ReactNode
}

export const SnackbarProvider = ({ children }: Props) => {
  const [activeSnackbars, setActiveSnackbars] = useState<Set<string>>(new Set())
  const openSnackbar = (id: string) => {
    setActiveSnackbars((prevActiveSnackbars) => {
      const newActiveSnackbars = new Set(prevActiveSnackbars)
      newActiveSnackbars.add(id)
      return newActiveSnackbars
    })
  }

  const closeSnackbar = (id: string) => {
    setActiveSnackbars((prevActiveSnackbars) => {
      const newActiveSnackbars = new Set(prevActiveSnackbars)
      newActiveSnackbars.delete(id)
      return newActiveSnackbars
    })
  }

  const value = {
    activeSnackbars,
    openSnackbar,
    closeSnackbar,
  }

  return <SnackbarContext.Provider value={value}>{children}</SnackbarContext.Provider>
}
