import merge from 'deepmerge'
import BlancoTheme from '../types/theme/theme.interface'
import normalize from './normalize'

const style = (theme: BlancoTheme): { [key: string]: any } => {
  const { typography } = theme

  const globals = {
    html: {
      fontSize: '100%',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      textRendering: 'optimizeLegibility',
    },
    body: {
      letterSpacing: 0,
      fontFamily: `${typography.fontFamily.helveticaRoman}`,
      fontWeight: typography.fontWeight.roman,
    },
    '& .relative': {
      position: 'relative',
    },
  }
  return merge.all([normalize, globals])
}

export default style
