import { createContext, useState, useContext, useEffect } from 'react'

interface Product {
  id: string
  // Other fields you may have in your product object
  date?: Date
  second_id: string
}

interface IProductsListContext {
  productsList: Product[]
  setProductsList: React.Dispatch<React.SetStateAction<Product[]>>
  updateProductDate: (second_id: string, date: Date) => void
}

const ProductsListContext = createContext<IProductsListContext>({
  productsList: [],
  setProductsList: () => {},
  updateProductDate: () => {},
})

export const ProductsListProvider = ({ children }: any) => {
  const [productsList, setProductsList] = useState<Product[]>([])

  useEffect(() => {
    const getProductsList = localStorage.getItem('selectedItems')
    const productListArray = getProductsList ? JSON.parse(getProductsList) : []
    setProductsList(productListArray)
  }, [])

  const updateProductDate = (second_id: string, date: Date) => {
    const updatedProductsList = productsList.map((product) => {
      if (product.second_id === second_id) {
        return {
          ...product,
          date,
        }
      }
      return product
    })
    localStorage.setItem('selectedItems', JSON.stringify(updatedProductsList))
    setProductsList(updatedProductsList)
  }

  return (
    <ProductsListContext.Provider value={{ productsList, setProductsList, updateProductDate }}>
      {children}
    </ProductsListContext.Provider>
  )
}

export const useProductsList = () => {
  return useContext(ProductsListContext)
}
