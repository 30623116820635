import styled from '@emotion/styled'
import * as Dialog from '@radix-ui/react-dialog'

import { style } from './Modal.style'
import ModalContainer from './ModalContainer'
import ModalOverlay from './ModalOverlay'
import ModalContent from './ModalContent'

const StyledModal = styled(Dialog.Root)(() => ({ ...style() }))

export interface ModalProps {
  isOpen: boolean
  onOpen?: () => void
  onClose?: () => void
  children: React.ReactNode
  modalTitle: string
  maxWidth?: string
  height?: string
  mobileHeight?: string
  doesCloseExist?: boolean
  preventPointerDownOutside?: boolean
}

const ModalComponent = ({
  isOpen,
  height,
  mobileHeight,
  maxWidth,
  onClose,
  onOpen,
  children,
  modalTitle,
  doesCloseExist,
  preventPointerDownOutside,
}: ModalProps) => (
  <StyledModal open={isOpen} onOpenChange={isOpen ? onClose : onOpen}>
    <Dialog.Portal>
      <ModalContainer>
        <ModalOverlay />
        <ModalContent
          mobileHeight={mobileHeight}
          height={height}
          doesCloseExist={doesCloseExist}
          maxWidth={maxWidth}
          onClose={onClose}
          modalTitle={modalTitle}
          preventPointerDownOutside={preventPointerDownOutside}
        >
          {children}
        </ModalContent>
      </ModalContainer>
    </Dialog.Portal>
  </StyledModal>
)

export default ModalComponent
