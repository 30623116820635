import { CSSObject } from '@emotion/react'
import { toRem } from '../../../../helpers/functions'

const style = ({
  theme,
  headlineMaxWidth,
  sectionMaxWidth,
  noPaddingOnMobile,
  isHeadlineLeft,
  isSectionLeft,
  isCentered,
  titleMarginBottom,
  mt,
  mb,
  ml,
  mr,
}: any): CSSObject => {
  const { typography, color, breakpoint, spacings } = theme
  return {
    marginTop: toRem(mt) || 0,
    marginRight: toRem(mr) || 0,
    marginBottom: toRem(mb) || 0,
    marginLeft: toRem(ml) || 0,
    ...(isCentered && {
      // Conditionally add these styles if isCentered is true
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '.headline-left-centered': {
        width: '100%',
        maxWidth: toRem(sectionMaxWidth),
      },
    }),
    '& .pageSection': {
      '&-headline': {
        maxWidth: toRem(headlineMaxWidth),
        textTransform: 'uppercase',

        margin: isHeadlineLeft ? 0 : '0 auto',
        padding: isCentered ? 0 : `0 ${spacings.s24}`,

        ...(titleMarginBottom && {
          marginBottom: toRem(titleMarginBottom / 2),
        }),

        fontSize: typography.fontSizeMobile.h3Bold,
        lineHeight: typography.lineHeightMobile.h3Bold,
        fontFamily: typography.fontFamily.helveticaHeavy,
        fontWeight: typography.fontWeight.heavy,
        color: color.primary.elementaryBlue,

        textAlign: 'left',
        [breakpoint.up('md')]: {
          fontSize: typography.fontSize.h3Bold,
          lineHeight: typography.lineHeight.h3Bold,
          textAlign: isHeadlineLeft ? 'left' : 'center',
          ...(titleMarginBottom && {
            marginBottom: toRem(titleMarginBottom),
          }),
        },
        [breakpoint.up('l')]: {
          padding: 0,
        },
      },
      '&-wrapper': {
        width: '100%',
        maxWidth: toRem(sectionMaxWidth),

        margin: isSectionLeft ? 0 : '0 auto',
        padding: isCentered ? 0 : `0 ${spacings.s24}`,
        ...(noPaddingOnMobile && {
          padding: 0,
        }),

        [breakpoint.up('xl')]: {
          padding: 0,
        },
      },
    },
    '& .hiddenOnMobile': {
      display: 'none',
      [breakpoint.up('md')]: {
        display: 'flex',
      },
    },
  }
}

export default style
