import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ProductCard } from '../../../components/atoms/ProductCard'
import { FlexComponent } from '../../../components/organisms/Layout/Flex'
import { useProductsList } from '../../../../Context/ProductListContext'
import { useModalContext } from '../../../../hooks'
import { ConfirmationDeleteProductModal } from '../ConfirmationDeleteProductModal'

const ProductsGrid = () => {
  const { t } = useTranslation()
  const [startDates, setStartDates] = useState<Record<string, Date | undefined>>({})
  const { updateProductDate, productsList, setProductsList } = useProductsList()
  const confirmationDeleteProductModalID = 'confirmationDeleteProductModal'

  const confirmationDeleteProductModal = useModalContext(confirmationDeleteProductModalID)

  // const [productId, setProductId] = useState('')
  const [secondId, setSecondId] = useState('')

  const onProductDelete = (secondId: any) => {
    confirmationDeleteProductModal.open()
    setSecondId(secondId)
  }

  const handleDateChange = (id: string, date: Date) => {
    setStartDates((prevDates) => ({
      ...prevDates,
      [id]: date,
    }))

    updateProductDate(id, date)
  }
  useEffect(() => {
    const newStartDates: Record<string, Date | undefined> = {}
    productsList.forEach((product) => {
      newStartDates[product.second_id] = product.date ? new Date(product.date) : undefined
    })
    setStartDates(newStartDates)
  }, [productsList])

  useEffect(() => {
    const storedItems = localStorage.getItem('selectedItems')
    if (storedItems) {
      setProductsList(JSON.parse(storedItems))
    }
  }, [])

  return (
    <FlexComponent flexDirection='column' width='100%' gap={24} mt={[24, 24, 60, 60]}>
      {productsList.map((product: any, index: number) => (
        <ProductCard
          t={t}
          key={`product-card-${index}`}
          serialNumber={product.id}
          // productFamily={product.familyNumber}
          productFamily={product.description}
          // imageUrl={product.media?.url}
          imageUrl={product.image?.[0]?.Dokumentenkennung}
          alt={product.media?.type}
          onProductDelete={() => onProductDelete(product.second_id)}
          startDate={startDates[product.second_id] || undefined}
          onChange={(date: any) => handleDateChange(product.second_id, date)}
        />
      ))}
      <ConfirmationDeleteProductModal secondId={secondId} />
    </FlexComponent>
  )
}

export default ProductsGrid
