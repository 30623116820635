import { useTranslation } from 'react-i18next'
import TabsComponent from '../../../components/atoms/Tabs/Tabs'
import TabsContent from '../../../components/atoms/Tabs/TabsContent'
import { UserRegistrationForm } from '../UserRegistrationForm'
import { UserLoginForm } from '../UserLoginForm'

const UserRegistrationWrapper = () => {
  const { t } = useTranslation()
  const tabTitles = [
    {
      text: t('product-registration.section.step.second.tab-registration'),
    },
    {
      text: t('product-registration.section.step.second.tab-login'),
    },
  ]

  return (
    <TabsComponent tabTitles={tabTitles} defaultValue='tabTrigger-0'>
      <TabsContent value='tabTrigger-0'>
        <UserRegistrationForm />
      </TabsContent>
      <TabsContent value='tabTrigger-1'>
        <UserLoginForm />
      </TabsContent>
    </TabsComponent>
  )
}

export default UserRegistrationWrapper
