import { useTranslation } from 'react-i18next'
import { CategoryFooter } from '../Templates/ProductDetails/CategoryFooter'
import { Text } from '../components/atoms/Text'
import { StepperHeader } from '../components/molecules/StepperHeader'
import { FlexComponent } from '../components/organisms/Layout/Flex'
import PageSection from '../components/organisms/PageSection/PageSection'

const ProductDetailsDefault = () => {
  const { t } = useTranslation()
  return (
    <>
      <StepperHeader exitUrl='/' />
      <PageSection
        sectionMaxWidth={990}
        headlineMaxWidth={483}
        headline={t('product-details-page.headline')}
        isHeadlineLeft
        isSectionLeft
        isCentered
        ml={24}
        mr={24}
      >
        <FlexComponent mt={24} maxWidth={483}>
          <Text text={t('product-details-page.description')} />
        </FlexComponent>
        <FlexComponent mt={64} maxWidth={483}>
          <Text text={t('product-details-page.subtitle')} variant='bold' />
        </FlexComponent>
        <CategoryFooter />
      </PageSection>
    </>
  )
}

export default ProductDetailsDefault
