import { CSSObject } from '@emotion/react'
import { toRem } from '../../../../helpers/functions'

export const style = ({ theme, isRow }: any): CSSObject => {
  const { breakpoint, typography, color } = theme
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: toRem(24),
    [breakpoint.up('md')]: {
      gap: toRem(40),
    },
    '& .list': {
      display: 'flex',
      flexDirection: isRow ? 'row' : 'column',
      flexWrap: isRow ? 'wrap' : 'nowrap',
      gap: toRem(8),
      padding: 0,
      margin: 0,
      [breakpoint.up('md')]: {
        gap: toRem(24),
      },
      '&-title': {
        display: 'none',
        fontSize: typography.fontSize.copyBold,
        lineHeight: typography.lineHeight.copyBold,
        fontFamily: typography.fontFamily.helveticaBold,
        [breakpoint.up('md')]: {
          display: 'inline-block',
        },
      },

      '&-item': {
        display: 'flex',
        alignItems: 'flex-start',
        gap: toRem(8),
        fontSize: toRem(16),
        lineHeight: toRem(26),
        color: color.greyscale.shipGrey,
        fontFamily: typography.fontFamily.helveticaLight,
        fontWeight: 300,
        '& svg': {
          minWidth: toRem(24),
        },
        [breakpoint.up('md')]: {
          gap: toRem(12),
          fontSize: typography.fontSize.copyLight,
          lineHeight: typography.lineHeight.copyLight,
          alignItems: 'center',
        },
      },
    },
  }
}

export default style
