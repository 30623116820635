import { toRem } from '../../../helpers/functions'

export const spacings = {
  s4: toRem(4),
  s8: toRem(8),
  s12: toRem(12),
  s16: toRem(16),
  s20: toRem(20),
  s24: toRem(24),
  s32: toRem(32),
  s40: toRem(40),
  s48: toRem(48),
  s56: toRem(56),
  s64: toRem(64),
  s80: toRem(80),
  s96: toRem(96),
}
