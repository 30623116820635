import styled from '@emotion/styled'
import { style } from './Text.style'

interface IText {
  text: string | React.ReactNode
  variant?:
    | 'primary'
    | 'secondary'
    | 'error'
    | 'newsletter'
    | 'title'
    | 'bold'
    | 'breadcrumbMain'
    | 'footer'
  fontSize?: number
  isHtml?: boolean
  isCursorPointer?: boolean
  onClick?: () => void
}

const StyledText = styled.span<Partial<IText>>((props) => ({ ...style(props) }))

const Text = ({ text, variant, fontSize, isHtml, isCursorPointer = false, onClick }: IText) => (
  <StyledText
    variant={variant}
    fontSize={fontSize}
    isCursorPointer={isCursorPointer}
    onClick={onClick}
  >
    {isHtml ? <span dangerouslySetInnerHTML={{ __html: text as string }} /> : text}
  </StyledText>
)

export default Text
