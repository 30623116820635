import styled from '@emotion/styled'

import style from './PageSection.style'

const StyledPageSection = styled.section<IPageSection>((props) => ({ ...style(props) }))

interface IPageSection {
  headline?: string
  children?: React.ReactNode
  headlineMaxWidth?: number | string
  sectionMaxWidth?: number | string
  noPaddingOnMobile?: boolean
  isHeadlineLeft?: boolean
  isSectionLeft?: boolean
  isCentered?: boolean
  titleMarginBottom?: number
  mt?: number
  ml?: number
  mr?: number
  mb?: number
}

const PageSection = ({
  noPaddingOnMobile,
  headline,
  children,
  headlineMaxWidth = '100%',
  sectionMaxWidth = '100%',
  isHeadlineLeft = false,
  isSectionLeft = false,
  isCentered = false,
  titleMarginBottom,
  mt,
  ml,
  mb,
  mr,
}: IPageSection) => (
  <StyledPageSection
    noPaddingOnMobile={noPaddingOnMobile}
    headlineMaxWidth={headlineMaxWidth}
    sectionMaxWidth={sectionMaxWidth}
    isHeadlineLeft={isHeadlineLeft}
    isSectionLeft={isSectionLeft}
    isCentered={isCentered}
    titleMarginBottom={titleMarginBottom}
    mt={mt}
    ml={ml}
    mb={mb}
    mr={mr}
  >
    {isCentered
      ? headline && (
          <div className='headline-left-centered'>
            <h1 className='pageSection-headline'>{headline}</h1>
          </div>
        )
      : headline && <h1 className='pageSection-headline'>{headline}</h1>}
    {children && <div className='pageSection-wrapper'>{children}</div>}
  </StyledPageSection>
)

export default PageSection
