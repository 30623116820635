import React, { createContext, ReactNode, useState } from 'react'

interface ModalContextValue {
  activeModals: Set<string>
  openModal: (id: string) => void
  closeModal: (id: string) => void
}

export const ModalContext = createContext<ModalContextValue>({
  activeModals: new Set<string>(),
  openModal: () => {},
  closeModal: () => {},
})

interface Props {
  children: ReactNode
}

export const ModalProvider = ({ children }: Props) => {
  const [activeModals, setActiveModals] = useState<Set<string>>(new Set())
  const openModal = (id: string) => {
    setActiveModals((prevActiveModals) => {
      const newActiveModals = new Set(prevActiveModals)
      newActiveModals.add(id)
      return newActiveModals
    })
  }

  const closeModal = (id: string) => {
    setActiveModals((prevActiveModals) => {
      const newActiveModals = new Set(prevActiveModals)
      newActiveModals.delete(id)
      return newActiveModals
    })
  }

  const value = {
    activeModals,
    openModal,
    closeModal,
  }

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
}
