import i18n from '../i18n'
import { makePublicPathUrl } from '../helpers/functions/routing'

export const accordionData = [
  {
    headerTitle: i18n.t('product-registration.modal.accordion.kuchenarmaturen.title'),
    content: i18n.t('product-registration.modal.accordion.kuchenarmaturen.content'),
    image: makePublicPathUrl('/images/kuchenarmaturen.png'),
  },
  {
    headerTitle: i18n.t('product-registration.modal.accordion.spulen.granit.title'),
    subTitle: i18n.t('product-registration.modal.accordion.spulen.granit.subTitle'),
    content: i18n.t('product-registration.modal.accordion.spulen.granit.content'),
    image: makePublicPathUrl('/images/spulen-granit.png'),
    subTitle2: i18n.t('product-registration.modal.accordion.spulen.keramik.subTitle'),
    content2: i18n.t('product-registration.modal.accordion.spulen.keramik.content'),
    image2: makePublicPathUrl('/images/spulen-keramik.png'),
    flexDirection: 'row',
  },
  {
    headerTitle: i18n.t('product-registration.modal.accordion.abfallsammler.title'),
    subTitle: i18n.t('product-registration.modal.accordion.abfallsammler.subTitle'),
    content: i18n.t('product-registration.modal.accordion.abfallsammler.content'),
    image: makePublicPathUrl('/images/abfallsammler.png'),
  },
  {
    headerTitle: i18n.t('product-registration.modal.accordion.drink-soda.title'),
    content: i18n.t('product-registration.modal.accordion.drink-soda.content'),
    image: makePublicPathUrl('/images/drink-soda.png'),
  },
]
