import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import PageSection from '../components/organisms/PageSection/PageSection'
import { Hero } from '../Templates/ProductRegistration/Hero'
import { Headline } from '../components/atoms/Headline'
import { FlexComponent } from '../components/organisms/Layout/Flex'
import { Text } from '../components/atoms/Text'
import { SearchProduct } from '../Templates/ProductRegistration/SearchProduct'
import { ReactComponent as ArrowRightIcon } from '../../icons/ArrowRight.svg'
import { Button } from '../components/atoms/Button'
import { ProductsGrid } from '../Templates/ProductRegistration/ProductsGrid'
import { useProductsList } from '../../Context/ProductListContext'
import AccordionComponent from '../components/molecules/Accordion/Accordion'
import AccordionItemWithContent from '../components/molecules/Accordion/AccordionItemWithContent'
import { UserRegistrationWrapper } from '../Templates/UserRegistration/UserRegistrationWrapper'

const ProductSection = ({ t, isProductstListEmpty }: any) => (
  <>
    <PageSection noPaddingOnMobile sectionMaxWidth={990}>
      <FlexComponent
        mt={!isProductstListEmpty ? [24, 24, 40, 40] : [0, 0, 0, 0]}
        pr={!isProductstListEmpty ? [0, 0, 24, 0] : 0}
        pl={!isProductstListEmpty ? [0, 0, 24, 0] : 0}
        flexDirection='column'
        gap={[24]}
        width='100%'
      >
        <FlexComponent maxWidth={480}>
          <Text text={t('product-registration.section.step.first.description')} />
        </FlexComponent>
        <SearchProduct t={t} />
      </FlexComponent>
    </PageSection>
    <PageSection noPaddingOnMobile sectionMaxWidth={990}>
      <FlexComponent
        pl={!isProductstListEmpty ? [0, 0, 24, 0] : 0}
        pr={!isProductstListEmpty ? [0, 0, 24, 0] : 0}
        mt={[48, 48, 60, 60]}
        gap={[10, 10, 10, 10]}
        alignItems='flex-start'
        flexDirection='column'
      >
        <Text variant='primary' text={t('product-registration.section.step.first.serial-number-cta')} />
        <FlexComponent alignItems='center'>
          <ArrowRightIcon />
          <Button
            variant='text'
            label={t('product-registration.section.step.first.find-product-details')}
            url='/product-details'
          />
        </FlexComponent>
      </FlexComponent>
    </PageSection>
  </>
)

const ProductRegistration = () => {
  const { t } = useTranslation()
  const { productsList } = useProductsList()
  const [isSecondStepActive, setIsSecondStepActive] = useState(false)

  const isProductstListEmpty = productsList.length

  return (
    <>
      <PageSection headlineMaxWidth={650} headline={t('product-registration.headline')} />
      <PageSection noPaddingOnMobile sectionMaxWidth={1160}>
        <Hero />
        <FlexComponent width='100%' mt={[38, 38, 80, 80]}>
          <Headline hasBorders headline={t('product-registration.section.step.first.headline')} />
        </FlexComponent>
      </PageSection>
      {!!isProductstListEmpty && (
        <PageSection sectionMaxWidth={990}>
          <ProductsGrid />
        </PageSection>
      )}
      <PageSection sectionMaxWidth={990}>
        {isProductstListEmpty ? (
          <FlexComponent flexDirection='column' mt={[40, 40, 60, 60]}>
            <AccordionComponent type='multiple'>
              <AccordionItemWithContent
                value='1'
                headerTitle={t('product-registration.section.step.first.accordion-title')}
                content={<ProductSection t={t} isProductstListEmpty={isProductstListEmpty} />}
              />
            </AccordionComponent>
          </FlexComponent>
        ) : (
          <ProductSection t={t} isProductstListEmpty={isProductstListEmpty} />
        )}
      </PageSection>
      <PageSection noPaddingOnMobile sectionMaxWidth={1160}>
        <FlexComponent width='100%' mt={[38, 38, 80, 80]}>
          <Headline
            isDisabled={!isProductstListEmpty}
            hasBorders
            headline={t('product-registration.section.step.second.headline')}
          />
        </FlexComponent>
      </PageSection>
      {!isSecondStepActive && (
        <PageSection noPaddingOnMobile sectionMaxWidth={990}>
          <FlexComponent mt={[24, 24, 40, 40]} pl={[24, 24, 24, 0]} pr={[24, 24, 24, 0]}>
            <Button
              desktopWidth='auto'
              width='100%'
              isDisabled={!isProductstListEmpty}
              label={t('product-registration.section.step.second.button.label')}
              variant='primary'
              onClick={() => setIsSecondStepActive(true)}
            />
          </FlexComponent>
        </PageSection>
      )}
      {isSecondStepActive && !!isProductstListEmpty && (
        <PageSection sectionMaxWidth={990}>
          <UserRegistrationWrapper />
        </PageSection>
      )}
    </>
  )
}

export default ProductRegistration
