import styled from '@emotion/styled'
import { Overlay } from '@radix-ui/react-dialog'

import { overlayStyles } from './Modal.style'

const StyledOverlay = styled(Overlay)(() => ({ ...overlayStyles() }))

const ModalOverlay = () => <StyledOverlay />

export default ModalOverlay
