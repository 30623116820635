/* eslint-disable quote-props, max-len */
/**
 * Simple page-level setup.
 *
 * 1. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 2. Ensure the page always fills at least the entire height of the viewport.
 * 3. Fluid images for responsive purposes.
 * 4. Offset `alt` text from surrounding copy.
 * 5. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */

export default {
  html: {
    boxSizing: 'border-box',
    minHeight: '100vh',
  },
  body: {
    margin: 0,
    padding: 0,
    minWidth: 320,
    height: '100%',
    '@media print': {
      // Gotta save that printer ink :)
      backgroundColor: 'white',
    },
  },
  '*, *::before, *::after': {
    boxSizing: 'border-box',
  },
  // Add correct display behavior in IE9 :)
  'article, aside, footer, header, nav, section, figcaption, figure, main': {
    display: 'block',
  },
  // Add correct box sizing in Firefox and show overflow in Edge and IE
  hr: {
    boxSizing: 'content-box',
    height: 0,
    overflow: 'visible',
  },
  // Correct the inheritance and scaling of font size in all browsers
  'pre, code, kbp, samp': {
    fontFamily: 'monospace, monospace',
    fontSize: '1em',
  },
  // Correct the font weight in Chrome, Edge and Safari
  'b, strong': {
    fontWeight: '700',
  },
  'i, em': {
    fontStyle: 'italic',
  },
  small: {
    fontSize: '75%',
  },
  // Prevent `sub` and `sup` elements from affecting the line height in all browsers
  'sub, sup': {
    fontSize: '75%',
    lineHeight: 0,
    position: 'relative',
    verticalAlign: 'baseline',
  },
  sup: {
    top: '-0.5em',
  },
  sub: {
    bottom: '-0.25em',
  },
  a: {
    // Remove gray backgrounds on active links in IE10
    background: 'transparent',
    '@supports (text-decoration-skip: objects)': {
      textDecorationSkip: 'objects',
    },
    '@supports not (text-decoration-skip: objects)': {
      textDecorationSkipInk: 'auto',
    },
    // Remove the outline on focused links when they are also hovered or active
    '&:active, &:hover': {
      outlineWidth: 0,
    },
  },
  // Show the overflow in IE and Edge
  'button, input': {
    overflow: 'visible',
  },
  // Remove the inheritance of text transform in Edge, Firefox and IE
  'button, select': {
    textTransform: 'none',
  },
  // Add ability to style clickable types in iOS and Safari
  'button, [type=reset], [type=submit]': {
    WebkitAppearance: 'button',
  },
  // Remove inner border and padding in Firefox
  'button::-moz-focus-inner, [type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner':
    {
      borderStyle: 'none',
      padding: 0,
    },
  // Restore the focus styles unset by the previous rule :)
  'button:-moz-focusring, [type=button]:-moz-focusring, [type=reset]:-moz-focusring, [type=submit]:-moz-focusring': {
    outline: '1px dotted ButtonText',
  },
  // Correct the cursor style of increment and decrement buttons in Chrome
  '[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button': {
    height: 'auto',
    WebkitAppearance: 'none',
  },
  /**
   * 1. Correct the odd appearance in Chrome and Safari.
   * 2. Correct the outline style in Safari.
   */
  '[type=search]': {
    appearance: 'textfield', // [1.]
    outlineOffset: '-2px', // [2.]
  },
  // Remove the inner padding and cancel buttons in Chrome and Safari on OS X
  '[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration': {
    appearance: 'none',
  },
  '& .form-control': {
    display: 'flex',
    gap: '8px',
  },
  /**
   * 1. Correct the inability to style clickable types in iOS and Safari.
   * 2. Change font properties to `inherit` in Safari.
   */
  '::-webkit-file-upload-button': {
    appearance: 'button', // [1.]
    font: 'inherit', // [2.]
  },
  // Add the correct display in Edge, IE, and Firefox
  'details, menu': {
    display: 'block',
  },
  // Add the correct display in all browsers
  summary: {
    display: 'list-item',
  },
  // Add the correct display in IE <9 :)
  canvas: {
    display: 'inline-block',
  },
  // Add the correct display in IE :)
  template: {
    display: 'none',
  },
  // Add the correct display in IE <10 :)
  '[hidden]': {
    display: 'none',
  },
  img: {
    fontStyle: 'italic', // [5.]
    maxWidth: '100%', // [4.]
    height: 'auto',
    verticalAlign: 'middle', // [6.]
    // Remove the border inside links in IE <10 :)
    borderStyle: 'none',
  },
  picture: {
    display: 'block',
  },
  'h1, h2, h3, h4, h5, h6, p, blockquote': {
    marginTop: 0,
    marginBottom: 0,
  },
  iframe: {
    margin: 0,
    padding: 0,
    border: 0,
    fontSize: '100%',
    verticalAlign: 'baseline',
    background: 'transparent',
  },
}
