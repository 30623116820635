import { CSSObject } from '@emotion/react'
import { toRem } from '../../../../helpers/functions'
import { spacings } from '../../../../theme/blanco/spacings/spacings'

export const styleLabel = ({ theme, isFloating, isError }: any): CSSObject => {
  const { typography, color } = theme

  const placeholderStyles = {
    fontSize: toRem(16),
    fontWeight: typography.fontWeight.light,
    color: color.greyscale.grey,
    borderRadius: 3,
    display: 'flex',
    alignItems: 'center',
    marginTop: '6px', //to align label-placeholder vertically
  }

  const floatingLabelStyle = {
    fontSize: toRem(12),
    fontFamily: typography.fontFamily.helveticaBold,
    lineHeight: toRem(16),
    top: spacings.s12,
    transition: '0.35s ease all',
    ontWeight: typography.fontWeight.bold,
    marginBottom: 'auto',
    color: isError ? color.applicationStates.error : color.greyscale.grey,
    marginTop: 0,
  }

  return {
    pointerEvents: 'none',
    position: 'absolute',
    zIndex: 1,
    top: '30%',
    left: spacings.s20,
    ...placeholderStyles,
    ...(isFloating ? floatingLabelStyle : {}),
  }
}

export const styleSelectWrap = ({ theme }: any): CSSObject => {
  const { typography, color, spacings, shadows } = theme

  return {
    fontSize: toRem(16),
    lineHeight: toRem(19),
    fontWeight: typography.fontWeight.light,
    fontFamily: typography.fontFamily.helveticaLight,

    '& .ui-select': {
      '&__control': {
        backgroundColor: color.greyscale.alabaster,
        border: 0,
        borderRadius: 3,
        boxShadow: 'none',
        transition: 'all 0.35s ease-in-out',
        padding: `0 ${spacings.s20}`,
      },
      '&__indicator': {
        padding: 0,
      },
      '&__value-container': {
        // backgroundColor: 'red',
        padding: 0,
      },
      '&__input-container': {
        margin: 0,
        padding: `36px 0 ${spacings.s8} 0`,
        fontSize: toRem(16),
      },
      '&__single-value': {
        margin: 0,
        padding: `36px 0 ${spacings.s8} 0`,
        fontSize: toRem(16),
      },
      '&__menu': {
        margin: 0,
        borderRadius: spacings.s4,
        padding: spacings.s24,
        backgroundColor: color.greyscale.alabaster,
        boxShadow: shadows.primary,
      },
      '&__option': {
        color: color.greyscale.grey,
        padding: `${spacings.s20} 0`,
        borderBottom: `1px solid ${color.greyscale.iron}`,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ':first-of-type': {
          paddingTop: 0,
        },
        ':last-of-type': {
          paddingBottom: 0,
          borderBottom: 0,
        },
        '&--is-selected': {
          backgroundColor: color.greyscale.alabaster,
        },
      },
    },
  }
}
