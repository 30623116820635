import styled from '@emotion/styled'
import { ModalComponent } from '../../../components/organisms/Modal'
import { useModalContext } from '../../../../hooks'
import { Text } from '../../../components/atoms/Text'
import { RadioButton } from '../../../components/atoms/RadioButton'
import style from './SelectModal.style'

interface ISelectModal {
  data?: any
  selected?: any
  onClick?: () => void
}

const StyledElement = styled.div<Partial<ISelectModal>>((props) => ({ ...style(props) }))

const SelectModal = () => {
  const selectModalID = 'selectModal'
  const selectModal = useModalContext(selectModalID)
  const handleModalClose = () => {
    selectModal.close()
  }

  const text = '300'
  return (
    <ModalComponent
      modalTitle={'Schrankmaßs'}
      isOpen={selectModal.isOpen}
      onClose={handleModalClose}
      height='auto'
      maxWidth='620px'
      mobileHeight='auto'
    >
      <StyledElement>
        <div className='option'>
          <Text text={`${text} x ${text} mm`} />
          <RadioButton isChecked={false} onChange={() => {}} value={text} />
        </div>
      </StyledElement>
    </ModalComponent>
  )
}

export default SelectModal
