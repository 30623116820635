import styled from '@emotion/styled'
import { useEffect } from 'react'
import { FlexComponent } from '../Layout/Flex'
import { DropdownItem } from '../../molecules/DropdownItem'
import { Button } from '../../atoms/Button'
import { Text } from '../../atoms/Text'
import { ReactComponent as ArrowRightIcon } from '../../../../icons/ArrowRight.svg'
import { Loader } from '../../atoms/Loader'
import { EmptyState } from '../EmptyState'
import { useProductsList } from '../../../../Context/ProductListContext'
import { randomNumberGenerator } from '../../../../helpers/functions/randomNumberGenerator'
import { style } from './BigDropdown.style'

const StyledBigDropdown = styled.div<Partial<IBigDropdown>>((props) => ({ ...style(props) }))

interface Item {
  id: string
  material: string
  text: string
  class: string
  familyNumber: string
  media: {
    url: string
    type: string
  }
  attributes: {
    productgroup: string
    rawmaterial: string
    shape: string
  }
}

interface IBigDropdown {
  isActive?: boolean
  items?: any
  t: any
  isLoading?: boolean
  closeDropdown?: () => void
}

const BigDropdown = ({ isActive, items, t, isLoading, closeDropdown }: IBigDropdown) => {
  const { setProductsList } = useProductsList()

  useEffect(() => {
    const storedItems = localStorage.getItem('selectedItems')
    if (storedItems) {
      setProductsList(JSON.parse(storedItems))
    }
  }, [])

  // const handleItemClick = (item: Item) => {
  //   console.log('ITEM: ', item)
  //   setProductsList((prevItems: any[]) => {
  //     const updatedItems = [...prevItems, item]
  //     localStorage.setItem('selectedItems', JSON.stringify(updatedItems))
  //     return updatedItems
  //   })

  //   if (closeDropdown) {
  //     closeDropdown()
  //   }
  // }

  const handleItemClick = (item: Item) => {
    // Adding second_id to the item object
    const itemWithSecondId = {
      ...item,
      second_id: `${randomNumberGenerator()}`, // You can assign the value dynamically as needed
    }

    setProductsList((prevItems: any[]) => {
      const updatedItems = [...prevItems, itemWithSecondId]
      localStorage.setItem('selectedItems', JSON.stringify(updatedItems))
      return updatedItems
    })

    if (closeDropdown) {
      closeDropdown()
    }
  }

  const slicedItems = items?.result.slice(0, 4)

  return (
    <StyledBigDropdown isActive={isActive}>
      {isLoading ? (
        <Loader />
      ) : slicedItems && !slicedItems?.length ? (
        <EmptyState />
      ) : (
        <>
          <div className='bigDropdown-grid'>
            {slicedItems?.map((item: any) => (
              <DropdownItem
                key={item.id}
                alt={item.media?.type}
                productName={item.description}
                productNumber={item.id}
                src={item.image?.[0]?.Dokumentenkennung}
                onClick={() => handleItemClick(item)}
              />
            ))}
          </div>
          <FlexComponent className='bigDropdown-footer' flexDirection='column' gap={[10]} mt={40}>
            <Text variant='primary' text={t('product-registration.section.step.first.serial-number-cta')} />
            <FlexComponent alignItems='center'>
              <ArrowRightIcon />
              <Button
                variant='text'
                label={t('product-registration.section.step.first.find-product-details')}
                url='/product-details'
              />
            </FlexComponent>
          </FlexComponent>
        </>
      )}
    </StyledBigDropdown>
  )
}

export default BigDropdown
