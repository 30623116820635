import { CSSObject } from '@emotion/react'
import { toRem, toRems } from '../../../../helpers/functions'

export const style = ({ theme }: any): CSSObject => {
  const { color, spacings, breakpoint, typography } = theme
  return {
    padding: toRems([8, 0]),
    '& .dropdown': {
      '&-icon': {
        cursor: 'pointer',
        background: 'none',
        border: 0,
        outline: 'none',
      },
      '&-content': {
        background: color.primary.elementaryWhite,
      },
      '&-item': {
        fontSize: toRem(16),
        fontWeight: 300,
        lineHeight: toRem(26),
        color: color.primary.elementaryBlue,
        helveticaHeavy: typography.fontFamily.helveticaThin,
        cursor: 'pointer',
        [breakpoint.up('md')]: {
          fontSize: toRem(18),
          lineHeight: toRem(32),
        },
      },
      '&-divider': {
        height: toRem(1),
        backgroundColor: color.greyscale.iron,
        margin: `${spacings.s16} 0`,
      },
      '&-itemWrapper': {
        display: 'flex',
        alignItems: 'center',
        gap: toRem(48),
        [breakpoint.up('md')]: {
          gap: toRem(40),
        },
      },
    },
  }
}

export const dropdownPortal = ({ theme }: any): CSSObject => {
  const { spacings, shadows, breakpoint } = theme
  return {
    outline: 0,
    '& > div': {
      boxShadow: shadows.primary,
      '& .dropdown-content': {
        padding: `${spacings.s16} ${spacings.s20}`,
        minWidth: toRem(130),
        '& > div': {
          outline: '0',
        },
        [breakpoint.up('md')]: {
          padding: `${spacings.s24} ${spacings.s24}`,
        },
      },
    },
  }
}
