import styled from '@emotion/styled'

import styles from './HideOn.style'

interface IHideOn {
  mobile?: boolean
  desktop?: boolean
  width?: number | string
  children: React.ReactNode
}

const HideOnStyled = styled.div<IHideOn>((props) => ({ ...styles(props) }))

const HideOn = ({ children, mobile, desktop, width }: IHideOn) => (
  <HideOnStyled width={width} mobile={mobile} desktop={desktop}>
    {children}
  </HideOnStyled>
)

export default HideOn
