import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { compact } from 'lodash'
import ClipLoader from 'react-spinners/ClipLoader'
import Form from '../../../components/molecules/Form/Form'
import { Input } from '../../../components/atoms/Input'
import { Select } from '../../../components/atoms/Select'
import { FlexComponent } from '../../../components/organisms/Layout/Flex'
import { Button } from '../../../components/atoms/Button'
import { Checkbox } from '../../../components/atoms/Checkbox'
import { Text } from '../../../components/atoms/Text'
import { ReactComponent as WarningIcon } from '../../../../icons/Warning.svg'
import { HideOn } from '../../../components/atoms/HideOn'
import { NewsletterSection } from '../../../components/organisms/NewsletterSection'
import { ReactComponent as VisibleIcon } from '../../../../icons/Visible.svg'
import { ReactComponent as HiddenIcon } from '../../../../icons/Hidden.svg'
import { RadioButton } from '../../../components/atoms/RadioButton'
import { useUser } from '../../../../Context/UserContext'
import { getCountry } from '../../../../helpers/functions/getLanguageByCountry'
import { colors } from '../../../../theme/blanco/colors/colors'

interface IDynamicOptions {
  value: string
  label: string
}

interface IRegisterUserForm {
  salutation: string
  firstname: string
  lastname: string
  address: string
  email: string
  setpassword: string
  confirmpassword: string
}

interface IConsentsModel {
  giveConsents: string[]
}

interface IUserData {
  firstName: string
  lastName: string
  email: string
  password: string
  consentsModel: IConsentsModel
}

const UserRegistrationForm = () => {
  const { t } = useTranslation()
  const { user } = useUser()

  const navigate = useNavigate()

  const country = getCountry()

  const userDomain = window.location.hostname.split('.').pop()?.toLowerCase()
  const consentSource = compact([userDomain, userDomain === 'ch' && user?.language, 'prod', 'reg'])
    .join('_')
    .toUpperCase()

  const acceptedCountries = ['de', 'at', 'ch', 'gb']

  const [isChecked, setIsChecked] = useState(false)
  const [isTextVisible, setIsTextVisible] = useState(false)
  const [isAddressComplete, setIsAddressComplete] = useState(false)
  const [isAddressFromRightCountry, setIsAddressFromRightCountry] = useState(false)

  const [isNewsletterConsentChecked, setIsNewsletterConsentChecked] = useState(false)

  // Function to handle newsletter consent change
  const handleNewsletterConsentChange = (isChecked: boolean) => {
    setIsNewsletterConsentChecked(isChecked)
  }

  const checkAddressCompleteness = () => {
    // Retrieve the stored address or set it to an empty object if not found
    const storedAddress = JSON.parse(localStorage.getItem('selectedAddress') || '{}')
    // Check if all required fields are present
    const requiredFields = ['streetNumber', 'street', 'postalCode', 'city', 'country']
    const isComplete = requiredFields.every((field) => storedAddress[field])
    // Check if the country is one of the accepted countries
    const isRightCountry = acceptedCountries.includes(storedAddress.country)

    setIsAddressComplete(isComplete) // Update state based on completeness
    setIsAddressFromRightCountry(isRightCountry) // Update state based on country match
  }

  const schema = yup
    .object({
      salutation: yup.string().required(),
      firstname: yup.string().required(),
      lastname: yup.string().required(),
      address: yup
        .string()
        .required('Address is required')
        .test(
          'is-address-complete',
          t(
            'product-registration.section.step.second.registration-section.input.error-message.address',
          ),
          () => {
            return isAddressComplete
          },
        )
        .test('is-country-ok', "This country can't be selected for product registration.", () => {
          return isAddressFromRightCountry
        }),
      email: yup
        .string()
        .email(
          t(
            'product-registration.section.step.second.registration-section.input.error-message.email',
          ),
        )
        .required()
        .matches(
          /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
          t(
            'product-registration.section.step.second.registration-section.input.error-message.email',
          ),
        ),
      setpassword: yup
        .string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters long'),
      confirmpassword: yup
        .string()
        .required('Confirm Password is required')
        .oneOf([yup.ref('setpassword')], 'Passwords must match'),
    })
    .required()
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    trigger,
    setError,
  } = useForm<IRegisterUserForm>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      salutation: '',
      firstname: '',
      lastname: '',
      address: '',
      email: '',
      setpassword: '',
      confirmpassword: '',
    },
  })

  const onCheckboxChange = () => {
    const newValue = !isChecked
    setIsChecked(newValue)
    localStorage.setItem('gdprConsent', newValue.toString())
  }

  const togglePasswordVisibility = () => setIsTextVisible(!isTextVisible)

  const logout = async () => {
    const url = new URL(
      `${process.env.REACT_APP_BLANCO_SHOP_API}/consumer/logout?locale=${user?.localeDiva}`,
    )
    const res = await fetch(url.toString(), {
      method: 'GET',
      credentials: 'include',
    })

    if (!res.ok) {
      throw new Error(`Logout request failed with status ${res.status}`)
    }

    const text = await res.text()
    // Check if response is not empty before parsing as JSON
    if (text) {
      return JSON.parse(text)
    }

    // If response is empty, return null or an appropriate value
    return null
  }

  const createUser = async (userData: any) => {
    const url = new URL(
      `${process.env.REACT_APP_BLANCO_SHOP_API}/consumer/cobe/create?locale=${user?.localeDiva}`,
    )

    const addressData = JSON.parse(localStorage.getItem('selectedAddress') || '{}')

    // Retrieve selected items from local storage
    const selectedItems = JSON.parse(localStorage.getItem('selectedItems') || '[]')

    // Filter selected items to find those with numeric ids and create the productRegistrationsBySKUStorageRequests array
    const productRegistrationsBySKUStorageRequests = selectedItems
      .filter((item: any) => !isNaN(item.id)) // Check if id is a number
      .map((item: any) => ({
        sku: item.id.toString(),
        buyDateTime: item.date || null,
      })) // Convert id to string and include buyDateTime

    const productRegistrationsByMetaStorageRequests = selectedItems
      .filter((item: any) => isNaN(item.id)) // Check if id is not a number
      .map((item: any) => ({
        meta: item.details,
        buyDateTime: item.date || null,
      })) // Include the whole item object or modify as needed

    const res = await fetch(url.toString(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        createConsumerModel: {
          email: userData.email,
          password: userData.password,
          salutation: userData.salutation,
          firstName: userData.firstName,
          lastName: userData.lastName,
          consentsModel: {
            giveConsents: userData.consentsModel.giveConsents,
          },
          source: consentSource,
        },
        addressModel: {
          id: addressData.placeId,
          street: `${addressData.street} ${addressData.streetNumber}`,
          postalCode: addressData.postalCode,
          city: addressData.city,
          country: addressData.country,
        },
        productRegistrationsByMetaStorageRequests,
        productRegistrationsBySKUStorageRequests,
      }),
      // credentials: 'include',
    })

    const data = await res.json()
    if (!res.ok) {
      if (data.messageId === 'UserExists') {
        setError('email', {
          type: 'manual',
          message: 'Email already used',
        })
      }
      throw new Error(data.message || 'Failed to create user')
    }

    // Remove the items added to the body from local storage if user creation is successful
    const remainingItems = selectedItems.filter(
      (item: any) =>
        !productRegistrationsBySKUStorageRequests.some(
          (req: any) => req.sku === item.id.toString(),
        ) &&
        !productRegistrationsByMetaStorageRequests.some((req: any) => req.meta === item.details),
    )

    localStorage.setItem('selectedItems', JSON.stringify(remainingItems))

    return data
  }

  const saveIds = (data: any) => {
    localStorage.setItem('userId', data.userId)
    localStorage.setItem('id', data.id)
    localStorage.setItem('emailAddress', data.email)
    localStorage.setItem('correlation', data.correlation)

    Cookies.set('userId', data.userId, { expires: 7 })
    Cookies.set('id', data.id, { expires: 7 })
    Cookies.set('emailAddress', data.emailAddress, { expires: 7 })
  }

  const addTestMutation = useMutation({
    mutationFn: (userData: IUserData) => createUser(userData),
    onError: (error) => {
      console.error('An error occurred:', error)
    },
    onSuccess: (data) => {
      saveIds(data)
      navigate('/confirm-registration')
      logout()
    },
  })

  const onSubmit = (data: IRegisterUserForm) => {
    const consents = ['TermsOfUse', 'PrivacyPolicy']
    if (isNewsletterConsentChecked) {
      consents.push('Newsletter')
    }

    const userData: any = {
      firstName: data.firstname,
      lastName: data.lastname,
      email: data.email,
      password: data.setpassword,
      salutation: data.salutation,
      consentsModel: {
        giveConsents: consents,
      },
    }
    addTestMutation.mutate(userData)
  }

  const fetchGooglePlacesPredictions = (inputValue: string): Promise<IDynamicOptions[]> => {
    return new Promise((resolve, reject) => {
      if (!inputValue) {
        resolve([])
        return
      }
      if (typeof window.google === 'undefined') {
        reject('Google Maps JavaScript API is not loaded.')
        return
      }

      let componentRestrictions: any = {}
      switch (userDomain) {
        case 'de':
          componentRestrictions = { country: 'de' }
          break
        case 'at':
          componentRestrictions = { country: 'at' }
          break
        case 'ch':
          componentRestrictions = { country: 'ch' }
          break
        case 'gb':
        case 'uk':
          componentRestrictions = { country: 'gb' }
          break
        default:
          componentRestrictions = {} // No restrictions for other domains
          break
      }

      const service = new window.google.maps.places.AutocompleteService()
      service.getPlacePredictions(
        { input: inputValue, componentRestrictions },
        (predictions: any, status: any) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
            const formattedPredictions = predictions.map((prediction: any) => {
              return {
                label: prediction.description,
                value: prediction.place_id,
              }
            })
            resolve(formattedPredictions)
          } else {
            reject('Failed to load predictions')
          }
        },
      )
    })
  }

  const fetchPlaceDetailsAndStore = (placeId: string) => {
    if (typeof window.google === 'undefined' || !placeId) {
      console.error('Google Maps JavaScript API is not loaded or placeId is missing.')
      return
    }

    const service = new window.google.maps.places.PlacesService(document.createElement('div'))
    service.getDetails({ placeId: placeId }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK && place) {
        const addressComponents = place.address_components
        const getAddressComponent = (type: any, short?: boolean) => {
          const component = addressComponents?.find((component) => component.types.includes(type))
          return component ? (short ? component.short_name : component.long_name) : ''
        }

        // Extracting the specific fields
        const placeId = place.place_id
        const streetNumber = getAddressComponent('street_number')
        const street = getAddressComponent('route')
        const postalCode = getAddressComponent('postal_code')
        const city = getAddressComponent('sublocality')
          ? [
              getAddressComponent('locality') || getAddressComponent('postal_town'),
              getAddressComponent('sublocality'),
            ].join(', ')
          : getAddressComponent('locality') || getAddressComponent('postal_town')
        const country = getAddressComponent('country', true)?.toLowerCase()

        // Storing in local storage
        localStorage.setItem(
          'selectedAddress',
          JSON.stringify({ placeId, streetNumber, street, postalCode, city, country }),
        )

        checkAddressCompleteness()
      } else {
        console.error('Failed to load place details')
      }
    })
  }

  return (
    <>
      <Form
        title={t('product-registration.section.step.second.registration-section.form-title')}
        description={t(
          'product-registration.section.step.second.registration-section.form-description',
        )}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name='salutation'
          control={control}
          render={({ field }) => (
            <FlexComponent flexDirection='column'>
              <FlexComponent gap={24} flexWrap='wrap'>
                <RadioButton
                  value='Male'
                  isChecked={field.value === 'Male'}
                  onChange={() => field.onChange('Male')}
                  label={t(
                    'product-registration.section.step.second.registration-section.radio-button.male',
                  )}
                />
                <RadioButton
                  value='Female'
                  isChecked={field.value === 'Female'}
                  onChange={() => field.onChange('Female')}
                  label={t(
                    'product-registration.section.step.second.registration-section.radio-button.female',
                  )}
                />
              </FlexComponent>
              {!!errors.salutation && (
                <Text
                  variant='error'
                  text={t(
                    'product-registration.section.step.second.registration-section.radio-button.error-message',
                  )}
                />
              )}
            </FlexComponent>
          )}
        />

        <Controller
          name='firstname'
          control={control}
          render={({ field }) => (
            <Input
              hasError={!!errors.firstname}
              backIcon={!!errors.firstname && <WarningIcon />}
              errorMessage={t(
                'product-registration.section.step.second.registration-section.input.error-message.first-name',
              )}
              isRequired={true}
              label={t(
                'product-registration.section.step.second.registration-section.input.placeholder.first-name',
              )}
              placeholder={t(
                'product-registration.section.step.second.registration-section.input.placeholder.first-name',
              )}
              {...field}
            />
          )}
        />
        <Controller
          name='lastname'
          control={control}
          render={({ field }) => (
            <Input
              hasError={!!errors.lastname}
              backIcon={!!errors.lastname && <WarningIcon />}
              errorMessage={t(
                'product-registration.section.step.second.registration-section.input.error-message.last-name',
              )}
              isRequired={true}
              label={t(
                'product-registration.section.step.second.registration-section.input.placeholder.last-name',
              )}
              placeholder={t(
                'product-registration.section.step.second.registration-section.input.placeholder.last-name',
              )}
              {...field}
            />
          )}
        />
        <Controller
          name='address'
          control={control}
          render={({ field }) => (
            <Select
              loadOptionsFunction={fetchGooglePlacesPredictions}
              onChange={(selected: any) => {
                if (selected) {
                  fetchPlaceDetailsAndStore(selected.value)
                  field.onChange(selected.label) // Update the address field with the selected address label
                  // No then() chaining here, just directly call the next steps
                  setIsAddressComplete(true) // Assume the address is complete for now
                  setIsAddressFromRightCountry(true)
                  // Manually trigger revalidation for the address field after a slight delay to ensure state updates
                  setTimeout(() => trigger('address'), 0)
                } else {
                  field.onChange('') // Clear the field if no address is selected
                  setIsAddressComplete(false) // Reset the address completeness state
                  setIsAddressFromRightCountry(false)
                  setTimeout(() => trigger('address'), 0) // Re-trigger validation for the address field
                }
              }}
              placeholder='Select an address'
              label={t(
                userDomain === 'uk'
                  ? 'product-registration.section.step.second.registration-section.input.placeholder.uk-address'
                  : 'product-registration.section.step.second.registration-section.input.placeholder.address',
              )}
              isRequired={true}
              helperText={errors.address && errors.address.message}
              isDisabled={false}
              isError={!!errors.address}
            />
          )}
        />
        <Controller
          name='email'
          control={control}
          render={({ field }) => (
            <Input
              hasError={!!errors.email}
              backIcon={!!errors.email && <WarningIcon />}
              errorMessage={
                errors.email
                  ? errors.email.message
                  : t(
                      'product-registration.section.step.second.registration-section.input.error-message.email',
                    )
              }
              isRequired={true}
              label={t(
                'product-registration.section.step.second.registration-section.input.placeholder.email',
              )}
              placeholder={t(
                'product-registration.section.step.second.registration-section.input.placeholder.email',
              )}
              {...field}
            />
          )}
        />
        <Controller
          name='setpassword'
          control={control}
          render={({ field }) => {
            const showIcon = field.value ? true : false
            return (
              <Input
                type={isTextVisible ? 'text' : 'password'}
                hasError={!!errors.setpassword}
                onBackIconClick={togglePasswordVisibility}
                backIcon={showIcon && (isTextVisible ? <HiddenIcon /> : <VisibleIcon />)}
                errorMessage={t(
                  'product-registration.section.step.second.registration-section.input.error-message.password',
                )}
                isRequired={true}
                label={t(
                  'product-registration.section.step.second.registration-section.input.placeholder.set-password',
                )}
                placeholder={t(
                  'product-registration.section.step.second.registration-section.input.placeholder.set-password',
                )}
                {...field}
              />
            )
          }}
        />
        <Controller
          name='confirmpassword'
          control={control}
          render={({ field }) => {
            const showIcon = field.value ? true : false
            return (
              <Input
                type={isTextVisible ? 'text' : 'password'}
                hasError={!!errors.confirmpassword}
                onBackIconClick={togglePasswordVisibility}
                backIcon={showIcon && (isTextVisible ? <HiddenIcon /> : <VisibleIcon />)}
                errorMessage={t(
                  'product-registration.section.step.second.registration-section.input.error-message.password',
                )}
                isRequired={true}
                label={t(
                  'product-registration.section.step.second.registration-section.input.placeholder.confirm-password',
                )}
                placeholder={t(
                  'product-registration.section.step.second.registration-section.input.placeholder.confirm-password',
                )}
                {...field}
              />
            )
          }}
        />
        <Text
          variant='newsletter'
          text={t(
            'product-registration.section.step.second.registration-section.input.password-instructions',
          )}
        />
        <HideOn mobile>
          <FlexComponent gap={16} mt={32}>
            <Checkbox isError={false} isChecked={isChecked} onChange={() => onCheckboxChange()} />
            <Text
              isHtml={true}
              variant='newsletter'
              text={t(
                `product-registration.section.step.second.registration-section.checkbox.form-checkbox.${country}`,
              )}
            />
          </FlexComponent>
        </HideOn>

        <HideOn mobile>
          <FlexComponent mt={52} alignItems='center' gap={16}>
            <Button
              isDisabled={!isChecked || !isValid || addTestMutation.isLoading}
              type='submit'
              label={t('product-registration.section.step.second.button.register')}
              variant='primary'
              width='100%'
              desktopWidth='auto'
            />
            {addTestMutation.isLoading && (
              <ClipLoader
                size={24}
                color={colors.primary.elementaryBlue}
                loading={addTestMutation.isLoading}
              />
            )}
          </FlexComponent>
        </HideOn>
      </Form>
      <NewsletterSection onNewsletterConsentChange={handleNewsletterConsentChange} />
      <HideOn desktop>
        <FlexComponent gap={16} mt={40}>
          <Checkbox isError={false} isChecked={isChecked} onChange={() => onCheckboxChange()} />
          <Text
            isHtml={true}
            variant='newsletter'
            text={t(
              `product-registration.section.step.second.registration-section.checkbox.form-checkbox.${country}`,
            )}
          />
        </FlexComponent>
      </HideOn>
      <HideOn desktop>
        <FlexComponent mt={40} flexDirection='column' gap={16} alignItems='center'>
          <Button
            isDisabled={!isChecked || !isValid || addTestMutation.isLoading}
            type='submit'
            label={t('product-registration.section.step.second.button.register')}
            variant='primary'
            width='100%'
            desktopWidth='auto'
            onClick={() => handleSubmit(onSubmit)()}
          />
          {addTestMutation.isLoading && (
            <ClipLoader
              size={24}
              color={colors.primary.elementaryBlue}
              loading={addTestMutation.isLoading}
            />
          )}
        </FlexComponent>
      </HideOn>
    </>
  )
}

export default UserRegistrationForm
