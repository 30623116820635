import styled from '@emotion/styled'
import { ReactComponent as CloseIcon } from '../../../../icons/Close.svg'

import { headerStyles } from './Modal.style'

const StyledModalHeader = styled.header<Partial<Props>>((props) => ({ ...headerStyles(props) }))

interface Props {
  modalTitle: string
  onClose?: VoidFunction
  modalIcon?: any
  customHeaderHeight?: string
  doesCloseExist: boolean
}
const ModalHeader = ({ modalTitle, onClose, doesCloseExist = true, customHeaderHeight }: Props) => (
  <StyledModalHeader customHeaderHeight={customHeaderHeight}>
    {modalTitle && modalTitle}
    {doesCloseExist && (
      <div onClick={onClose} className='close'>
        {<CloseIcon />}
      </div>
    )}
  </StyledModalHeader>
)

export default ModalHeader
