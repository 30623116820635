export const breakpoints = {
  values: {
    xs: 0,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1535,
  },
  /**
   *  Precision of measurement, it will be divided by 100, meaning we measure growth in 0.05
   *  pixels if step is equal to 5 and unit is equal to 'px'.
   */
  unit: 'px',
  step: 5,
}
