import styled from '@emotion/styled'
import DatePicker from 'react-datepicker'
import { useEffect, useState } from 'react'
import { isAfter, startOfToday } from 'date-fns' // Import date utility functions

import { FlexComponent } from '../../organisms/Layout/Flex'
import { ReactComponent as CalendarIcon } from '../../../../icons/Calendar.svg'
import 'react-datepicker/dist/react-datepicker.css'
import { ReactComponent as ChevronLeft } from '../../../../icons/ChevronLeft.svg'
import { ReactComponent as ChevronRight } from '../../../../icons/ChevronRight.svg'
import { style } from './Datepicker.style'

interface IDatepicker {
  startDate: Date
  onChange: (date: Date) => void
  selected: Date
  placeholderText: string
  datepickerLabel: string
  datepickerOptional: string
  isDisabled?: boolean
}

const StyledDatepicker = styled.div((props) => ({ ...style(props) }))

const DatepickerComponent = ({
  startDate,
  onChange,
  datepickerLabel,
  datepickerOptional,
  selected,
  placeholderText,
  isDisabled = false,
}: IDatepicker) => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768) // You can set the breakpoint according to your needs
  const updateWindowDimensions = () => {
    setIsDesktop(window.innerWidth > 768)
  }

  useEffect(() => {
    window.addEventListener('resize', updateWindowDimensions)
    return () => window.removeEventListener('resize', updateWindowDimensions)
  }, [])

  const isFutureDate = (date: Date) => {
    return isAfter(date, startOfToday())
  }

  const getDayClassName = (date: Date) => {
    return isFutureDate(date) ? 'react-datepicker__day--future' : ''
  }

  if (isDisabled) {
    return null
  }
  return (
    <StyledDatepicker>
      <FlexComponent className='datepicker-wrapper' gap={14} alignItems='center'>
        <FlexComponent className='datepicker-icon'>
          <CalendarIcon />
        </FlexComponent>
        <FlexComponent flexDirection='column' justifyContent='center'>
          <span className='datepicker-label'>
            {datepickerLabel} <span>{datepickerOptional}</span>
          </span>
          <DatePicker
            placeholderText={placeholderText}
            className='datepicker-component'
            selected={selected}
            onChange={onChange}
            startDate={startDate}
            maxDate={new Date()} // Block future dates
            dayClassName={getDayClassName} // Add custom class to future dates
            popperPlacement='bottom-start'
            popperModifiers={[
              {
                name: 'offset',
                options: {
                  offset: isDesktop ? [-58, 25] : [-58, 20],
                },
              },
            ]}
            nextMonthButtonLabel={<ChevronRight />}
            previousMonthButtonLabel={<ChevronLeft />}
          />
        </FlexComponent>
      </FlexComponent>
    </StyledDatepicker>
  )
}

export default DatepickerComponent
