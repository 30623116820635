import { CSSObject } from '@emotion/react'
import { toRem } from '../../../../helpers/functions'

export const style = ({ theme, variant, fontSize, isCursorPointer }: any): CSSObject => {
  const { typography, color, breakpoint } = theme
  return {
    fontSize: fontSize ? toRem(fontSize) : toRem(16),
    fontWeight: typography.fontWeight.light,
    fontFamily: typography.fontFamily.helveticaLight,
    lineHeight: toRem(26),
    color: color.greyscale.shipGrey,
    display: 'inline-block',
    [breakpoint.up('md')]: {
      fontSize: fontSize ? toRem(fontSize) : typography.fontSize.copyLight,
      lineHeight: typography.lineHeight.copyLight,
    },
    ...(variant === 'primary' && {
      fontWeight: typography.fontWeight.bold,
      color: color.greyscale.shipGrey,
    }),
    ...(variant === 'secondary' && {
      fontSize: typography.fontSizeMobile.interactionsBold,
      lineHeight: typography.lineHeightMobile.interactionsBold,
      color: color.primary.elementaryBlue,
      fontWeight: typography.fontWeight.bold,
      fontFamily: typography.fontFamily.helveticaBold,
    }),
    ...(variant === 'error' && {
      fontWeight: typography.fontWeight.light,
      color: color.applicationStates.error,
      fontSize: typography.fontSizeMobile.interactionsBoldSmallWide,
      lineHeight: typography.lineHeightMobile.interactionsBoldSmallWide,
      fontFamily: typography.fontFamily.helveticaLight,
      [breakpoint.up('md')]: {
        fontSize: typography.fontSize.interactionsBoldSmallWide,
      },
    }),
    ...(variant === 'newsletter' && {
      fontWeight: typography.fontWeight.light,
      color: color.greyscale.shipGrey,
      fontSize: typography.fontSize.descriptionsRoman,
      lineHeight: typography.lineHeight.descriptionsRoman,
      fontFamily: typography.fontFamily.helveticaLight,
      [breakpoint.up('md')]: {
        fontSize: typography.fontSize.descriptionsRoman,
      },
    }),
    ...(variant === 'title' && {
      // fontWeight: typography.fontWeight.bold,
      // color: color.primary.elementaryBlue,
      // fontSize: typography.fontSizeMobile.copyBold,
      // lineHeight: typography.lineHeight.copyBold,
      // fontFamily: typography.fontFamily.helveticaLight,

      color: color.primary.elementaryBlue,
      fontSize: typography.fontSizeMobile.copyBold,
      lineHeight: typography.lineHeightMobile.interactionsBoldSmallWide,
      fontWeight: typography.fontWeight.bold,
      fontFamily: typography.fontFamily.helveticaBlack,
    }),
    ...(variant === 'bold' && {
      fontWeight: typography.fontWeight.bold,
      color: color.greyscale.shipGrey,
      fontSize: typography.lineHeightMobile.interactionsBoldSmall,
      lineHeight: toRem(26),
      fontFamily: typography.fontFamily.helveticaBold,
      [breakpoint.up('md')]: {
        fontSize: typography.fontSizeMobile.copyBold,
        lineHeight: typography.lineHeight.copyBold,
      },
    }),
    ...(variant === 'breadcrumbMain' && {
      fontWeight: typography.fontWeight.medium,
      color: color.greyscale.shipGrey,
      fontSize: typography.fontSize.interactionsMedium,
      lineHeight: typography.lineHeight.interactionsMedium,
      fontFamily: typography.fontFamily.helveticaMedium,
      textTransform: 'uppercase',
    }),

    ...(variant === 'footer' && {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',

      width: '100%',

      fontSize: toRem(12),
      fontWeight: 700,
      lineHeight: toRem(28),
      letterSpacing: toRem(2),
      textTransform: 'uppercase',
      fontFamily: typography.fontFamily.helveticaHeavy,

      '& a': {
        textDecoration: 'none',
        color: color.greyscale.shipGrey,
      },
      [breakpoint.up('md')]: {
        justifyContent: 'flex-end',
        flexDirection: 'row',
        gap: toRem(32),
        width: 'auto',
      },
    }),

    ...(isCursorPointer && {
      cursor: 'pointer',
    }),
  }
}

export default style
