import styled from '@emotion/styled'
import { useEffect } from 'react'
import { MaterialCard } from '../../../components/molecules/MaterialCard'
import { ProductDetailsCard } from '../../../components/molecules/ProductDetailsCard'
import { FlexComponent } from '../../../components/organisms/Layout/Flex'
import { Text } from '../../../components/atoms/Text'
import { Button } from '../../../components/atoms/Button'
import { useSnackbarContext } from '../../../../hooks'
import { OptionalAttributesSnackbar } from '../../../Templates/ProductDetails/OptionalAttributesSnackbar'
import style from './GridOptions.style'

interface IGridOptions {
  data?: any
  currentQuery: string
  hoveredCard?: number | null // Add this prop
  onCardClick?: (material: any, id: any) => void
  onClick?: (element: any) => void
  onMouseEnter?: (index: number) => void // Add this prop
  onMouseLeave?: () => void // Add this prop
  type?: 'optionalAttributes' | 'mandatoryAttributes'
  selectedCardInfo?: any
  onReset?: () => void
}

const StyledGridOptions = styled.div<Partial<IGridOptions>>((props) => ({ ...style(props) }))

const GridOptions = ({
  data,
  currentQuery,
  hoveredCard,
  onCardClick,
  onMouseEnter,
  onMouseLeave,
  onClick,
  selectedCardInfo,
  type = 'mandatoryAttributes',
  onReset,
}: IGridOptions) => {
  const optionalAttributesSnackbarID = 'optionalAttributesSnackbar'

  const optionalAttributesSnackbar = useSnackbarContext(optionalAttributesSnackbarID)
  useEffect(() => {
    optionalAttributesSnackbar.open()
  }, [])
  return (
    <StyledGridOptions>
      {type === 'mandatoryAttributes' ? (
        <div className='grid-wrapper'>
          {data?.result?.map((card: any, index: number) => {
            // console.log('CARD', card)
            return (
              <MaterialCard
                key={card.id || index}
                imageUrl={card.imagePath || card.value}
                name={currentQuery && card[currentQuery]}
                isLowerOpacity={hoveredCard !== null && hoveredCard !== (card.id ? card.id : index)}
                onMouseEnter={() => onMouseEnter && onMouseEnter(card.id || index)}
                onMouseLeave={onMouseLeave}
                onClick={() => onCardClick && currentQuery && onCardClick(card[currentQuery], card.id)}
              />
            )
          })}
        </div>
      ) : (
        <div className='grid-wrapper'>
          <OptionalAttributesSnackbar />
          {data?.result?.map((element: any, index: number) => (
            <ProductDetailsCard
              title={element[currentQuery]}
              img={element.imagePath}
              type='single'
              onClick={
                onClick
                  ? () => {
                      onClick(element)
                    }
                  : () => {}
              }
              key={index}
              isChecked={selectedCardInfo[currentQuery]?.[currentQuery] === element[currentQuery] ?? false}
            />
          ))}
          {data?.result?.length === 0 && (
            <FlexComponent flexDirection='column' gap={16} textAlign='center'>
              <ProductDetailsCard isEmptyState />
              <Text text='No available filters.' />
              <Button type='button' onClick={onReset} variant='secondary' label={'Reset all filters'} />
            </FlexComponent>
          )}
        </div>
      )}
    </StyledGridOptions>
  )
}

export default GridOptions
