import styled from '@emotion/styled'
import * as Tabs from '@radix-ui/react-tabs'
import { tabcontentStyles } from './Tabs.style'

interface ITabsContent {
  children: React.ReactNode
  value: string
}

const StyledTabsContent = styled(Tabs.Content)((props) => ({ ...tabcontentStyles(props) }))

const TabsContent = ({ children, value }: ITabsContent) => (
  <StyledTabsContent className='tabs-content' value={value}>
    {children}
  </StyledTabsContent>
)

export default TabsContent
