import { CSSObject, keyframes } from '@emotion/react'
import { toRem } from '../../../../helpers/functions'

const slideDown = keyframes`
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
`

const slideUp = keyframes`
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
`

export const style = (): CSSObject => {
  return {
    width: '100%',
  }
}

export const accordionItem = ({ theme }: any): CSSObject => {
  const { breakpoint, typography, color, spacings } = theme
  return {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: spacings.s16,
    [breakpoint.up('md')]: {
      gap: spacings.s24,
    },
    '& .accordionItem': {
      '&-headerTitle': {
        display: 'flex',
        justifyContent: 'space-between',
        '& span': {
          fontSize: typography.fontSizeMobile.interactionsHeavy,
          fontWeight: typography.fontWeight.bold,
          fontFamily: typography.fontFamily.helveticaBold,
          color: color.primary.elementaryBlue,
          textTransform: 'uppercase',
        },
      },
      '&-headerTitleWIthContent': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        [breakpoint.up('md')]: {
          justifyContent: 'flex-start',
          gap: spacings.s16,
        },
        '& span': {
          fontSize: typography.fontSizeMobile.copyBold,
          lineHeight: typography.lineHeightMobile.copyBold,
          fontFamily: typography.fontFamily.helveticaBold,
          fontWeight: typography.fontWeight.bold,
          color: color.primary.elementaryBlue,
        },
      },
      '&-chevron': {
        transition: 'transform 0.3s ease-in-out',
      },
      '&-trigger': {
        background: 'none',
        border: 'none',
        outline: 0,
        boxShadow: 'none',
        cursor: 'pointer',
        padding: 0,
        margin: 0,
        '& [data-state="open"] > .accordionItem-chevron': {
          transform: 'rotate(180deg)',
        },
      },
      '&-contentWrapper': {
        overflow: 'hidden',
        "&[data-state='open']": {
          animation: `${slideDown} 300ms ease-out`,
        },
        "&[data-state='closed']": {
          animation: `${slideUp} 300ms ease-out`,
        },
      },
      '&-contentWrapperWithContent': {
        "&[data-state='open']": {
          animation: `${slideDown} 300ms ease-out`,
        },
        "&[data-state='closed']": {
          animation: `${slideUp} 300ms ease-out`,
        },
      },
      '&-content': {
        display: 'flex',
        flexDirection: 'column',
        gap: toRem(24),
        justifyContent: 'flex-start',
        '& img': {
          maxWidth: toRem(270),
          alignSelf: 'center',
          [breakpoint.up('md')]: {
            alignSelf: 'flex-start',
          },
        },
        '&_subtitle': {
          fontSize: typography.fontSizeMobile.copyBold,
          lineHeight: typography.lineHeightMobile.copyBold,
          fontFamily: typography.fontFamily.helveticaBold,
          fontweight: typography.fontWeight.bold,
          color: color.greyscale.shipGrey,
        },
        '&_description': {
          fontSize: toRem(16),
          lineHeight: typography.lineHeightMobile.interactionsBoldSmallWide,
          fontFamily: typography.fontFamily.helveticaLight,
          fontweight: typography.fontWeight.light,
          color: color.greyscale.shipGrey,
          [breakpoint.up('md')]: {
            fontSize: typography.fontSize.copyBold,
            lineHeight: typography.lineHeight.copyBold,
          },
        },
      },
    },
  }
}
