import styled from '@emotion/styled'
import { FlexComponent } from '../../organisms/Layout/Flex'
import { ReactComponent as BackIcon } from '../../../../icons/ChevronLeft.svg'
import { ReactComponent as ExitIcon } from '../../../../icons/Close.svg'
import { Text } from '../../atoms/Text'
import { useModalContext } from '../../../../hooks'
import { ExitModal } from '../../../Templates/ProductDetails/ExitModal'
import { style } from './StepperHeader.style'

interface IStepperHeader {
  onBack?: () => void
  exitUrl?: string
  breadcrumbs?: string[]
  stepNumber?: number
  maxStepNumber?: number
  marginBottom?: number
}

const StyledStepperHeader = styled.div<Partial<IStepperHeader>>((props) => ({ ...style(props) }))

const StepperHeader = ({ onBack, exitUrl, breadcrumbs, stepNumber, maxStepNumber, marginBottom }: IStepperHeader) => {
  const exitModalID = 'exitModal'

  const exitModal = useModalContext(exitModalID)
  const handleModalOpen = () => {
    exitModal.open()
  }

  return (
    <StyledStepperHeader marginBottom={marginBottom} stepNumber={stepNumber} maxStepNumber={maxStepNumber}>
      <FlexComponent
        flexDirection='row-reverse'
        alignItems='center'
        gap={12}
        pt={[16, 16, 32, 32]}
        pr={[24, 24, 60, 60]}
        pb={[16, 16, 32, 32]}
        pl={[24, 24, 60, 60]}
      >
        {exitUrl && (
          <ExitIcon
            width={32}
            height={32}
            onClick={() => {
              handleModalOpen()

              // navigate(exitUrl)
            }}
          />
        )}
        {breadcrumbs && (
          <FlexComponent width='100%'>
            <FlexComponent flexDirection='column' gap={1}>
              <Text text={breadcrumbs.slice(0, -1).join('/')} variant='newsletter' />
              <Text text={breadcrumbs.slice(-1).toString()} variant='breadcrumbMain' fontSize={14} />
            </FlexComponent>
          </FlexComponent>
        )}
        {onBack && <BackIcon width={32} height={32} onClick={onBack} />}
      </FlexComponent>
      {breadcrumbs && (
        <div className='progress-wrapper'>
          <div className='progress-fill'></div>
        </div>
      )}
      <ExitModal />
    </StyledStepperHeader>
  )
}

export default StepperHeader
