import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import Form from '../../../components/molecules/Form/Form'
import { Input } from '../../../components/atoms/Input'
import { FlexComponent } from '../../../components/organisms/Layout/Flex'
import { Button } from '../../../components/atoms/Button'
import { Checkbox } from '../../../components/atoms/Checkbox'
import { Text } from '../../../components/atoms/Text'
import { ReactComponent as WarningIcon } from '../../../../icons/Warning.svg'
import { ReactComponent as VisibleIcon } from '../../../../icons/Visible.svg'
import { ReactComponent as HiddenIcon } from '../../../../icons/Hidden.svg'
import { HideOn } from '../../../components/atoms/HideOn'
import { useModalContext } from '../../../../hooks'
import { ResetPasswordModal } from '../ResetPasswordModal'
import { ResendOtpSnackbar } from '../ResendOtpSnackbar'
import { useAccess } from '../../../../Context/AccessContext'
import { useUser } from '../../../../Context/UserContext'
import { getCountry } from '../../../../helpers/functions/getLanguageByCountry'

interface ILoginUserForm {
  email: string
  password: string
}

const UserLoginForm = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { user } = useUser()

  const { setHasAccess } = useAccess()

  const [isChecked, setIsChecked] = useState(false)
  const [isTextVisible, setIsTextVisible] = useState(false)
  const [selectedProductIds, setSelectedProductIds] = useState<number[]>([])
  const [manualProductIds, setManualProductIds] = useState<any[]>([])
  const [loginError, setLoginError] = useState('')

  const country = getCountry()

  useEffect(() => {
    // Retrieve and filter products from local storage
    const selectedItems = JSON.parse(localStorage.getItem('selectedItems') || '[]')
    const filteredProductIds: number[] = []
    const manualIds: string[] = []

    selectedItems.forEach((item: any) => {
      const parsedId = parseInt(item.id, 10)
      if (!isNaN(parsedId)) {
        filteredProductIds.push(parsedId)
      } else {
        manualIds.push(item)
      }
    })

    setSelectedProductIds(filteredProductIds)
    setManualProductIds(manualIds)
  }, [])

  const schema = yup
    .object({
      email: yup
        .string()
        .email(t('product-registration.section.step.second.login-section.input.error-message.email'))
        .required(),
      password: yup.string().required(),
    })
    .required()

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ILoginUserForm>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const onCheckboxChange = () => {
    const newValue = !isChecked
    setIsChecked(newValue)
    localStorage.setItem('gdprConsent', newValue.toString())
  }

  const togglePasswordVisibility = () => setIsTextVisible(!isTextVisible)

  const resetPasswordModalID = 'resetPasswordModal'

  const resetPasswordModal = useModalContext(resetPasswordModalID)
  const handleModalOpen = () => {
    resetPasswordModal.open()
  }

  const logout = async () => {
    const url = new URL(`${process.env.REACT_APP_BLANCO_SHOP_API}/consumer/logout?locale=${user?.localeDiva}`)
    const res = await fetch(url.toString(), {
      method: 'GET',
      credentials: 'include',
    })

    if (!res.ok) {
      throw new Error(`Logout request failed with status ${res.status}`)
    }
    return res.json()
  }

  const userLogin = async (data: any) => {
    const url = new URL(
      `${process.env.REACT_APP_BLANCO_SHOP_API}/consumer/login?locale=${user?.localeDiva}&rememberMe=false`,
    )
    const res = await fetch(url.toString(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: data.email,
        password: data.password,
      }),
      credentials: 'include',
    })

    if (!res.ok) {
      throw new Error(`Request failed with status ${res.status}`)
    }

    const text = await res.text()
    if (!text) {
      // const token = await Cookies.get('ciamAuthToken')
      // console.log('token', token)
      return { message: 'Login successful', data: {} }
    }

    const responseData = JSON.parse(text)
    // console.log('responseData', responseData)
    return responseData
  }

  const registerProducts = async () => {
    const url = new URL(
      `${process.env.REACT_APP_BLANCO_SHOP_API}/consumer/product/registrations/add/bysku?locale=${user?.localeDiva}`,
    )
    const selectedItems = JSON.parse(localStorage.getItem('selectedItems') || '[]')

    const promises = selectedProductIds.map(async (id) => {
      const product = selectedItems.find((item: any) => parseInt(item.id, 10) === id)
      const buyDateTime = product?.date || null

      const response = await fetch(url.toString(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sku: id.toString(),
          buyDateTime: buyDateTime,
        }),
        credentials: 'include',
      })

      if (response.ok) {
        const selectedItems = JSON.parse(localStorage.getItem('selectedItems') || '[]')
        const updatedSelectedItems = selectedItems.filter((item: { id: string }) => parseInt(item.id, 10) !== id)
        localStorage.setItem('selectedItems', JSON.stringify(updatedSelectedItems))
        return true
      }
      return false
    })

    await Promise.all(promises)
  }

  const registerManuallyAddedProducts = async () => {
    const url = new URL(
      `${process.env.REACT_APP_BLANCO_SHOP_API}/consumer/product/registrations/add/bymeta?locale=${user?.localeDiva}`,
    )
    const selectedItems = JSON.parse(localStorage.getItem('selectedItems') || '[]')

    const promises = manualProductIds.map(async (product) => {
      const storedProduct = selectedItems.find((item: any) => item.id === product.id)
      const buyDateTime = storedProduct?.date || null

      const response = await fetch(url.toString(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          meta: product.details,
          buyDateTime: buyDateTime,
        }),
        credentials: 'include',
      })

      if (response.ok) {
        const selectedItems = JSON.parse(localStorage.getItem('selectedItems') || '[]')

        const updatedSelectedItems = selectedItems.filter((item: { id: string }) => item.id !== product.id)
        localStorage.setItem('selectedItems', JSON.stringify(updatedSelectedItems))
        return true
      }
      return false
    })

    await Promise.all(promises)
  }
  const loginMutation = useMutation({
    mutationFn: (loginData: any) => userLogin(loginData),
    onError: (error) => {
      console.error('An error has occurred: ', error)
      setLoginError(t('product-registration.section.step.second.login-section.input.error-message.failed-login'))
    },
    onSuccess: async () => {
      await registerProducts()
      await registerManuallyAddedProducts()
      setHasAccess(true)
      navigate('/confirmation-page', { state: 'login' })
      await logout()
    },
  })

  const onSubmit = (data: any) => {
    const loginData: any = {
      email: data.email,
      password: data.password,
    }

    setLoginError('')
    loginMutation.mutate(loginData)
  }

  return (
    <>
      <Form
        title={t('product-registration.section.step.second.login-section.title')} // TODO: add translation product-registration.section.step.second.
        description={t('product-registration.section.step.second.login-section.description')} // TODO: add translation
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name='email'
          control={control}
          render={({ field }) => (
            <Input
              hasError={!!errors.email || !!loginError}
              backIcon={(!!errors.email || !!loginError) && <WarningIcon />}
              errorMessage={
                !!errors.email
                  ? t('product-registration.section.step.second.login-section.input.error-message.email')
                  : loginError
              }
              isRequired={true}
              label={t('product-registration.section.step.second.login-section.input.label.email')}
              placeholder={t('product-registration.section.step.second.login-section.input.label.email')}
              {...field}
            />
          )}
        />
        <Controller
          name='password'
          control={control}
          render={({ field }) => (
            <Input
              type={isTextVisible ? 'text' : 'password'}
              hasError={!!errors.password || !!loginError}
              onBackIconClick={togglePasswordVisibility}
              backIcon={isTextVisible ? <HiddenIcon /> : <VisibleIcon />}
              errorMessage={
                !!errors.password
                  ? t('product-registration.section.step.second.login-section.input.error-message.password')
                  : loginError
              }
              isRequired={true}
              label={t('product-registration.section.step.second.login-section.input.label.password')}
              placeholder={t('product-registration.section.step.second.login-section.input.label.password')}
              {...field}
            />
          )}
        />
        <FlexComponent
          alignItems='flex-start'
          width='100%'
          flexDirection='column'
          gap={[10, 10, 24, 24]}
          className='relative'
        >
          <Button
            type='button'
            onClick={handleModalOpen}
            variant='secondary'
            label={t('product-registration.section.step.second.login-section.button.password')}
          />
        </FlexComponent>
        <HideOn mobile>
          <FlexComponent gap={16}>
            <Checkbox isError={false} isChecked={isChecked} onChange={() => onCheckboxChange()} />
            <Text
              isHtml
              variant='newsletter'
              text={t(`product-registration.section.step.second.login-section.checkbox.${country}`)}
            />
          </FlexComponent>
        </HideOn>
        <FlexComponent flexDirection='column' gap={24}>
          <HideOn mobile>
            <Button
              isDisabled={!isChecked || !isValid}
              type='submit'
              label={t('product-registration.section.step.second.login-section.button.submit')}
              variant='primary'
              width='100%'
              desktopWidth='auto'
            />
          </HideOn>
        </FlexComponent>
      </Form>
      <HideOn desktop>
        <FlexComponent gap={16} mt={40}>
          <Checkbox isError={false} isChecked={isChecked} onChange={() => onCheckboxChange()} />
          <Text
            isHtml
            variant='newsletter'
            text={t(`product-registration.section.step.second.login-section.checkbox.${country}`)}
          />
        </FlexComponent>
      </HideOn>
      <HideOn desktop>
        <FlexComponent mt={40}>
          <Button
            isDisabled={!isChecked || !isValid}
            type='submit'
            label={t('product-registration.section.step.second.login-section.button.submit')}
            variant='primary'
            width='100%'
            desktopWidth='auto'
            onClick={() => handleSubmit(onSubmit)()}
          />
        </FlexComponent>
      </HideOn>
      <ResetPasswordModal />
      <ResendOtpSnackbar />
    </>
  )
}

export default UserLoginForm
