import { CSSObject } from '@emotion/react'

export const style = ({ theme, isSelected }: any): CSSObject => {
  const { typography, color, spacings } = theme
  return {
    outline: 'none',
    boxShadow: 'none',
    cursor: 'pointer',

    borderRadius: spacings.s4,
    border: `1px solid ${color.primary.elementaryBlue}`,

    fontSize: typography.fontSizeMobile.interactionsBoldSmallWide,
    fontWeight: typography.fontWeight.bold,
    lineHeight: typography.lineHeightMobile.interactionsBoldSmall,
    fontFamily: typography.fontFamily.helveticaBold,
    letterSpacing: '2px',
    textTransform: 'uppercase',
    textWrap: 'nowrap',
    color: color.primary.elementaryBlue,

    backgroundColor: color.primary.elementaryWhite,
    padding: `${spacings.s8} ${spacings.s12}`,
    height: spacings.s32,

    [theme.breakpoint.up('md')]: {
      padding: `${spacings.s12} ${spacings.s24}`,
      height: spacings.s40,
    },

    ...(isSelected && {
      color: color.primary.elementaryWhite,
      backgroundColor: color.primary.elementaryBlue,
    }),
  }
}

export default style
