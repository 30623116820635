import { CSSObject } from '@emotion/react'
import { toRem } from '../../../../helpers/functions'

export const style = ({ theme, type, img, isEmptyState }: any): CSSObject => {
  const { color, spacings, breakpoint, typography } = theme
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '.subsection': {
      backgroundColor: color.greyscale.lightGrey,
      borderRadius: spacings.s4,
      height: toRem(95),
      position: 'relative',
      marginLeft: toRem(40),

      // left: toRem(40),
      //   right: toRem(40),
      [breakpoint.up('md')]: {
        height: toRem(140),
        marginLeft: toRem(50),
      },
      ...(type === 'main' && {
        height: toRem(120),

        [breakpoint.up('md')]: {
          height: toRem(140),
          // left: toRem(412),
        },
      }),
      ...(isEmptyState && {
        backgroundColor: color.primary.elementaryWhite,
        borderRadius: spacings.s4,
        border: `1px dashed ${color.greyscale.silver}`,
      }),
    },
    '.card': {
      '&-image': {
        position: 'absolute',
        zIndex: 1,
        left: 0,
        transform: ' translateX(-50%)',
        width: toRem(80),
        height: toRem(80),
        [breakpoint.up('md')]: {
          width: toRem(100),
          height: toRem(100),
        },
        img: {
          width: toRem(80),
          height: toRem(80),
          [breakpoint.up('md')]: {
            width: toRem(100),
            height: toRem(100),
          },
          objectFit: 'contain',
        },
        ...(!img && {
          svg: {
            width: toRem(80),
            height: toRem(80),
            [breakpoint.up('md')]: {
              width: toRem(100),
              height: toRem(100),
            },
          },
        }),
        ...(type === 'main' && {
          backgroundColor: color.greyscale.iron,
        }),
        ...(isEmptyState && {
          borderRadius: spacings.s4,
          backgroundColor: color.greyscale.lightGrey,
        }),
      },
      '&-title': {
        width: '100%',

        color: color.greyscale.shipGrey,
        fontFamily: typography.fontFamily.helveticaBlack,
        fontSize: toRem(18),
        fontWeight: typography.fontWeight.bold,
        lineHeight: typography.lineHeight.descriptionsRoman,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,

        ...(type === 'single' && {
          textTransform: 'uppercase',
        }),

        [breakpoint.up('md')]: {
          fontSize: toRem(20),
          lineHeight: typography.lineHeight.interactionsHeavy,
        },

        ...(type === 'main' && {
          fontSize: toRem(18),
          lineHeight: typography.lineHeightMobile.copyBold,
          [breakpoint.up('md')]: {
            fontSize: toRem(24),
            lineHeight: typography.lineHeight.h5Bold,
          },
        }),
      },
      '&-breadcrumbs': {
        color: color.greyscale.shipGrey,
        fontFamily: typography.fontFamily.helveticaLight,
        fontSize: typography.fontSize.interactionsMedium,
        fontWeight: typography.fontWeight.light,
        lineHeight: typography.lineHeight.descriptionsRoman,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 1,
      },
      '&-radiobutton': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexShrink: 0,
        borderLeft: `1px solid ${color.greyscale.iron}`,
        paddingLeft: spacings.s20,
        height: toRem(48),
        [breakpoint.up('md')]: {
          paddingLeft: spacings.s40,
        },
      },
    },
  }
}
