import React from 'react'
import { ModalComponent } from '../../../components/organisms/Modal'
import AccordionComponent from '../../../components/molecules/Accordion/Accordion'
import AccordionItem from '../../../components/molecules/Accordion/AccordionItem'
import { Divider } from '../../../components/atoms/Divider'
import { FlexComponent } from '../../../components/organisms/Layout/Flex'
import { useModalContext } from '../../../../hooks'

interface accordionItem {
  headerTitle: string
  content: string
  image: string
  subTitle?: string
  content2?: string
  image2?: string
  subTitle2?: string
  flexDirection?: string
}

interface IProductInfoModal {
  accordionData: accordionItem[]
  t: any
}

const ProductInfoModal = ({ accordionData, t }: IProductInfoModal) => {
  const serialNumberInstructionsModalID = 'serialNumberInstructionsModal'

  const serialNumberInstructionsModal = useModalContext(serialNumberInstructionsModalID)
  const handleModalClose = () => serialNumberInstructionsModal.close()
  return (
    <ModalComponent
      modalTitle={t('product-registration.modal.mainTitle')}
      isOpen={serialNumberInstructionsModal.isOpen}
      onClose={handleModalClose}
    >
      <FlexComponent>
        <AccordionComponent type='multiple'>
          {accordionData.map((item, index) => (
            <React.Fragment key={`item-${index}`}>
              <AccordionItem
                headerTitle={item.headerTitle}
                value={`item-${index}`}
                content={item.content}
                url={item.image}
                alt={item.subTitle || item.headerTitle}
                alt2={item.subTitle2 || item.headerTitle}
                subTitle={item.subTitle}
                subTitle2={item.subTitle2}
                content2={item.content2}
                url2={item.image2}
                flexDirection={item.flexDirection}
              />
              {index !== accordionData.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </AccordionComponent>
      </FlexComponent>
    </ModalComponent>
  )
}

export default ProductInfoModal
