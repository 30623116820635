import { CSSObject } from 'theme-ui'
import { toRem } from '../../../../helpers/functions'

export const style = ({ theme, isActive }: any): CSSObject => {
  const { spacings, shadows, color, breakpoint } = theme
  return {
    position: 'absolute',
    width: 'calc(48px + 100%)',
    opacity: 0,
    overflow: 'hidden',
    visibility: 'hidden',
    top: toRem(63),
    left: toRem(-24),
    backgroundColor: color.primary.elementaryWhite,
    zIndex: 1,
    boxShadow: shadows.secondary,
    border: 0,
    maxHeight: 0,
    transition: 'opacity 0.35s ease-in-out',
    ...(isActive && {
      opacity: 1,
      visibility: 'visible',
      minHeight: '64vh',
      maxHeight: '100vh',
      overflowY: 'auto',
      [breakpoint.up('md')]: {
        overflowY: 'none',
        maxHeight: 'fit-content',
      },
    }),
    [breakpoint.up('md')]: {
      padding: `${spacings.s24} ${spacings.s40}`,
      width: '100%',
      left: 0,
      minHeight: toRem(375),
    },
    '& .bigDropdown': {
      '&-grid': {
        display: 'grid',
        gridTemplateColumns: 'repeat(1, 1fr)',
        gridTemplateRows: 'repeat(4, auto)',
        rowGap: spacings.s24,
        padding: `${spacings.s24}`,
        [breakpoint.up('md')]: {
          gridTemplateColumns: 'repeat(2, 1fr)',
          gridTemplateRows: 'repeat(2, auto)',
          columnGap: toRem(50),
        },
      },
      '&-footer': {
        padding: `0 ${spacings.s24} ${spacings.s24}`,
        [breakpoint.up('md')]: {
          padding: `0 ${spacings.s24} ${spacings.s24}`,
        },
      },
    },
  }
}
