import React, { forwardRef } from 'react'
import styled from '@emotion/styled'
import { FlexComponent } from '../../organisms/Layout/Flex'
import { Text } from '../Text'
import { style } from './Input.style'

interface IInput {
  id?: string
  icon?: React.ReactNode
  backIcon?: React.ReactNode
  placeholder: string
  value?: string
  onClick?: () => void
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onBackIconClick?: () => void
  maxWidth?: string
  label?: string
  hasError?: boolean
  isRequired?: boolean
  errorMessage?: string
  type?: string //change to actual types
}

const StyledInput = styled.div<Partial<IInput>>((props) => ({ ...style(props) }))

const StyledInputRoot = styled.input<Partial<IInput>>((props) => ({ ...style(props) }))

const Input = forwardRef<HTMLInputElement, IInput>(
  (
    {
      id,
      icon,
      backIcon,
      onBackIconClick,
      value,
      onChange,
      onBlur,
      placeholder,
      onClick,
      maxWidth,
      label,
      hasError,
      isRequired = false,
      errorMessage,
      type = 'text',
    },
    ref,
  ) => {
    return (
      <FlexComponent flexDirection='column' width='100%'>
        <StyledInput maxWidth={maxWidth} label={label} hasError={hasError} value={value} type={type}>
          {icon && !label && icon}
          <StyledInputRoot
            id={id}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            ref={ref}
            onClick={onClick}
            placeholder={label ? undefined : placeholder}
            type={type}
          />
          {!!label && (
            <label className='floatingLabel'>
              {label}
              {isRequired && '*'}
            </label>
          )}
          <FlexComponent className='input-backIcon' onClick={onBackIconClick}>
            {backIcon && backIcon}
          </FlexComponent>
        </StyledInput>
        <FlexComponent mt={'2px'}>
          {hasError && errorMessage && <Text variant='error' text={errorMessage} />}
        </FlexComponent>
      </FlexComponent>
    )
  },
)

Input.displayName = 'Input'

export default Input
