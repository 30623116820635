import { useTranslation } from 'react-i18next'
import { Incentive } from '../../../components/molecules/Incentive'
import { FlexComponent } from '../../../components/organisms/Layout/Flex'
import { ReactComponent as AccessIcon } from '../../../../icons/Access.svg'
import { ReactComponent as GiftIcon } from '../../../../icons/Gift.svg'
import { HideOn } from '../../../components/atoms/HideOn'
import List from '../../../components/atoms/List/List'
import { ReactComponent as CheckIcon } from '../../../../icons/check.svg'
import { makePublicPathUrl } from '../../../../helpers/functions/routing'

const Hero = () => {
  const { t } = useTranslation()

  const list = [
    {
      icon: <CheckIcon />,
      text: t('home.list.first'),
    },
    {
      icon: <CheckIcon />,
      text: t('home.list.second'),
    },
    {
      icon: <CheckIcon />,
      text: t('home.list.third'),
    },
    {
      icon: <CheckIcon />,
      text: t('home.list.fourth'),
    },
  ]

  return (
    <FlexComponent mt={[24, 24, 24, 60]} flexDirection='column' alignItems='center' width='100%'>
      <FlexComponent className='hiddenOnMobile'>
        <img src={makePublicPathUrl('/images/blanco-contained-hero.jpg')} alt='blanco-contained-hero' />
      </FlexComponent>
      <HideOn desktop>
        <FlexComponent mb={40} maxWidth={['100%', '100%', 483, 483]}>
          <List list={list} title={t('home.list.headline')} />
        </FlexComponent>
      </HideOn>
      <FlexComponent
        maxWidth={1038}
        width='100%'
        alignItems='center'
        gap={[10, 10, 10, 24]}
        mt={[0, 0, -60, -60]}
        pl={[0, 0, 24, 24]}
        pr={[0, 0, 24, 24]}
        flexDirection={['column', 'column', 'row', 'row']}
      >
        <Incentive icon={<GiftIcon />} text={t('home.incentive.gift')} />
        <Incentive icon={<AccessIcon />} text={t('home.incentive.access')} />
      </FlexComponent>
    </FlexComponent>
  )
}

export default Hero
