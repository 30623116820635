import styled from '@emotion/styled'

import style from './Headline.style'

interface IHeadline {
  headline: string
  hasBorders?: boolean
  isDisabled?: boolean
}

const StyledHeadline = styled.h1<Partial<IHeadline>>((props) => ({ ...style(props) }))

const Headline = ({ isDisabled, headline, hasBorders }: IHeadline) => (
  <StyledHeadline isDisabled={isDisabled} hasBorders={hasBorders}>
    <h1 className='headline'>{headline}</h1>
  </StyledHeadline>
)

export default Headline
