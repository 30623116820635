import { useTranslation } from 'react-i18next'
import { ModalComponent } from '../../../components/organisms/Modal'
import { FlexComponent } from '../../../components/organisms/Layout/Flex'
import { useModalContext } from '../../../../hooks'
import { Button } from '../../../components/atoms/Button'
import { Text } from '../../../components/atoms/Text'
import { ReactComponent as GermanyIcon } from '../../../../icons/country-flag/Germany.svg'
import { ReactComponent as AustriaIcon } from '../../../../icons/country-flag/Austria.svg'
import { ReactComponent as SwitzerlandIcon } from '../../../../icons/country-flag/Switzerland.svg'
import { ReactComponent as UnitedKingdomIcon } from '../../../../icons/country-flag/UnitedKingdom.svg'
import { Divider } from '../../../components/atoms/Divider'

interface ICountryModal {
  scenario?: 'A' | 'B' | 'C' | 'D'
  countryName?: string
  countryCode?: string
  userDomain?: string
}

const countries = [
  {
    countryCode: 'AT',
    countryName: 'Austria',
    icon: <AustriaIcon />,
    domain: 'at',
    link: 'https://www.blanco.at/produktregistrierung',
  },
  {
    countryCode: 'DE',
    countryName: 'Germany',
    icon: <GermanyIcon />,
    domain: 'de',
    link: 'https://www.blanco.de/produktregistrierung',
  },
  {
    countryCode: 'CH',
    countryName: 'Switzerland',
    icon: <SwitzerlandIcon />,
    domain: 'ch',
    link: 'https://www.blanco.ch/produktregistrierung ',
  },
  {
    countryCode: 'UK',
    countryName: 'United Kingdom',
    icon: <UnitedKingdomIcon />,
    domain: 'uk',
    link: 'https://www.blanco.co.uk/productregistration',
  },
]

const CountryModal = ({ scenario, countryName, countryCode, userDomain }: ICountryModal) => {
  const { t } = useTranslation()

  const countryModalID = 'countryModal'
  const countryModal = useModalContext(countryModalID)
  const handleModalClose = () => {
    countryModal.close()
  }

  const onContinue = (country: (typeof countries)[number]) => {
    if (country.link.toLowerCase() !== userDomain?.toLowerCase()) {
      window.location.href = country.link
    }
    countryModal.close()
  }

  const selectedCountry = countries.find((country) => country.countryCode.toLowerCase() === countryCode?.toLowerCase())
  const domainCountry = countries.find((country) => country.domain.toLowerCase() === userDomain?.toLowerCase())

  return scenario === 'B' ? (
    <ModalComponent
      modalTitle={t('location-modal.title')}
      isOpen={countryModal.isOpen}
      onClose={handleModalClose}
      height='auto'
      maxWidth='620px'
      mobileHeight='auto'
      preventPointerDownOutside={true}
    >
      <FlexComponent flexDirection='column' gap={24} mb={24}>
        {countryName && (
          <FlexComponent gap={1}>
            <div>
              <Text text={t('location-modal.available')} />{' '}
              <Text variant='bold' text={`${selectedCountry?.countryName}`} />
            </div>
          </FlexComponent>
        )}
        <Text text={t('location-modal.select')} />
      </FlexComponent>
      <FlexComponent flexDirection='column' gap={24}>
        <FlexComponent flexDirection='column' gap={2}>
          <Text variant='newsletter' text='Switch to:' />
          {selectedCountry && (
            <Button
              icon={selectedCountry.icon}
              isDisabled={false}
              type='button'
              label={selectedCountry.countryName}
              variant='leftCenteredPrimary'
              width='400px'
              onClick={() => onContinue(selectedCountry)}
            />
          )}
        </FlexComponent>
        <FlexComponent flexDirection='column' gap={2}>
          <Text variant='newsletter' text='or stay on:' />
          {domainCountry && domainCountry !== selectedCountry && (
            <Button
              icon={domainCountry.icon}
              isDisabled={false}
              type='button'
              label={domainCountry.countryName}
              variant='leftCenteredSecondary'
              width='400px'
              onClick={() => onContinue(domainCountry)}
            />
          )}
        </FlexComponent>
      </FlexComponent>

      <FlexComponent mt={40}>
        <Text variant='newsletter' text={t('location-modal.four-countries')} />
      </FlexComponent>
    </ModalComponent>
  ) : (
    <ModalComponent
      modalTitle={t('location-modal.title')}
      isOpen={countryModal.isOpen}
      onClose={handleModalClose}
      height='auto'
      maxWidth='620px'
      mobileHeight='auto'
      preventPointerDownOutside={true}
    >
      <FlexComponent flexDirection='column' gap={24} mb={24}>
        {countryName && scenario !== 'D' && (
          <FlexComponent gap={1} flexWrap='wrap'>
            <Text
              text={
                <span>
                  {t('location-modal.not-available')} <Text variant='bold' text={`${countryName}`} />
                </span>
              }
            />
          </FlexComponent>
        )}
        <Text text={t('location-modal.select')} />
      </FlexComponent>
      <FlexComponent flexDirection='column' alignItems='center' gap={2}>
        {countries.map((country) => (
          <>
            <Divider hasMargin={false} />
            <Button
              icon={country.icon}
              isDisabled={false}
              type='button'
              label={country.countryName}
              variant='leftCenteredDefault'
              width='400px'
              onClick={() => onContinue(country)}
            />
          </>
        ))}
      </FlexComponent>
      <FlexComponent mt={40}>
        <Text variant='newsletter' text={t('location-modal.four-countries')} />
      </FlexComponent>
    </ModalComponent>
  )
}

export default CountryModal
